import React, {useContext, useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import {isMobile, isMobileOnly} from 'react-device-detect'
import PaymentScreen from './PaymentScreen'
import {useHistory} from 'react-router-dom'
import Link from '@material-ui/core/Link'

import Payment from '../images/Pay.svg'
import TeamCradi from '../images/TeamCred.svg'
import LOGOUT from '../images/Logout.svg'
import ACCOUNT from '../images/Accountdet.svg'
import {HideHeaderContext} from '../UserContext'

import '../components/ProfilePage.css'
import {useAuth0} from '@auth0/auth0-react'

interface profileProps {
  PaymentScreen(): void
}

const useStyles = makeStyles({
  avatar: {
    heigh: '42px',
    width: '42px',
    marginRight: '10px',
    backgroundColor: '#C8A178',
    color: '#FFFFFF',
  },
  avatar1: {
    width: isMobile ? '48px' : '80px',
    // marginRight: "10px",
    backgroundColor: '#C8A178',
    color: '#FFFFFF',
    marginTop: '50px',
    fontSize: isMobile ? '26px' : '40px',
    justifyContent: 'center',
  },
})

export interface SimpleDialogProps {
  open: boolean
  selectedValue: string
  onClose: (value: string) => void
  className: string
}
const values = ['', '']

function SimpleDialog(props: SimpleDialogProps) {
  let history = useHistory()
  const classes = useStyles()
  const {onClose, selectedValue, open} = props
  const {HideHeader, setHideHeader} = useContext(HideHeaderContext)

  const handleListItemClick = (value: string) => {
    onClose(value)
  }

  const handleClose = () => {
    onClose(selectedValue)
  }
  const preventDefault = (event: React.SyntheticEvent) => event.preventDefault()
  const [isSE, setSE] = useState(window.innerWidth < 321)
  const [is8Plus, set8Plus] = useState(window.innerWidth < 415 && window.innerWidth > 410)
  const [isIphone12, setIphone12] = useState(window.innerWidth < 428 && window.innerWidth > 422)
  const [isIphoneX, setIphoneX] = useState(window.innerWidth === 375) // +??? ~sahil FEATURE.

  const {user, logout} = useAuth0()
  const updateMedia = () => {
    console.log('isIphone12', isIphone12)
    setSE(window.innerWidth < 321)
    set8Plus(window.innerWidth < 415 && window.innerWidth > 410)
    setIphone12(window.innerWidth < 428 && window.innerWidth > 422)
    setIphoneX(window.innerWidth === 375) // +??? ~sahil FEATURE.
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{
        marginBottom: isMobile ? '' : '66px !important',
        marginTop: isMobile ? '-50px' : '',
        position: 'absolute',
        left: isMobile ? '17%' : '67%',
        top: isMobile ? '-1%' : '9%',
        height: isMobile ? '767px' : '752px',
      }}>
      {console.log('is8Plus', is8Plus)}
      <div
        className='profile-page'
        style={{
          right: isMobileOnly ? '0%' : '30px', // ~sahil-feature ?????????
          // border: '2px solid blue', // debug ~sahil?????????
          position: 'fixed',
          width: isMobile ? '252px' : '420px',
          background: '#000000',
          boxShadow: '0px 0px 20px  grey',
          // height: isMobile ? '767px' : '752px', // ~sahil, old code, to make the profile-sidebar have full height as the viewport.
          top: 0, //~sahil, 9 Jun, 2021, now this doesn't get cut at top side, yo!!
          // border: '2px solid blue',//~sahil, debug
          height: isMobile ? '100%' : '752px',
          marginTop: isMobile ? '' : '-60px',
          marginLeft: isIphone12 ? '44px' : is8Plus ? '32px' : isMobile ? 10 : '10px',
        }}
        onClick={() => handleListItemClick('')}>
        <div style={{justifyContent: 'center', display: 'flex'}}>
          {' '}
          <Avatar style={{height: isMobile ? '48px' : '80px', marginBottom: '10px'}} className={classes.avatar1}>
            {user?.nickname?.charAt(0).toUpperCase()}
          </Avatar>
        </div>
        <div
          style={{
            fontFamily: 'Atten New',
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: isMobile ? '16px' : '26px',
            color: '#FFFFFF',
            textAlign: 'center',
          }}>
          {user?.nickname}
        </div>
        <div style={{textAlign: 'center'}}>
          <Link
            href='#'
            style={{
              fontFamily: 'Atten New',
              fontStyle: 'normal',
              fontSize: isMobile ? '16px' : '22px',
              color: '#E7E1C5',
              marginTop: '10px',
              borderBottom: '1px solid #E7E1C5',
              textDecoration: 'none',
            }}
            onClick={() => {
              history.push('/viewAccount')
              setHideHeader(true)
            }}>
            View account
          </Link>
        </div>
        <div className='flex-grow-1' />

        <div className='' style={{marginTop: '60px'}}>
          <div className='d-flex ml-4 justify-content-space-evenly text-aligin-center'>
            <div>
              <img style={{width: 18, height: 18}} src={Payment} />
            </div>
            <div
              style={{
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontSize: isMobile ? '14px' : '20px',
                color: '#FFFFFF',
                marginLeft: '25px',
                position: 'relative', //~sahil
                top: '2px', //~sahil.
              }}
              onClick={(e) => {
                history.push('/paymentScreen')
                setHideHeader(true)
              }}>
              Payment History
            </div>
          </div>
          <div className='flex-grow-1' />

          <div className='d-flex ml-4 justify-content-space-evenly mt-4'>
            <div>
              <img style={{width: 28, height: 28, marginLeft: '-5px'}} src={TeamCradi} />
            </div>
            <div
              style={{
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontSize: isMobile ? '14px' : '20px',
                color: '#FFFFFF',
                marginLeft: '19px',
                position: 'relative', //~sahil
                top: '3px', //~sahil.
              }}
              onClick={(e) => {
                history.push('/teamCredentials')
                setHideHeader(true)
              }}>
              Submit Team Credentials
            </div>
          </div>
          <div className='d-flex ml-4 mt-4'>
            <div>
              <img style={{width: 18, height: 18}} src={ACCOUNT} />
            </div>
            <div
              style={{
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontSize: isMobile ? '14px' : '20px',
                color: '#FFFFFF',
                marginLeft: '25px',
                position: 'relative', //~sahil
                top: '4px', //~sahil.
              }}
              onClick={(e) => {
                history.push('/accountDetails')
                setHideHeader(true)
              }}>
              Account Details
            </div>
          </div>
          <div className='flex-grow-1' />

          <div className='d-flex ml-4 mt-4 align-items-center'>
            <div>
              <img style={{width: 18, height: 18}} src={LOGOUT} />
            </div>
            <div
              style={{
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                // border: '1px solid blue', //~sahil
                fontSize: isMobile ? '14px' : '20px',
                color: '#FFFFFF',
                marginLeft: '25px',
                position: 'relative', //~sahil
                top: '2px', //~sahil.
              }}
              onClick={() => logout()}>
              Log Out
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default function SimpleDialogDemo() {
  const classes = useStyles()

  const [open, setOpen] = React.useState(false)
  const [close, setClose] = React.useState(false)
  const [selectedValue, setSelectedValue] = React.useState(values[1])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (values: string) => {
    setOpen(false)
    setSelectedValue(values)
  }
  const {user} = useAuth0()
  return (
    <div>
      <Avatar onClick={handleClickOpen} className={classes.avatar}>
        {user?.nickname?.charAt(0).toUpperCase()}
      </Avatar>

      <SimpleDialog selectedValue={selectedValue} open={open} onClose={handleClose} className={classes.avatar} />
    </div>
  )
}
