import React, {useState, useEffect, useContext, useRef} from 'react'
import {makeStyles, Theme, createStyles} from '@material-ui/core/styles'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import {Tabs, Chip} from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import $ from 'jquery'
import Logo from '../images/logo.png'
import Avatar from '@material-ui/core/Avatar'
import {Link} from 'react-router-dom'
import {isMobile, isMobileOnly} from 'react-device-detect'
import '../components/header.css'
import {useHistory, useLocation} from 'react-router-dom'
import ProfilePage from './ProfilePage'
import Box from '@material-ui/core/Box'
import {useAuth0} from '@auth0/auth0-react'
import {AuthContext, ReloadContext} from '../UserContext'
import {focusContext} from '../UserContext'
import {TabVanilla} from './TabVanilla'
import {useMediaQueryVanilla} from '../hooks/useMediaQueryVanilla'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      flexGrow: 1,
      flexDirection: 'row',
      background: '#010101',
    },
    indicator: {
      color: '#BC936B',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },

    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    image: {
      heigh: '38px',
      width: '169px',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        marginLeft: '10px',
      },
    },
    avatar: {
      heigh: '42px',
      width: '42px',
      backgroundColor: '#A39C73',
      color: '#FFFFFF',
      [theme.breakpoints.down('sm')]: {
        display: 'flex',
      },
    },
    navMobile: {
      flex: 1,
      flexDirection: 'row',
      display: 'none',
      background: '#010101 !important',

      [theme.breakpoints.down('sm')]: {
        display: 'flex',
        // marginTop: '-20px',//~sahil, giving such marginTop is not good.
        fontSize: '12px',
        marginLeft: '-13px',
      },
      justifyContent: 'center',
    },
    navBarTextDesk: {
      paddingTop: '13px',
      paddingBottom: '30px',
      lineHeight: '20px',
      marginLeft: '10px',
      textAlign: 'left',
      background: '#010101',
    },
    navBarText: {
      paddingTop: '13px',
      paddingBottom: '30px',
      lineHeight: '20px',
      textAlign: 'left',

      [theme.breakpoints.down('xs')]: {
        marginLeft: '-10px',
      },
    },
    Indicator: {
      color: '#C57A1E',
      fontSize: '12px',
      lineHeight: '20px',
      marginLeft: '10px',

      [theme.breakpoints.down('sm')]: {
        color: '#C57A1E',

        marginLeft: '8px',
      },
      [theme.breakpoints.down('xs')]: {
        color: '#C57A1E',

        marginLeft: '0px',
      },
    },
    IndicatorClick: {
      height: '30px',
      color: '#C8A178',
      lineHeight: '20px',
      borderRadius: '50px',
    },

    IndicatorDesk: {
      color: '#BC936B',
      fontSize: '14px',
      lineHeight: '20px',
      marginLeft: '10px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingRight: ' 45px',
      paddingTop: '32px',
    },
    root: {
      flexGrow: 1,
      flex: 1,
      flexDirection: 'row',
      color: '#BC936B',
      fontSize: '12px',
      lineHeight: '20px',
      paddingTop: '13px',
      paddingLeft: '10px',
      paddingBottom: '30px',
    },
    declip: {
      height: '30px',
      color: '#C57A1E',
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Atten New',
      fontWeight: 'bold',
    },
    clip: {
      height: '30px',
      color: '#E7E1C5',
      fontSize: '16px',
      lineHeight: '20px',
      fontFamily: 'Atten New',

      '&:hover': {
        textDecoration: 'none !important',
        underline: 'none !important',
        textDecorationColor: 'none !important',
      },
      [theme.breakpoints.down('sm')]: {
        maxwidth: '334px',
        fontSize: '10px',
        marginLeft: '2px',
        marginTop: '-35px',
      },
      indicator: {
        color: '#BC936B',
        backgroundColor: '#BC936B',
        width: '72px',
      },
    },
  })
)

interface props {
  indicator: any
  children?: React.ReactNode
  index: any
  value: any
  handlechange(): void
}

//
//
//
// code below only!!
//
//
//
//
//
//
// const localStorageGetLastTab = () => localStorage.getItem('lastTab') || 0 // providing fallback value as 0 tab ~ sahil debug, ?????????
// const localStorageSetLastTab = (tabNumber: number) => localStorage.setItem('lastTab', String(tabNumber))
let {log} = console

const isWithoutHeaderPage = () => {
  if (window.location.pathname === '/paymentScreen') return true
  if (window.location.pathname === '/teamCredentials') return true
  if (window.location.pathname === '/viewAccount') return true
  if (window.location.pathname === '/accountDetails') return true
  // add more cases here like above if necessary!

  // otherwise return false.
  return false
}

function MenuAppBar(props: any) {
  //// 🛑︎ Warning: Do not put below function outside the component, else the window.location.pathname will not be updated when we navigate to different pages.

  useEffect(() => {
    // TODO: I  get to hide the html markup element with class =>  `mobile-navigation-bar` if `isWithoutPageHeaderPage()` returns true.
    // display: isWithoutHeaderPage() ? 'none' : 'block',

    const el: any = window.document.querySelector('.mobile-navigation-bar')
    console.log('~sahil iswithoutheaderpage', isWithoutHeaderPage())
    if (isWithoutHeaderPage()) {
      console.log('~sahil iswithoutpage', isWithoutHeaderPage())
      el.style.display = 'none'
    }

    log('~sahil,header.tsx, window.location.pathname', window.location.pathname)

    // if()

    // return alert('unmounted...') // ????????? ~sahil
  })

  const [_, forceReRender] = useState({})
  console.log({isMobile})
  const history = useHistory()
  const location = useLocation()
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const {Reload, setReload} = useContext(ReloadContext)
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    // debugger
    setValue(newValue)
    // ~sahil debug, ????????? THIS handleChange is not executing at all.
    console.log('~sahil handleChange executed..')
  }
  console.log('~sahil value', value)
  const {user} = useAuth0()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget)
  }

  const mobileMenuId = 'primary-search-account-menu-mobile'

  function linkTapped(val: number) {
    setValue(val)
    setReload(true)
    props.tabChanged(val)
    log('~sahil linkTapped executed...')
    // history.push(value) /// ()..
    // setLastTab(val)
    setValue(val)
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: any
    value: any
  }

  interface Data {
    date: string
    amount: string
    confirnation: string
  }

  useEffect(() => {
    log('~sahil location changed...')
    // console.log(props)
    console.log('loca', location)
    // console.log('loca', history)
    const pathname = location.pathname
    if (pathname == '/') {
      setValue(0)
    } else if (pathname == '/listed') {
      setValue(1)
    } else if (pathname == '/notlisted') {
      setValue(2)
    } else if (pathname == '/sold') {
      setValue(3)
    }
  }, [location])

  // // original code...below.
  // useEffect(() => {
  //   console.log(props)
  //   console.log('loca', location)
  //   console.log('loca', history)
  //   const pathname = location.pathname
  //   if (pathname == '/') {
  //     setValue(0)
  //   } else if (pathname == '/listed') {
  //     setValue(1)
  //   } else if (pathname == '/notlisted') {
  //     setValue(2)
  //   } else if (pathname == '/sold') {
  //     setValue(3)
  //   }
  // }, [])

  // ??? Below code was not used anywhere, so thats why I commented it.
  // function TabPanel(props: TabPanelProps) {
  //   const {children, value, index, ...other} = props

  //   return (
  //     <div role='tabpanel' hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
  //       {value === index && (
  //         <Box p={3}>
  //           <Typography>{children}</Typography>
  //         </Box>
  //       )}
  //     </div>
  //   )
  // }

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    }
  }

  const {focus, setfocus} = useContext(focusContext)

  const {isSE, is8Plus, isIphone8} = useMediaQueryVanilla() // ~sahil

  useEffect(() => {
    console.log('newValue', value)
    console.log(is8Plus)
    // window.addEventListener('resize', updateMedia) // ~sahil added this logic to useMediaQueryVanilla media query.
    // $(window).scroll(function () {
    console.log(focus)
    // var sticky = $('.sticky'),
    // scroll: any = $(window)?.scrollTop()
    // if (scroll >= 320) sticky.addClass('fixed')
    //~sahil, original
    // if (scroll >= 420) sticky.addClass('fixed')
    // ~testing..
    // else sticky.removeClass('fixed')
    // })

    // return () => window.removeEventListener('resize', updateMedia) // ~sahil added this logic to useMediaQueryVanilla media query.
  }, [])

  // useEffect(() => {
  // })

  return (
    <>
      <div
        className={classes.grow}
        style={{
          display: isWithoutHeaderPage() ? 'none' : 'block',
          background: '#010101',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <AppBar
          position='relative'
          style={{
            background: '#010101',
            borderBottom: isMobile ? '' : '2px solid rgba(146, 146, 146, 0.19)',
            height: isMobile ? '' : '101px',
            marginBottom: isMobile ? '' : '30px',
          }}>
          <Toolbar
            style={{
              paddingTop: isMobile ? '16px' : '14px',
              paddingBottom: isMobile ? '16px' : '',
              marginLeft: isMobile ? '' : '55px',
              marginRight: isMobile ? '' : '50px',
              background: isMobile ? '#010101' : '',
              // border: '2px solid blue', // ????????? sahil debug, ?????????
            }}>
            <div>
              <div className={clsx(classes.menuButton)} color='inherit' aria-label='open drawer'>
                <img
                  className={classes.image}
                  style={{
                    marginLeft: isMobile ? '' : '40px',
                  }}
                  src={Logo}
                  alt='just to shut up css warning'
                />
              </div>
            </div>
            <div className={classes.grow} />

            <Tabs
              className={classes.sectionDesktop}
              style={{
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontWeight: 'normal',
                marginRight: '45px',
                marginBottom: '25px',
                // border: '2px solid blue', // ????????? ~sahil debug, ?????????
              }}
              value={value} // ~sahil
              onChange={handleChange}
              indicatorColor='primary' // ????????? ~sahil (27may, adding dashboard animation.) debug, ?????????
              // textColor='inherit'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
              TabIndicatorProps={{
                style: {
                  display: 'none', // ????????? ~sahil (27may, adding dashboard animation.) debug, ?????????
                  // ????????? ~sahil DISABLED THE TAB INDICATIOR FROM MATERIAL UI.
                  backgroundColor: '#C57A1E',
                  borderBottomStyle: 'solid',
                  borderBottomColor: '#c57a1e',
                  borderBottomWidth: '2px',
                  width: value == 2 ? '22%' : '14%',
                  // paddingRight: isMobile ? "40px" : "",
                },
              }}
              classes={{
                indicator: classes.indicator,
              }}>
              <Link to='/home' onClick={() => linkTapped(0)} style={{textDecoration: 'none'}}>
                <div>
                  {/* <Typography className={classes.IndicatorDesk}> */}
                  {/* <div className={clsx(value === 0 ? classes.declip : classes.clip)}>HOME</div> */}
                  {/* Tip: use color appropriately for the text as its light brown now, originally its bright brown I guess.. */}
                  {/* </Typography> */}
                  <TabVanilla label='HOME' i={0} />
                </div>
              </Link>
              <Link to='/listed' onClick={() => linkTapped(1)} style={{textDecoration: 'none'}}>
                <div>
                  {/* <Typography className={classes.IndicatorDesk}> */}
                  {/* <div className={clsx(value === 1 ? classes.declip : classes.clip)}>LISTED</div> */}
                  {/* </Typography> */}
                  <TabVanilla label='LISTED' i={1} />
                </div>
              </Link>
              <Link to='/notlisted' onClick={() => linkTapped(2)} style={{textDecoration: 'none'}}>
                <div>
                  {/* <Typography className={classes.IndicatorDesk}> */}
                  {/* <div className={clsx(value === 2 ? classes.declip : classes.clip)}>NOT LISTED</div> */}
                  {/* </Typography> */}
                  <TabVanilla label='NOT LISTED' i={2} />
                </div>
              </Link>
              <Link to='/sold' onClick={() => linkTapped(3)} style={{textDecoration: 'none'}}>
                <div>
                  {/* <Typography className={classes.IndicatorDesk}> */}
                  {/* <div className={clsx(value === 3 ? classes.declip : classes.clip)}>SOLD</div> */}
                  {/* </Typography> */}
                  <TabVanilla label='SOLD' i={4} />
                </div>
              </Link>
              <div
                aria-label='account of current user'
                aria-haspopup='true'
                // onClick={handleProfileMenuOpen}
                style={{marginTop: '20px', paddingLeft: '12px'}}>
                <ProfilePage />
                {/* <Avatar className={classes.avatar}>M</Avatar> */}
              </div>
            </Tabs>

            <div>
              <div className={classes.sectionMobile}>
                <div
                  aria-label='show more'
                  aria-controls={mobileMenuId}
                  aria-haspopup='true'
                  // onClick={handleMobileMenuOpen}
                >
                  <ProfilePage />
                  {/* <Avatar className={classes.avatar}>M</Avatar> */}
                </div>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
      {/* Below `mobile-navigation-bar` class is only visible in mobile screen, see media query in .css file for clarity. */}
      <div
        className='mobile-navigation-bar'
        // className='sticky'
        style={
          {
            // display: isWithoutHeaderPage() ? 'none' : 'block',
            // border: '3px solid blue',
          }
        }>
        <AppBar
          position='static'
          className={classes.navMobile}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: '#010101',
          }}>
          <Tabs
            className={clsx(classes.navBarText)}
            style={{
              fontFamily: 'Atten New',
              fontStyle: 'normal',
              fontWeight: 'normal',
              marginLeft: '28px',
              marginTop: '10px',
              // border: '5px solid deeppink', // ????????? ~sahil debug, ?????????
              // background: 'deeppink', // ????????? ~sahil debug, ?????????
            }}
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            // textColor='inherit'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#C57A1E',
                borderBottomStyle: 'solid',
                borderBottomColor: '#c57a1e',
                borderBottomWidth: '2px',

                // paddingRight: isMobile ? "40px" : "",
              },
            }}
            classes={{
              indicator: classes.indicator,
            }}>
            <Link to='/home' onClick={() => linkTapped(0)} style={{textDecoration: 'none'}}>
              <Tab
                className={clsx(value === 0 ? classes.Indicator : classes.IndicatorClick)}
                style={{
                  fontSize: isSE ? '9px' : isMobileOnly ? '12px' : '12px',
                }}
                label='HOME'
                {...a11yProps(0)}
                value={value}
              />
            </Link>
            <Link to='/listed' onClick={() => linkTapped(1)} style={{textDecoration: 'none'}}>
              <Tab
                className={clsx(value === 1 ? classes.Indicator : classes.IndicatorClick)}
                style={{
                  fontSize: isSE ? '9px' : isMobileOnly ? '12px' : '12px',
                }}
                label='LISTED'
                {...a11yProps(1)}
                value={value}
              />
            </Link>
            <Link to='/notlisted' onClick={() => linkTapped(2)} style={{textDecoration: 'none'}}>
              <Tab
                className={clsx(value === 2 ? classes.Indicator : classes.IndicatorClick)}
                style={{
                  fontSize: isSE ? '9px' : isMobileOnly ? '12px' : '12px',
                }}
                label='NOT LISTED'
                {...a11yProps(2)}
                value={value}
              />
            </Link>
            <Link to='/sold' onClick={() => linkTapped(3)} style={{textDecoration: 'none'}}>
              <Tab
                className={clsx(value === 3 ? classes.Indicator : classes.IndicatorClick)}
                style={{
                  fontSize: isSE ? '9px' : isMobileOnly ? '12px' : '12px',
                }}
                label='SOLD'
                {...a11yProps(3)}
                value={value}
              />
            </Link>
          </Tabs>
        </AppBar>
      </div>
    </>
  )
}

export default React.memo(MenuAppBar)