import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import ChooseBills from "./components/ChooseBills";
import ConfirmEmail from "./components/ConfirmEmail";
import Start from "./components/Start";
import Teams from "./components/Teams";
import ThankyouToVerify from "./components/ThankyouToVerify";
import VerifyResaleTicket from "./components/VerifyResaleTicket";
import WhichGame from "./components/WhichGame";
import Navigation from "./components/RouteDashboard";
import CustomizedSteppers from "./screens/Stepper";
import {
  AuthContext,
  focusContext,
  HideHeaderContext,
  UserContext,
} from "./UserContext";
import { start } from "node:repl";
import Home from "./components/Home";
import Listed from "./components/Listed";
import Notlisted from "./components/Notlisted";
import Sold from "./components/Sold";
import PaymentScreen from "./components/PaymentScreen";
import TeamCredentials from "./components/ViewAccount";
import ViewAccount from "./components/ViewAccount";
import RouteDashboard from "./components/RouteDashboard";
import { ProtectRoute } from "./components/ProtectedRoutes";
import Header from "./components/Header";
import { useAuth0 } from "@auth0/auth0-react";
import { isMobileOnly } from "react-device-detect";
import { CircularProgress } from "material-ui";
interface RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}
//   export class Route<T extends RouteProps = RouteProps> extends React.Component<T, any> { }

const StepperRoute = () => {
  const { HideHeader, setHideHeader } = useContext(HideHeaderContext);
  const { toggle, settoggle } = useContext(UserContext);
  const { Auth, setAuth } = useContext(AuthContext);
  const { focus, setfocus } = useContext(focusContext);
  const { user, isLoading } = useAuth0();
  console.log(focus);

  if (isLoading) {
    return <CircularProgress />;
  } else {
    return (
      <Router>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <ProtectRoute exact path="/home" component={Home} />
            <ProtectRoute exact path="/listed" component={Home} />
            <ProtectRoute exact path="/notlisted" component={Home} />
            <ProtectRoute exact path="/sold" component={Home} />
            <Route exact path="/paymentScreen" component={PaymentScreen} />
            <Route exact path="/teamCredentials" component={TeamCredentials} />
            <Route exact path="/viewAccount" component={ViewAccount} />
            {/* <ProtectRoute path= "/home" component ={RouteDashboard}/> */}
          </>
        )}
        {!toggle ? (
          <Route exact path="/:par" component={CustomizedSteppers} />
        ) : null}
      </Router>
    );
  }
};

export default StepperRoute;
