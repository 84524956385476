import { credentialReducer } from "./credential/reducers";
import { seatsetsReducer } from "./seatSets/reducers";
import { dashboardReducer } from "./dashboard/reducers";
import { combineReducers } from "redux";

export const rootReducer = combineReducers({
  credential: credentialReducer,
  seatsets: seatsetsReducer,
  dashboard: dashboardReducer
});

export type RootState = ReturnType<typeof rootReducer>;
