import { memo, Dispatch } from "react";
import "./TicketCard.css";
import PopUpScreen from "../DashboardPopUpScreen";
import { updateTicket } from "../../store/dashboard/action";
import { useDispatch } from "react-redux";
import line from "../../images/Line2.png";

export interface TicketCardModel {
  imageSrc?: string;
  seatImg?: string;
  dollarImg?: string;
  data?: any;
  ticketUpdated?(newObj: any): void;
  buttonTapped(object?: any): void;
  handleModalClose(str: String): any;
}

function getTeamNames(data?: any): any[] {
  let upperName = data?.split(" ");
  let bottomName = upperName?.pop(upperName.length - 1);
  return [upperName || " ", bottomName || " "];
}

function getSeatDetails(data?: any): any[] {
  let seats = data?.seats.join("-");

  let leftText = `${data?.section} Row ${data?.row}, ${seats}`;
  let rightText = data?.payout || data?.client_payout
  rightText = rightText.toFixed(2)
  return [leftText, rightText];
}

function getDates(dateStr: string): any[] {
  let date = new Date(dateStr);
  let day = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(date);
  let month = new Intl.DateTimeFormat("en", { month: "short" }).format(date);
  let year = new Intl.DateTimeFormat("en", { year: "numeric" }).format(date);

  let time = new Intl.DateTimeFormat("en", {
    hour: "numeric",
    minute: "numeric",
  }).format(date);
  let weekday = new Intl.DateTimeFormat("en", { weekday: "short" }).format(
    date
  );

  let leftStr = `${month} ${day} ${year}`;
  let rightStr = `${weekday} ${time}`;
  return [leftStr, rightStr];
}

function getButtonDetails(data: any) {
  switch (data.status) {
    case "NOT_LISTED":
      return { text: "LIST", classname: "list" };
    case "LISTED":
      return { text: "DE-LIST", classname: "de-list" };
    default:
      break;
  }
}

function TicketCard(props: TicketCardModel) {
  const leftTeam = getTeamNames(props.data?.home_team);
  const rightTeam = getTeamNames(props.data?.away_team);
  const dateTime = getDates(props.data?.event_date);
  const seatDets = getSeatDetails(props.data);
  const btnDetail = getButtonDetails(props.data);

  const dispatch: Dispatch<any> = useDispatch();
console.log(seatDets[0].split(",")[1])
  return (
    <div className="TicketCard position-relative d-flex flex-column align-items-center justify-content-center">
      <div className="upper-content w-100 flex-grow-1 position-relative flex-center flex-column">
        <img
          className="position-absolute w-100 h-100"
          src={props.imageSrc}
          alt=""
        />
        <div className="teams-div d-flex w-100 h-100 align-items-center justify-content-between">
          <div className="team-div d-flex flex-column">
            <label className="state-label">
              {leftTeam[0].toString().replace(",", " ")}
            </label>
            <label className="team-label">{leftTeam[1]}</label>
          </div>
          <label className="vs-label">VS</label>
          <div className="team-div d-flex flex-column justify-content-start">
            <label className="state-label">
              {rightTeam[0].toString().replace(",", " ")}
            </label>
            <label className="team-label">{rightTeam[1]}</label>
          </div>
        </div>
        <div className="flex-grow-1" />
        <div className="date-div w-100 d-flex align-items-center justify-content-between">
          <div className="left-date">{dateTime[0]}</div>
          <div className="right-date">{dateTime[1]}</div>
        </div>
        <div className="separator w-100"></div>
      </div>
      <div className="bottom-content flex-grow-1 w-100 position-relative flex-center flex-column justify-content-start">
        <div className="seat-det-div w-100 d-flex justify-content-between">
          <div className="image-text-div d-flex">
            <img src={props.seatImg} alt="" />
            <div>{seatDets[0]}</div>
          </div>
          <div className="image-text-div d-flex">
            <img src={props.dollarImg} alt="" />
            <div>{seatDets[1]}</div>
          </div>
        </div>
        {props.data?.status === "SOLD" ? null : (
          // <div
          //   className={`action-button ${btnDetail?.classname} d-flex align-items-center justify-content-center`}
          //   onClick={() => props.buttonTapped(props.data)}
          // >
          //   {btnDetail?.text}
          // </div>
          <PopUpScreen
            details={props.data}
            className={btnDetail?.classname || ""}
            setsData={seatDets[0].split(",")[1]}
            RefreshData={() => {}}
            updatebutton={(e: any) => {
              // updatebutton(ticket.status);
              let newObj = props.data;
              newObj.status =
                props.data.status === "LISTED" ? "NOT_LISTED" : "LISTED";
              dispatch(updateTicket(newObj));
              props.ticketUpdated && props.ticketUpdated(newObj);
            }}
            handleListButton={(buttonName: string) => {
              // let status = ticket?.status === "NOT_LISTED" ? "DE-LIST" : "LIST";
            }}
            label={btnDetail?.text || ""}
            disabled={btnDetail?.text === "SOLD"}
            onModalClose={(buttonName: string) =>
              props.handleModalClose(props.data.status)
            }
          />
        )}
      </div>
      {props.data?.status === "SOLD" ? (
        <div className="sold-ticket position-absolute w-100">
          <div className="sold-text position-absolute">Sold</div>
        </div>
      ) : null}
      {/* <div className="bottom-separator separator w-100"></div> */}
      <img src={line} />
    </div>
  );
}

export default memo(TicketCard);
