import React, { Dispatch, useContext } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { Formik } from "formik";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";
import VerifyResaleTicket from "./VerifyResaleTicket";
import VerifyResaleTicketMobile from "./VerifyResaleTicketMobile";
import {
  DataContext,
  myiconContext,
  UpdateForm,
  verifytoggle,
} from "../UserContext";
import {
  addSeatSets,
  removeSeatSets,
  updateSeatSets,
} from "../store/seatSets/action";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { useHistory } from "react-router";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useEffect } from "react";
const BillSchema = yup.object().shape({
  Section: yup.string().required('Required'),
  Row: yup.string().required('Required'),
  fromSeat: yup.string().matches(/^[0-9]+$/gi, "Must be a Number"),
  toSeat: yup.string().matches(/^[0-9]+$/gi, "Must be a Number"),
  price: yup.string().matches(/^[0-9]+$/gi, "Must be a Number").required('Required'),
});

interface ButtonProps {
  activeStep: number;
  handleBack(value: React.BaseSyntheticEvent): void;
  handleNext(value: any): void;

  handleReset(): void;
  handleNext(value: any): void;
  doubleBack(): void;
}

const ChooseBills: React.FC<ButtonProps> = (props) => {
  const [toggle, settoggle] = React.useState<boolean | "">(true);
  const { trackData, setTrackData } = useContext(DataContext);
  const [cancel, setcancel] = React.useState<any>(false);
  const { updateform, setupdateform } = useContext(UpdateForm);
  const { verify, setverify } = useContext(verifytoggle);
  const { Icon, setIcon } = useContext(myiconContext);
  const dispatch: Dispatch<any> = useDispatch();
  const history = useHistory();
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets.seatSets;
  });
  const ToggleHandler = (value: any) => {
    settoggle(value);
  };
  useEffect(() => {
    console.log(trackData);
  });
  const DeleteData = () => {
    console.log("delete");

    setTrackData({
      id: trackData.id,
      teams: {
        selectedTeam: trackData.teams.selectedTeam,
      },
      gamesID: trackData.gamesID,
      section: "",
      row: "",
      toSeat: "",
      fromSeat: "",
      price: "",
    });
    dispatch(removeSeatSets(trackData.id));
    props.handleNext(3);
    setverify(false);
    setupdateform(false);
    history.push("events");
  };

  const handleDelete = () => {
    confirmAlert({
      overlayClassName: "overlay_our",

      customUI: ({ onClose }) => {
        return (
          <div
            className="custom-ui"
            style={{
              width: isMobileOnly ? "330px" : "484px",
              height: isMobileOnly ? "195px" : "259px",
              paddingTop : isMobileOnly ? "8%" : ""
            }}
          >
            <div
              className="ui"
              style={{
                fontSize: isMobile ? 16 : 26,
                lineHeight: isMobileOnly ? "inherit" : "normal",
                width: isMobileOnly ? "auto" : "",
                paddingTop: isMobileOnly ? "2%" : "4%",
                paddingLeft : isMobile ? "" : "0px",
                paddingRight : isMobile ? "" : "0px",
              }}
            >
              {" "}
              Are you sure you want to delete these seats?
            </div>
            <Row
              style={{
               width : isMobile ? " " : "100%",  marginTop: isMobileOnly ? "16%" : "14%", marginLeft : isMobileOnly ? "0px" : "", marginRight: isMobileOnly ? "0px" : "",
              }}
            >
              {isMobile ? (
                 <Row style={{
                   marginRight:'65px'
                 }}>
                 <Button
                   variant="contained"
                   style={{
                     width: 120,
                     height: 48,

                     borderRadius: 50,
                     backgroundColor: "#5B5B5B",
                   }}
                   disabled={props.activeStep === 0}
                   onClick={() => {
                     onClose();
                   }}
                 >
                   <div style={{ color: "#FAFAFA" ,fontSize : "16px" }}>Cancel</div>
                 </Button>
               </Row>
              ) : (
                <Row style={{ paddingLeft: "7%" }}>
                  <Button
                    variant="contained"
                    style={{
                      width: 181  ,
                      height: 52,

                      borderRadius: 50,
                      backgroundColor: "#5B5B5B",
                    }}
                    disabled={props.activeStep === 0}
                    onClick={() => {
                      onClose();
                    }}
                  >
                    <div style={{ color: "#FAFAFA", fontSize : 20  }}>Cancel</div>
                  </Button>
                </Row>
              )}
              {isMobile ? (
                <Row>
               <Button
                 variant="contained"
                 style={{
                   width: 120,
                   height: 48,
                   borderRadius: 50,

                   backgroundColor: "#C57A1E",
                 }}
                 onClick={() => {
                   DeleteData();
                   onClose();
                 }}
               >
                 <div style={{ color: "#FAFAFA" ,fontSize : "16px" }}>Delete</div>
               </Button>
             </Row>
              ) : (
                <Row style={{ paddingLeft: "13%" }}>
                  <Button
                    variant="contained"
                    style={{
                      width: 181,
                      height: 52,
                      borderRadius: 50,

                      backgroundColor: "#C57A1E",
                    }}
                    onClick={() => {
                      DeleteData();
                      onClose();
                    }}
                  >
                    <div style={{ color: "#FAFAFA" , fontSize : 20 }}>Delete</div>
                  </Button>
                </Row>
              )}
            </Row>
          </div>
        );
      },
    });
  };
  console.log(trackData);
  return (
    <>
      <div
        className="container"
        style={{ paddingLeft: isMobileOnly ? "20px" : "" }}
      >
        {isMobileOnly ? (
          <div
            className="label"
            style={{
              fontFamily: "Atten New",
              fontSize: "20px",
              fontWeight: 700,
              color: "#BC936C",
              textAlign: "center",
            }}
          >
            Enter your {trackData.teams.selectedTeam[0].home_team} seat info
          </div>
        ) : isTablet ? (
          <div
            className="label"
            style={{
              color: "#BC936C",
              fontSize: "40px",
              fontFamily: "Atten New",
              fontWeight: 500,
              paddingTop: 50,
              paddingBottom: 50,
            }}
          >
            Enter your {trackData.teams.selectedTeam[0].home_team} seat info
          </div>
        ) : (
          <div
            className="label"
            style={{
              color: "#BC936C",
              fontSize: "40px",
              fontFamily: "Atten New",
              fontWeight: 500,
              paddingTop: 15,
              paddingBottom: 30,
            }}
          >
            Enter your {trackData.teams.selectedTeam[0].home_team} seat info
          </div>
        )}

        <Formik
          initialValues={{
            Section: trackData.section == undefined ? "" : trackData.section,
            Row: trackData.row == undefined ? "" : trackData.row,
            fromSeat: trackData.fromSeat == undefined ? "" : trackData.fromSeat,
            toSeat: trackData.toSeat == undefined ? "" : trackData.toSeat,
            price: trackData.price == undefined ? "" : trackData.price,
          }}
          validationSchema={BillSchema}
          onSubmit={(data: any) => {
            setTrackData({
              id: trackData.id,
              teams: {
                selectedTeam: trackData.teams.selectedTeam,
              },
              gamesID: trackData.gamesID,
              section: data.Section,
              row: data.Row,
              toSeat: data.toSeat,
              fromSeat: data.fromSeat,
              price: data.price,
            });
            setIcon([
              {
                active: false,
                completed: true,
                error: false,
                icon: 1,
              },
              {
                active: false,
                completed: true,
                error: false,
                icon: 2,
              },
              {
                active: false,
                completed: true,
                error: false,
                icon: 3,
              },
              {
                active: true,
                completed: false,
                error: false,
                icon: 4,
              },
            ]);
            props.handleNext(3);
            setverify(true);
            history.push("events");
          }}
        >
          {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            errors,
            touched,
          }) => (
            <Form
              onSubmit={handleSubmit}
              autoComplete={"off"}
              style={{
                marginLeft: isMobileOnly ? "" : isTablet ? "" : "100px",
                marginRight: isMobileOnly ? "6px" : isTablet ? "" : "100px",
                marginTop: isMobileOnly ? "30px" : "2rem",
              }}
            >
              <Row>
                <Col>
                  <Form.Group
                    controlId="Section"
                    style={{ marginBottom: isMobileOnly ? "" : "1rem" }}
                  >
                    <Form.Label
                      style={{
                        color: "#FFFFFF",
                        float: "left",
                        fontWeight: 400,
                        fontSize: isMobileOnly
                          ? "16px"
                          : isTablet
                          ? "19px"
                          : "16px",
                        fontFamily: "Atten New",
                      }}
                    >
                      Section
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter your section"
                      value={values.Section}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        height: isMobileOnly ? "48px" : "60px",
                        backgroundColor: "#151515",
                        color: "#FAFAFA",
                        border: "1px solid rgba(255, 255, 255, 0.14)",
                      }}
                    />
                    {errors.Section && touched.Section ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          float: "left",
                        }}
                      >
                        {errors.Section}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group
                    controlId="Row"
                    style={{
                      marginTop: isMobileOnly && isTablet ? "2%" : "",
                      marginBottom: "1rem",
                    }}
                  >
                    <Form.Label
                      style={{
                        color: "#FFFFFF",
                        float: "left",
                        fontWeight: 400,
                        fontSize: isMobileOnly
                          ? "16px"
                          : isTablet
                          ? "19px"
                          : "16px",
                        fontFamily: "Atten New",
                      }}
                    >
                      Row
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter row number"
                      value={values.Row}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        height: isMobileOnly ? "48px" : "60px",
                        backgroundColor: "#151515",
                        color: "#FAFAFA",
                        border: "1px solid rgba(255, 255, 255, 0.14)",
                      }}
                    />
                    {errors.Row && touched.Row ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          float: "left",
                        }}
                      >
                        {errors.Row}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                {isMobileOnly ? (
                  <Row style={{ width: "100%", paddingLeft: "28px" }}>
                    <div className="form-inline" style={{}}>
                      <div style={{ width: "100%" }}>
                        <Form.Label
                          style={{
                            color: "#FFFFFF",
                            float: "left",
                            fontWeight: 700,
                            fontSize: isMobileOnly
                              ? "16px"
                              : isTablet
                              ? "19px"
                              : "",
                            fontFamily: "Atten New",
                          }}
                        >
                          Seats
                        </Form.Label>
                      </div>
                    </div>

                    <Row>
                      <Col style={{ padding: "0px" }}>
                        <div className="form-group mr-2" style={{}}>
                          <Form.Group
                            controlId="fromSeat"
                           inputMode="numeric"
                            className="col"
                            style={{
                              marginTop: isMobile ? "" : "2%",
                              paddingRight: "0px",
                            }}
                          >
                            <Form.Control
                              type="text"
                              inputMode="numeric"
                              placeholder="00"
                              className="text"
                              value={values.fromSeat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                height: isMobileOnly ? "48px" : "60px",
                                backgroundColor: "#151515",
                                color: "#FAFAFA",
                                border: "1px solid rgba(255, 255, 255, 0.14)",
                              }}
                            />
                          </Form.Group>
                          {errors.fromSeat && touched.fromSeat ? (
                            <div
                              style={{
                                fontSize: "12px",
                                float: "left",
                                color: "red",
                                marginTop: "-15px",
                              }}
                            >
                              {errors.fromSeat}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        className="col-1"
                        style={{
                          marginTop: "20px",
                          paddingRight: isMobileOnly ? "34px" : "",
                        }}
                      >
                        <text
                          className="form-group mr-2"
                          style={{
                            color: "#FFFFFF",
                            fontSize: isMobileOnly
                              ? "13px"
                              : isTablet
                              ? "19px"
                              : "",
                            float: "left",
                            fontWeight: 700,
                            fontFamily: "Atten New",
                          }}
                        >
                          {" "}
                          To{" "}
                        </text>
                      </Col>
                      <Col style={{ padding: "0px" }}>
                        <div
                          className="form-group mr-2"
                          style={{ marginTop: "1%", marginBottom: "1%" }}
                        >
                          <Form.Group
                            className="col"
                            inputMode="numeric"
                            controlId="toSeat"
                            style={{
                              marginTop: isMobile ? "" : "2%",
                              paddingLeft: "0px",
                            }}
                          >
                            <Form.Control
                              type="text"
                              inputMode="numeric"
                              className="text"
                              placeholder="00"
                              value={values.toSeat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                height: isMobileOnly ? "48px" : "60px",
                                backgroundColor: "#151515",
                                color: "#FAFAFA",
                                border: "1px solid rgba(255, 255, 255, 0.14)",
                              }}
                            />
                          </Form.Group>
                          {errors.toSeat && touched.toSeat ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                                marginTop: "-15px",
                                marginLeft: "-70px",
                              }}
                            >
                              {errors.toSeat}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                  </Row>
                ) : (
                  <Col className="col-4">
                    <div
                      className="form-inline"
                      style={{ marginBottom: "6px" }}
                    >
                      <div style={{ width: "100%" }}>
                        <Form.Label
                          style={{
                            color: "#FFFFFF",
                            float: "left",
                            fontWeight: 400,
                            fontSize: isMobileOnly
                              ? "12px"
                              : isTablet
                              ? "19px"
                              : "16px",
                            fontFamily: "Atten New",
                          }}
                        >
                          Seats
                        </Form.Label>
                      </div>
                    </div>

                    <Row>
                      <Col className="col-5" style={{ padding: "0px" }}>
                        <div className="form-group mr-2" style={{}}>
                          <Form.Group
                            controlId="fromSeat"
                            className="col"
                            style={{
                              marginTop: isMobile ? "10%" : "2%",
                              paddingRight: "0px",
                              marginBottom: isMobile ? "13%" : "",
                            }}
                          >
                            <Form.Control
                              type="text"
                              placeholder="00"
                              className="text"
                              value={values.fromSeat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                height: "60px",
                                backgroundColor: "#151515",
                                color: "#FAFAFA",
                                textAlign: "center",
                                border: "1px solid rgba(255, 255, 255, 0.14)",
                              }}
                            />
                          </Form.Group>
                          {errors.fromSeat && touched.fromSeat ? (
                            <div
                              style={{
                                fontSize: "12px",
                                float: "left",
                                color: "red",
                                marginTop: "-15px",
                                marginLeft: "17px",
                              }}
                            >
                              {errors.fromSeat}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                      <Col
                        className="col-2"
                        style={{
                          marginTop: isTablet ? "30px" : "19px",
                          marginBottom: "21px",
                          paddingLeft: isTablet ? "20px" : "",
                        }}
                      >
                        <text
                          className="form-group mr-2"
                          style={{
                            color: "#FAFAFA",
                            fontSize: isMobileOnly
                              ? "12px"
                              : isTablet
                              ? "19px"
                              : "16px",
                            float: "left",
                            fontWeight: 400,
                            fontFamily: "Atten New",
                          }}
                        >
                          {" "}
                          to{" "}
                        </text>
                      </Col>
                      <Col className="col-5" style={{ padding: "0px" }}>
                        <div
                          className="form-group mr-2"
                          style={{ marginTop: "1%", marginBottom: "1%" }}
                        >
                          <Form.Group
                            className="col"
                            controlId="toSeat"
                            style={{
                              marginTop: isMobile ? "10%" : "2%",
                              paddingLeft: "0px",
                            }}
                          >
                            <Form.Control
                              type="text"
                              className="text"
                              placeholder="00"
                              value={values.toSeat}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              style={{
                                height: "60px",
                                backgroundColor: "#151515",
                                color: "#FAFAFA",
                                textAlign: "center",
                                border: "1px solid rgba(255, 255, 255, 0.14)",
                              }}
                            />
                          </Form.Group>
                          {errors.toSeat && touched.toSeat ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                                marginTop: "-15px",
                                marginLeft: "-70px",
                              }}
                            >
                              {errors.toSeat}
                            </div>
                          ) : null}
                        </div>
                      </Col>
                    </Row>

                    {updateform === true && trackData.price !== "" ? (
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          height: 50,
                          borderRadius: "6px",
                          marginTop: "5%",
                          backgroundColor: "black",
                          borderColor: "#C8A178",
                        }}
                        onClick={() => handleDelete()}
                      >
                        <div style={{ color: "#BC936B" }}>DELETE</div>
                      </Button>
                    ) : null}
                  </Col>
                )}
                <Col style={{ paddingLeft: isMobile ? "" : "7rem" }}>
                  <Form.Label
                    style={{
                      color: "#FFFFFF",
                      float: "left",
                      fontWeight: 400,
                      fontSize: isMobileOnly
                        ? "16px"
                        : isTablet
                        ? "19px"
                        : "16px",
                      fontFamily: "Atten New",
                      marginBottom: isTablet ? "20px" : "10px",
                    }}
                  >
                    Average Face Value $
                  </Form.Label>

                  <Form.Group controlId="price">
                    <Form.Control
                      type="text"
                      placeholder="Enter price"
                      value={values.price}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{
                        height: isMobileOnly ? "48px" : "60px",
                        backgroundColor: "#151515",
                        color: "#FAFAFA",
                        border: "1px solid rgba(255, 255, 255, 0.14)",
                      }}
                    />
                    {errors.price && touched.price ? (
                      <div
                        style={{
                          color: "red",
                          fontSize: "12px",
                          float: "left",
                        }}
                      >
                        {errors.price}
                      </div>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>
              {isMobileOnly ? (
                <Col
                  style={{
                    marginTop: "10%",
                    marginBottom: "3%",
                    marginLeft: "-2px",
                    width: "100%",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                  }}
                >
                  <Col style={{ padding: isMobileOnly ? "" : "0px" }}>
                    <Button
                      variant="contained"
                      style={{
                        width: "100%",
                        height: 50,
                        borderRadius: 50,

                        backgroundColor: "#C57A1E",
                      }}
                      onClick={() => handleSubmit()}
                    >
                      <div style={{ color: "#FCFCFC" }}>CONTINUE</div>
                    </Button>
                    {updateform === true && trackData.price !== "" ? (
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          height: 50,
                          borderRadius: 50,
                          marginTop: "5%",
                          backgroundColor: "#010101",
                          border: "2px solid #BC936B",
                        }}
                        onClick={() => handleDelete()}
                      >
                        <div style={{ color: "#BC936B" }}>Delete</div>
                      </Button>
                    ) : null}
                  </Col>
                  {/* <Col style={{ marginTop: "15px" }}>
                      <Button
                        variant="contained"
                        style={{
                          width: "100%",
                          height: 50,
                          // paddingRight: "45px",
                          borderRadius: 50,
                          backgroundColor: "#BC936C",
                        }}
                        disabled={props.activeStep === 0}
                        onClick={(e) => { props.handleBack(e) }}
                      >
                        <div style={{ color: "#FAFAFA", paddingRight: "0px" }}>
                          BACK
                        </div>
                      </Button>
                    </Col> */}
                </Col>
              ) : (
                <Row
                  style={{
                    marginTop: "11%",
                    marginBottom: "11%",
                  }}
                >
                  <Col>
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        height: 50,

                        borderRadius: 50,
                        backgroundColor: "#5B5B5B",
                        float: "left",
                      }}
                      disabled={props.activeStep === 0}
                      onClick={(e) => {
                        props.handleBack(e);
                      }}
                    >
                      <div style={{ color: "#FAFAFA" }}>BACK</div>
                    </Button>
                  </Col>

                  <Col>
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        height: 50,
                        borderRadius: 50,

                        backgroundColor: "#C57A1E",
                        float: "right",
                      }}
                      onClick={() => handleSubmit()}
                    >
                      <div style={{ color: "#FCFCFC" }}>NEXT</div>
                    </Button>
                  </Col>
                </Row>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default ChooseBills;
