import React, {useState, useRef} from 'react'
import logo from './logo.svg'
import './App.css'
import CustomizedSteppers from './screens/Stepper'
import {MuiThemeProvider} from 'material-ui/styles'
import Routes from './Routes'
import {
  DataContext,
  UserContext,
  UpdateForm,
  hideData,
  myiconContext,
  focusContext,
  AuthContext,
  InvContext,
  PaymentScreenContext,
  HideHeaderContext,
  verifytoggle,
  teamCredContext,
  teamShowContext,
  TotalContext,
  DashboardContext,
  loginContext,
  SignupContext,
  IdContext,
  ReloadContext,
  activeTabContext,
  updatedTicketsRefContext,
  ContextSpinner,
  forceReRenderContext,
} from './UserContext'
import {useAuth0} from '@auth0/auth0-react'
import {CircularProgress} from 'material-ui'
import {localStorageGetLastTab} from './components/TabVanilla'
import Header from './components/Header'

interface gameObject {
  datetime: string
  gameId: string
}

export interface form {
  id: number
  teams: {
    selectedTeam: number
  }
  gamesID: gameObject[]
  section: string
  row: string
  toSeat: number
  fromSeat: number
  price: number
}
interface appCtxDefaultValue {
  trackData: {
    id: 0
    teams: {
      selectedTeam: 0
    }
    gamesID: []
    section: ''
    row: ''
    toSeat: 0
    fromSeat: 0
    price: 0
  }
  setTrackData: (trackData: {
    id: 0
    teams: {
      selectedTeam: 0
    }
    gamesID: []
    section: ''
    row: ''
    toSeat: 0
    fromSeat: 0
    price: 0
  }) => void
}
function App() {
  const [trackData, setTrackData] = useState({
    trackData: {
      id: 0,
      teams: {
        selectedTeam: 0,
      },
      gamesID: [],
      section: '',
      row: '',
      toSeat: 0,
      fromSeat: 0,
      price: 0,
    },
    setTrackData: (trackData: {
      id: 0
      teams: {
        selectedTeam: 0
      }
      gamesID: []
      section: ''
      row: ''
      toSeat: 0
      fromSeat: 0
      price: 0
    }) => {},
  })

  const [toggle, settoggle] = useState<boolean>()
  const [updateform, setupdateform] = useState<boolean>()
  const [Reload, setReload] = useState<boolean>(false)
  const [Hide, setHideData] = useState<boolean>(false)
  const [Auth, setAuth] = useState<any>()
  const [focus, setfocus] = useState<any>(false)
  const [verify, setverify] = useState<any>(true)
  const [Icon, setIcon] = useState<any>([
    {
      active: true,
      completed: false,
      error: false,
      icon: 1,
    },
    {
      active: false,
      completed: false,
      error: false,
      icon: 2,
    },
    {
      active: false,
      completed: false,
      error: false,
      icon: 3,
    },
    {
      active: false,
      completed: false,
      error: false,
      icon: 4,
    },
  ])

  const {isLoading} = useAuth0()
  const [Inv, setInv] = useState<any>([])
  const [login, setlogin] = useState<any>()
  const [signup, setsignup] = useState<any>()
  const [Paymnet, setPaymnet] = useState<any>()
  const [HideHeader, setHideHeader] = useState<boolean>(false)
  const [TeamCred, setTeamCred] = useState<any>([])
  const [teamShow, setteamShow] = useState<any>([])
  const [AuthID, setAuthID] = useState<any>()
  const [total, settotal] = useState<any>([])
  const [spinner, setspinner] = useState<any>()
  const [DashboardData, setDashboardData] = useState<any>([])
  const {isAuthenticated} = useAuth0()
  const [active, setActive] = useState(localStorageGetLastTab()) // ~sahil , added for tab change enhanced animation.
  const updatedTickets = useRef<any[]>([]) //~sahil , this is shifted to upper node in the component tree so that header component can access it anyway!
  const [_, forceReRender] = useState<any>({})

  return (
    <div className='App'>
      <div style={{color: 'deeppink'}}></div>

      <MuiThemeProvider>
        <UserContext.Provider value={{toggle, settoggle}}>
          <DataContext.Provider value={{trackData, setTrackData}}>
            <UpdateForm.Provider value={{updateform, setupdateform}}>
              <hideData.Provider value={{Hide, setHideData}}>
                <myiconContext.Provider value={{Icon, setIcon}}>
                  <focusContext.Provider value={{focus, setfocus}}>
                    <AuthContext.Provider value={{Auth, setAuth}}>
                      <verifytoggle.Provider value={{verify, setverify}}>
                        <InvContext.Provider value={{Inv, setInv}}>
                          <PaymentScreenContext.Provider value={{Paymnet, setPaymnet}}>
                            <forceReRenderContext.Provider value={{forceReRender}}>
                              <HideHeaderContext.Provider value={{HideHeader, setHideHeader}}>
                                <teamCredContext.Provider value={{TeamCred, setTeamCred}}>
                                  <teamShowContext.Provider value={{teamShow, setteamShow}}>
                                    <TotalContext.Provider value={{total, settotal}}>
                                      <activeTabContext.Provider value={{active, setActive}}>
                                        <updatedTicketsRefContext.Provider value={updatedTickets}>
                                          <ContextSpinner.Provider value={{spinner, setspinner}}>
                                            <DashboardContext.Provider
                                              value={{
                                                DashboardData,
                                                setDashboardData,
                                              }}>
                                              <SignupContext.Provider value={{signup, setsignup}}>
                                                <loginContext.Provider value={{login, setlogin}}>
                                                  <IdContext.Provider value={{AuthID, setAuthID}}>
                                                    <ReloadContext.Provider value={{Reload, setReload}}>
                                                      <Routes />
                                                    </ReloadContext.Provider>
                                                  </IdContext.Provider>
                                                </loginContext.Provider>
                                              </SignupContext.Provider>{' '}
                                            </DashboardContext.Provider>
                                          </ContextSpinner.Provider>
                                        </updatedTicketsRefContext.Provider>
                                      </activeTabContext.Provider>
                                    </TotalContext.Provider>
                                  </teamShowContext.Provider>
                                </teamCredContext.Provider>
                              </HideHeaderContext.Provider>
                            </forceReRenderContext.Provider>
                          </PaymentScreenContext.Provider>
                        </InvContext.Provider>
                      </verifytoggle.Provider>
                    </AuthContext.Provider>
                  </focusContext.Provider>
                </myiconContext.Provider>
              </hideData.Provider>
            </UpdateForm.Provider>
          </DataContext.Provider>
        </UserContext.Provider>
      </MuiThemeProvider>
    </div>
  )
}

export default App
