import {confirmAlert} from 'react-confirm-alert'
import React, {useState, useContext, useEffect, useCallback} from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import * as yup from 'yup'
import {isMobile, isMobileOnly, isTablet} from 'react-device-detect'
import {Row, Col, Container} from 'react-bootstrap'
import Header from './Header'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Delete from '../assets/deleteIcon.svg'
import Vector from '../images/Vector.png'
import clsx from 'clsx'
import {useHistory} from 'react-router-dom'
import './TeamCredentials.css'
import {ClosedCaptionTwoTone} from '@material-ui/icons'
import {divide} from 'lodash'
import {InvContext, HideHeaderContext, teamCredContext} from '../UserContext'
// import SelectSearch from 'react-select-search';
import Select from 'react-select'
import {isNullishCoalesce} from 'typescript'
import {data} from 'jquery'
import EditPencil from '../images/EditPencil.svg'
import {useAuth0} from '@auth0/auth0-react'
import {CircularProgress, List, ListItem} from 'material-ui'
import {Formik} from 'formik'
import {ListItemText} from '@material-ui/core'
import {API_END_POINT} from '../utils/Constant'
import {HeaderMinimal} from './HeaderMinimal'
import axios, {AxiosRequestConfig} from 'axios'
import {ENV} from '../utils/environmentConfig'

const dJSON = require('dirty-json')

const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // flexGrow: 1, // ~sahil, this is redundant IMO so commented it.
    },
    indicator: {
      color: '#BC936B',
    },

    //~sahil, Purged below code in favor of inline styling.
    // title: {
    // marginTop: '20px', // ~sahil, I am using flex things now, so ditch marginTop now..!
    // marginLeft: isMobile ? '25px' : '50px',
    // marginLeft: isMobile ? '3%' : '50px',
    // textAlign: 'left',
    // },
    avatar: {
      heigh: '42px',
      width: '42px',
      marginRight: '10px',
      backgroundColor: '#A39C73',
      color: '#FFFFFF',
      marginTop: '20px',
    },
    outer_width: {
      width: isMobile ? '375px' : '1440px',
      padding: isMobile ? '' : '40px 0',
    },

    navbar: {
      width: isMobile ? '345px' : '1383px',
      marginLeft: isMobile ? '10px' : '70px',
    },
    navbarText: {
      // float: "left",
      marginTop: '10px',
      alignItems: 'center',
      color: '#C57A1E',
      fontFamily: 'Atten New',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '18px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.14)',
      display: 'flex',
      flexGrow: isMobile ? 0 : 1,
    },
    indicatorColor: {
      // background: "#BC936B",
      // borderBottom: "#BC936B",
    },
  })
)

// const TeamCredSchema = yup.object().shape({
//   TeamName: yup
//     .string()
//     .min(2, "Too Short!")
//     .max(50, "Too Long!")
//     .required("Required"),
//   Emailaddress: yup.string().required("Required"),
//   Password: yup.string().required("Required"),
//   ConfirmPassword: yup.string().required("Required"),
// });

interface ButtonProps {
  handleBack(value: React.BaseSyntheticEvent): void
  handleNext(value: any): void
  handleNext(value: any): void
  TeamName: any
  onSubmit(): void
  callbackfunction(): void
}

const TeamCredentialsBootstrap: React.FC<ButtonProps> = (props) => {
  const [Credentials, setCredentials] = React.useState([])
  const [submitClicked, setSubmitClicked] = React.useState(false)
  const {Inv, setInv} = useContext(InvContext)
  const {HideHeader, setHideHeader} = useContext(HideHeaderContext)
  const {TeamCred, setTeamCred} = useContext(teamCredContext)
  const [hideCredentials, sethideCredentials] = React.useState(false)
  const [allTeams, setAllTeams] = React.useState([])
  const [wrongPassword, setWrongPassword] = React.useState([false])
  const [wrongEmail, setWrongEmail] = React.useState(false)
  const [select, setSelect] = React.useState({label: '', value: '', id: 0})
  const [EditData, setEditData] = useState({
    clientid: 0,
    psw: '',
    recordid: 0,
    teamName: '',
    teamemail: '',
    teamid: 0,
    updated: '',
  })
  const [formData, setFormData] = React.useState([])
  const {user, getIdTokenClaims} = useAuth0<any>()
  const [teams, setTeams] = useState([
    {
      TeamName: '',
      EmailAddress: '',
      Password: '',
      ConfirmPassword: '',
    },
  ])

  const [password, setPassword] = useState([
    {
      actualValue: '',
      patternValue: '',
      confirmActualValue: '',
      confirmPatternValue: '',
    },
  ])
  const [showProgressBar, setShowProgressBar] = useState(false)
  //const [actualValue,setactualValue] =useState("")
  //const [patternValue,setpatternValue] = useState("")

  //const [confirmActualValue,setConfirmActualValue] =useState("")
  //const [confirmPatternValue,setConfirmPatternValue] = useState("")
  const [CredentialData, setCredentialData] = useState<any>([])

  const classes = useStyles()
  let history = useHistory()

  const [errorTeamName, setErrorTeamName] = useState('')

  const customStyle = {
    height: '80px',
    backgroundColor: '#151515',
    color: 'red',
    border: '1px solid rgba(255, 255, 255, 0.2)',
  }

  const onAddTeam = () => {
    const team = {
      TeamName: '',
      EmailAddress: '',
      Password: '',
      ConfirmPassword: '',
    }
    const emptypasswd = {
      actualValue: '',
      patternValue: '',
      confirmActualValue: '',
      confirmPatternValue: '',
    }
    const newArray2 = [...password]
    const newTeams = [...teams]
    newTeams.push(team)
    newArray2.push(emptypasswd)
    setTeams(newTeams)
    setPassword(newArray2)
  }
  const handleChange = (team: any, field: any, value: any, index: any) => {
    // setWrongPassword(false)
    const newTeams = [...teams]
    team[field] = value
    newTeams[index] = team
    setTeams(newTeams)
  }

  const closeTeam = (team: any, index: any) => {
    const newTeams = [...teams]
    newTeams.splice(index, 1)
    setTeams(newTeams)
  }

  const SignUpSchema = yup.object().shape({
    Email: yup.string().email('Invalid email'),
    TeamName: yup.string(),
    Password: yup.string().required('Password is required'),
    ConfirmPassword: yup.string().oneOf([yup.ref('Password'), null], 'Passwords must match'),
    EmailAddress: yup.string().required('Required').email('Invalid email'),
  })

  const onSubmit = () => {
    console.log('inside onSubmit')
    setSubmitClicked(true)
  }
  const validEmail = (email: any) => {
    if (!pattern.test(email)) {
      setWrongEmail(true)
    }
  }
  const saveteam = (e: any) => {
    console.log('insode save', e.target)
    // history.push("/");
  }

  const handleSubmit = (e: any) => {
    e.preventDefault()

    if (!pattern.test(teams[0].EmailAddress)) {
      return
    }
    let temp = teams[0]
    temp.TeamName = e.target.TeamName.value
    teams[0] = temp

    let flag = 0
    password.map((item: any, index: any) => {
      if (item.actualValue !== item.confirmActualValue) {
        wrongPassword[index] = true
        setWrongPassword(wrongPassword)
        flag = 1
        return
      }
    })
    console.log('flag', wrongPassword)
    if (flag === 1) {
      setSubmitClicked(false)
      console.log('inside if')
      return false
    }

    var axios = require('axios')
    let urlProd = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAMCREDENTIAL}`

    let urlDevAndLocal = 'https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/team_credentials'
    setSubmitClicked(false)

    const url = ENV === 'prod' ? urlProd : urlDevAndLocal

    axios
      .post(url, teams)
      .then(function (response: any) {
        if (response.status === 200) {
          console.log('new team added', response.status)
          alert('new team added')
          setSubmitClicked(false)
        }
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }
  const cancelteam = () => {
    history.push('/home')
  }

  const fetchAndSetTeamCredentialsInState = async (): Promise<any> => {
    //🔽︎ ~sahil, fetchig teamCredentialsData again so that we can show teams with newly added team...
    setTimeout(async () => {
      const Token = await getIdTokenClaims()
      let id = user['https://tiqassist.com/user_id']

      const urlProd = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAMCREDENTIAL}/${id}`
      const urlDevAndLocal = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/team_credentials/${id}`

      const url = ENV === 'prod' ? urlProd : urlDevAndLocal

      let config: AxiosRequestConfig = {
        method: 'get',
        url: url,
        headers: {Authorization: `Bearer ${Token.__raw}`},
      }

      let response = await axios(config)
      console.log('teamCred', response.data)
      console.log(response.data)
      // debugger
      // setCredentialData(response.data.info)
      // let obj = JSON.parse(response.data)
      // let info = JSON.parse(response.data.info)
      // debugger
      // console.log('~sahil credentialdata', {obj})
      // console.log('~sahil credentialdata', {info})

      // const data = dJSON.parse(response.data)//~sahil, to parse the dirty json with nan value.
      const data = response.data
      // debugger
      setCredentialData(data.info)
      // setCredentialData(response.data) //~sahil, is this the solution.

      // return response
      return {data}
    })
    //🔼︎ ~sahil, fetchig teamCredentialsData again so that we can show teams with newly added team...
  }

  const DeleteData = (currentId: any) => {
    ;(async () => {
      let id = user['https://tiqassist.com/user_id']
      let recordid = currentId

      try {
        setShowProgressBar(true) // mmmmmmmmmmmmm

        const token = await getIdTokenClaims()
        // debugger
        // const herokuEndpoint = 'https://tiqassist-stage-api.herokuapp.com/ta_api/team_credentials/112/18'

        // const apiEndpoint = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/team_credential/`
        // const apiEndpoint = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/team_credential/${id}/${recordid}`
        // const apiEndpointnew = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/dashboard/${id}`

        // const response = await axios.get(apiEndpoint, {
        // debugger
        // const response = await axios.delete(apiEndpoint, {
        //   headers: {
        //     Authorization: `Bearer ${token.__raw}`,
        //   },
        // })

        // 💯︎ Below code works coz of axios config method way..
        // debugger

        const localAndDevUrl = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/team_credentials/${id}/${recordid}`
        const productionUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAMCREDENTIAL}/${id}/${recordid}`

        const url = ENV === 'prod' ? productionUrl : localAndDevUrl

        const config: AxiosRequestConfig = {
          method: 'get',
          url: url,
          headers: {Authorization: `Bearer ${token.__raw}`},
        }
        let res = await axios(config)
        console.log('~sahil res-cors')

        await fetchAndSetTeamCredentialsInState()

        setShowProgressBar(false) // mmmmmmmmmmmmm

        // 🔽︎🔽︎ Using native fetch library of browser..
        // const res = await fetch(apiEndpoint, {
        //   // const res = await fetch(herokuEndpoint, {
        //   // method: 'delete',
        //   method: 'post',
        //   credentials: 'include', // 🍪︎ Note: It is important to use ``credentials true`` to be able to send the cookies to the express server.
        //   mode: 'cors', //redundant, coz its default.
        //   headers: {
        //     Authorization: `Bearer ${token.__raw}`,
        //   },
        // })
        // console.log('~sahil cors', res.status)

        // 🔽︎🔽︎ Using heorku request copied from postman...
        // let myHeaders = new Headers()
        // myHeaders.append('Authorization', 'Basic dGFfY2xpZW50X2Rhc2hib2FyZDpVNk13VnQ0QXhlRGZWTC5iLU5rXw==')

        // const res = await fetch('https://tiqassist-stage-api.herokuapp.com/ta_api/team_credentials/112/18', {
        //   method: 'DELETE',
        //   headers: myHeaders,
        //   redirect: 'follow',
        //   // redirect: 'follow',
        // })
      } catch (e) {
        console.error(e)
      }
    })()
  }

  const handleDelete = (item: any) => {
    const id = item?.recordid
    confirmAlert({
      overlayClassName: 'overlay_our',

      customUI: ({onClose}) => {
        return (
          <div className='custom-ui'>
            <div className='ui' style={{}}>
              Are you sure you want to delete ?
            </div>
            <div
              className='d-flex justify-content-center'
              style={{
                marginTop: '1.6rem',
                // marginTop: '23%'
                // ^^ ~sahil, we dont' need this.
              }}>
              {/* <Col style={{paddingLeft: '16%'}}> */}
              <Button
                className='btn-action'
                variant='contained'
                style={{
                  height: 50,
                  // TODO: FIX SIDE MARGINs.~sahil
                  // margin: 'auto 20px',
                  borderRadius: 50,
                  backgroundColor: '#5B5B5B',
                }}
                // disabled={props.activeStep === 0}
                onClick={() => {
                  onClose()
                }}>
                <div style={{color: '#FAFAFA'}}>Cancel</div>
              </Button>
              {/* </Col> */}
              {/* <Col> */}
              <Button
                className='btn-action'
                variant='contained'
                style={{
                  height: 50,
                  borderRadius: 50,
                  marginLeft: 30,

                  backgroundColor: '#C57A1E',
                }}
                onClick={() => {
                  DeleteData(id) // TODO:

                  onClose()
                }}>
                <div style={{color: '#FCFCFC'}}>Delete</div>
              </Button>
              {/* </Col> */}
            </div>
          </div>
        )
      },
    })
  }

  useEffect(() => {
    setHideHeader(false)
  }, [HideHeader])
  const [isSE, setSE] = useState(window.innerWidth < 321)
  const [is8Plus, set8Plus] = useState(window.innerWidth < 415 && window.innerWidth > 410)
  const [isIphone12, setIphone12] = useState(window.innerWidth < 428 && window.innerWidth > 422)
  const [form, setform] = useState(false)
  const updateMedia = () => {
    setSE(window.innerWidth < 321)
    set8Plus(window.innerWidth < 415 && window.innerWidth > 410)
    setIphone12(window.innerWidth < 428 && window.innerWidth > 422)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  useEffect(() => {
    ;(async () => {
      try {
        // ? Fetching the initial teamCredentials on pageload.
        let response = await fetchAndSetTeamCredentialsInState()

        let data = response.data.info ? response.data.info.length : 0

        console.log('data', data)
        if (data !== 0) {
          setTeams([])

          teams.pop()
          password.pop()
          let newArray = teams
          let newArray3 = password
          response.data?.info?.map((team: any, index: any) => {
            console.log('index..', index, password[index])
            let obj1 = {
              TeamName: team.teamName,
              EmailAddress: team.teamemail || '',
              Password: '*'.repeat(team.psw.length) || '',
              ConfirmPassword: '*'.repeat(team.psw.length) || '',
            }
            let obj2 = {
              actualValue: '*'.repeat(team.psw.length) || '',
              patternValue: '*'.repeat(team.psw.length) || '',
              confirmActualValue: '*'.repeat(team.psw.length) || '',
              confirmPatternValue: '*'.repeat(team.psw.length) || '',
            }
            newArray.push(obj1)
            newArray3.push(obj2)

            setTeams(newArray)
            setPassword(newArray3)
          })
        }
      } catch (error) {
        console.log(error)
      }

      console.log(teams)
      console.log(password)
    })()
  }, []) //~sahi, is this bug 9 jun, 21.
  // }, [teams.length < 0])

  // 🔽︎ Below fetchTeamsCallback fetches the teams in the dropdown teams selection in <Add Team> screen.

  const localAndDevUrl = 'https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/teams'
  const prodUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAM}`

  const url = ENV === 'prod' ? prodUrl : localAndDevUrl

  const fetchTeamsCallback = useCallback(() => {
    const config: AxiosRequestConfig = {
      method: 'get',
      url: url,
      headers: {},
    }

    axios(config)
      .then(function (response: any) {
        console.log(response.data)
        console.log(JSON.stringify('allTeams', response.data))

        setAllTeams(
          response.data.map((item: any) => {
            return {
              value: item.home_team,
              label: item.home_team,
              id: item.sky_id,
            }
          })
        )
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    fetchTeamsCallback() // This is not r
  }, [])

  const handleOnChange = (value: any) => {
    setform(true)
    setSelect(value)
  }

  const customhandleOnChange = (team: any, field: any, value: any, index: any) => {
    console.log('passsword...', value)
    let replaceStar = value.replace(/[*]/g, '')

    const passwdArray = [...password]
    if (field === 'Password') {
      password[index].actualValue = password[index].actualValue.concat(replaceStar)
      password[index].patternValue = replaceStar === '' ? password[index].patternValue.slice(0, -1) : password[index].patternValue.concat('*')
    } else {
      password[index].confirmActualValue = password[index].confirmActualValue.concat(replaceStar)
      password[index].confirmPatternValue = replaceStar === '' ? password[index].confirmPatternValue.slice(0, -1) : password[index].confirmPatternValue.concat('*')
    }

    const newTeams = [...teams]
    team[field] = field === 'ConfirmPasswor' ? password[index].confirmActualValue.concat(replaceStar) : password[index].actualValue.concat(replaceStar)

    newTeams[index] = team
    setTeams(newTeams)
  }
  const EditHandle = (value: any) => {
    console.log(value)
    setEditData(value)
    const Team = {
      label: value.teamName,
      value: value.teamName,
      id: value.teamid,
    }

    setSelect(Team)
    setform(true)
    const data = teams.map((i) => {
      if (i.EmailAddress !== value.teamemail) return i
    })
    console.log(data)
  }

  return (
    <>
      {console.log('Inv', Inv)}
      {console.log('allTeams.....', allTeams)}

      <div style={{background: 'black'}}>
        {/* <div className={classes.root}> */
        /* ^^ ~sahil redundant imo*/}
        <div>
          <HeaderMinimal isMobile={isMobile} form={form} setform={setform} />
          <div
            className='container'
            style={{
              // paddingLeft: isMobileOnly ? '7px' : '0px',//~ankur
              // display: 'flex',//~ankur
              // flexDirection: 'row',//~ankur

              // ~sahil, purged above^^.
              justifyContent: isMobileOnly ? '' : 'center',
              marginTop: isMobileOnly ? '' : '7%',
            }}>
            <div>
              {isMobileOnly ? (
                <div
                  className='label'
                  style={{
                    fontFamily: 'Atten New',
                    fontSize: isSE ? '19px' : '20px',
                    fontWeight: 700,
                    color: '#E7E1C5',
                    textAlign: 'initial',
                    // paddingTop: 30, // We just want to have no padding top, coz header has padding what it requires, that suffcient.
                    // marginLeft: 15,
                    marginBottom: '2vh',
                  }}>
                  Season Ticket Holder Credentials
                </div>
              ) : isTablet ? (
                <div
                  className='label'
                  style={{
                    color: '#E7E1C5',
                    fontSize: '40px',
                    fontFamily: 'Atten New',
                    fontWeight: 500,
                    paddingTop: 50,
                    textAlign: 'initial',
                  }}>
                  Season Ticket Holder Credentials
                </div>
              ) : (
                <div
                  className='label'
                  style={{
                    color: '#E7E1C5',
                    fontSize: '20px',
                    fontFamily: 'Atten New',
                    fontWeight: 500,
                    paddingTop: 29,
                    textAlign: 'initial',
                    marginLeft: isMobileOnly ? '' : isTablet ? '' : form === false ? '11%' : '13%',
                    marginRight: isMobileOnly ? '6px' : isTablet ? '' : '100px',
                  }}>
                  Season Ticket Holder Credentials
                  {form === true ? (
                    <div
                      style={{
                        fontStyle: 'italic',
                      }}>
                      Please provide your season ticket holder credentials
                    </div>
                  ) : (
                    <div className=''>Team</div>
                  )}
                </div>
              )}

              {showProgressBar ? (
                <CircularProgress
                  style={{
                    position: 'absolute',
                    top: '43vh',
                    left: '43vw',
                  }}
                />
              ) : (
                ''
              )}

              {form === false ? (
                CredentialData?.length > 0 || CredentialData === null ? (
                  // Added the `|| CredentialData === null` so now if list is null, we are not showing circular progressbar. Yo!~sahil.
                  CredentialData.map((item: any, i: any) => {
                    const padding = '.7rem 0'

                    return (
                      <Row
                        className="team-credentials-items"
                        style={{
                          padding: '10px',
                          flexWrap: 'nowrap',
                          // ^^~sahil, by default bootstrap give flexWrap a `wrap` value which causes side affects in our ui.
                          // border: '2px solid green',
                          // justifyContent : "center"
                          background: 'rgba(231, 225, 197, 0.1)',
                          marginBottom: '4px',
                        }}>
                        <div className='col-11' style={{}}>
                          <Row
                            style={{
                              // border: '2px solid blue',padding
                              // backgroundColor: 'rgba(231, 225, 197, 0.1)',
                              // ~sahil, added ^^ this to above markup element.
                              color: '#E7E1C5',
                              // width: isMobileOnly ? '130%' : '',
                              height: isMobileOnly ? '100%' : '90%',
                            }}>
                            <div
                              className={isMobileOnly ? 'col-4' : 'col-3'}
                              style={
                                {
                                  // border: '2px solid blue',
                                  // paddingLeft: 10,
                                }
                              }>
                              <div
                                className='col-12'
                                style={{
                                  marginTop: isMobileOnly ? '' : '8%',
                                  textAlign: 'start',
                                  color: '#E7E1C5',
                                  // border: '4px solid blue',
                                  padding: '0 7px',
                                }}>
                                {/* ~sahil. */}
                                <div
                                  style={
                                    {
                                      // padding: padding,
                                    }
                                  }>
                                  Team
                                </div>
                                <div
                                  style={{
                                    padding: padding,
                                  }}>
                                  Email
                                </div>
                                <div
                                  style={
                                    {
                                      // padding: padding,
                                    }
                                  }>
                                  Password
                                </div>
                              </div>
                            </div>
                            <div
                              className={isMobileOnly ? 'col-7' : 'col-4'}
                              style={{
                                padding: 0,
                              }}>
                              <div
                                className='col-12'
                                style={{
                                  padding: 0,
                                  // ~sahil, by default bootstrap sets a padding of 15 pixel.
                                  textAlign: 'start',
                                  color: '#FFFFFF',
                                  // border: '2px solid red', //~sahil, debug
                                }}>
                                <div>{item.teamName}</div>
                                <div
                                  style={{
                                    padding: padding,
                                  }}>
                                  {item.teamemail}
                                </div>
                                {/* <div>{item.psw.toString().replaceAll('.', '*')}</div> */}
                                <div
                                  style={{
                                    marginTop: '4px',
                                  }}>
                                  ***********
                                </div>
                                {/*This is hidden password, ~sahil.*/}
                              </div>
                            </div>
                          </Row>
                        </div>
                        <div
                          className='col-1'
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // border: '2px solid blue', //~sahil
                            // background: 'orange', //~sahil debug
                            justifyContent: 'center',
                            alignItems: 'center',
                            // border: '2px solid blue', //~sahil, debug
                          }}>
                          <img
                            src={EditPencil}
                            style={{
                              // border: '2px solid red', // ~sahil thisisit
                              width: '24px',
                              height: '24px',
                            }}
                            alt='i am alt'
                            onClick={() => EditHandle(item)}
                          />
                          <img
                            src={Delete}
                            alt='i am alt'
                            style={{
                              // border: '2px solid green', // ~sahil thisisit
                              width: '24px',
                              height: '24px',
                              marginTop: '16px',
                            }}
                            onClick={() => {
                              // EditHandle(item); //~backup
                              // 🧸︎🧸︎🧸︎🧸︎🧸︎🧸︎
                              handleDelete(item)
                            }}
                          />
                        </div>
                      </Row>
                    )
                  })
                ) : (
                  <CircularProgress
                    style={{
                      position: 'absolute',
                      top: '43vh',
                      left: '43vw',
                    }}
                  />
                )
              ) : (
                <Formik
                  initialValues={{
                    TeamName: EditData.teamName,
                    Password: EditData.psw,
                    ConfirmPassword: '',
                    EmailAddress: EditData.teamemail,
                  }}
                  validationSchema={SignUpSchema}
                  onSubmit={(data: any) => {
                    if (!select.value) {
                      // ^^ falsy value.
                      setErrorTeamName('Required')
                      return
                    }

                    console.log(data)

                    let prodUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAMCREDENTIAL}`
                    let localAndDevurl = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev${API_END_POINT.TEAMCREDENTIAL}`

                    let url = ENV === 'prod' ? prodUrl : localAndDevurl

                    setSubmitClicked(false)
                    console.log(select)
                    let id = user['https://tiqassist.com/user_id']
                    const SubmitData = {
                      clientId: user['https://tiqassist.com/user_id'],
                      teamId: select.id,
                      teamEmail: data.EmailAddress,
                      password: data.Password,
                    }

                    setShowProgressBar(true) // mmmmmmmmmmmmm
                    // debugger

                    axios
                      .post(url, SubmitData)
                      .then(function (response: any) {
                        if (response.status === 200) {
                          console.log('new team added', response.status)
                          setShowProgressBar(false) // mmmmmmmmmmmmm
                          setform(false)
                          setSubmitClicked(false)
                        }
                      })
                      .catch(function (error: any) {
                        setShowProgressBar(false) // mmmmmmmmmmmmm

                        console.log(error)
                      })

                    //🔽︎ ~sahil, fetchig teamCredentialsData again so that we can show teams with newly added team...
                    fetchAndSetTeamCredentialsInState()
                    //🔼︎ ~sahil, fetchig teamCredentialsData again so that we can show teams with newly added team...
                  }}>
                  {({values, handleChange, handleSubmit, handleBlur, errors, touched}) => (
                    <Form
                      onSubmit={handleSubmit}
                      autoComplete={'off'}
                      style={{
                        marginLeft: isMobileOnly ? '' : isTablet ? '' : '100px',
                        marginRight: isMobileOnly ? '6px' : isTablet ? '' : '100px',
                        marginTop: isMobileOnly ? '20px' : '2rem',
                      }}>
                      {console.log(values)}

                      <Row>
                        <Col style={{}}>
                          <Form.Group
                            controlId='TeamName'
                            style={{
                              marginBottom: isMobileOnly ? '' : '1.5rem',
                            }}>
                            <Form.Label
                              style={{
                                // border: '2px solid blue', //sahil, debug
                                color: '#E7E1C5',
                                float: 'left',
                                fontWeight: 400,
                                fontSize: isMobileOnly ? '12.88px' : isTablet ? '19px' : '16px',
                                fontFamily: 'Atten New',
                                // paddingLeft: isMobileOnly ? '2%' : '',
                              }}>
                              TeamName
                              <span
                                style={{
                                  // border: '2px solid red', //sahil, debug
                                  color: 'red',
                                }}>
                                *
                              </span>
                            </Form.Label>
                            <Select
                              name='TeamName'
                              placeholder='search team ...'
                              value={select}
                              options={allTeams}
                              onBlur={() => [setErrorTeamName('')]} ////iiiiiiiii
                              onChange={handleOnChange}
                              className='customSelect'
                              style={{
                                // border: '2px solid deeppink', //sahil, debug
                                width: '100%',
                                height: '48px',
                                backgroundColor: '#C57A1E',
                                // backgroundColor: 'deeppink',
                              }}
                            />

                            {/* {errors.TeamName && touched.TeamName ? ( */}
                            {!select.value ? (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '12px',
                                  float: 'left',
                                }}>
                                {errorTeamName}
                              </div>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group
                            controlId='EmailAddress'
                            style={{
                              marginBottom: isMobileOnly ? '' : '1.5rem',
                            }}>
                            <Form.Label
                              style={{
                                color: '#E7E1C5',
                                float: 'left',
                                fontWeight: 400,
                                fontSize: isMobileOnly ? '12.88px' : isTablet ? '19px' : '16px',
                                fontFamily: 'Atten New',
                                // paddingLeft: isMobileOnly ? '2%' : '',
                              }}>
                              Email address associated with your season tickets
                              <span
                                style={{
                                  color: 'red',
                                }}>
                                *
                              </span>
                            </Form.Label>
                            <div
                              style={
                                {
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  //~sahil ^^ Giving padding to container is better that this.
                                }
                              }>
                              <Form.Control
                                type='text'
                                placeholder='Enter your Email'
                                value={values.EmailAddress}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  height: isMobileOnly ? '48px' : '60px',
                                  width: isMobileOnly ? '100%' : '',
                                  backgroundColor: '#151515',
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  color: '#FAFAFA',
                                  border: '1px solid rgba(255, 255, 255, 0.14)',
                                  // border: '1px solid deeppink',//~sahil, debug.
                                }}
                              />
                              {errors.EmailAddress && touched.EmailAddress ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    float: 'left',
                                  }}>
                                  {errors.EmailAddress}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group
                            controlId='Password'
                            style={{
                              marginTop: isMobileOnly && isTablet ? '2%' : '',
                              marginBottom: '1rem',
                            }}>
                            <Form.Label
                              style={{
                                color: '#E7E1C5',
                                float: 'left',
                                fontWeight: 400,
                                fontSize: isMobileOnly ? '12.88px' : isTablet ? '19px' : '16px',
                                fontFamily: 'Atten New',
                                // paddingLeft: isMobileOnly ? '2%' : '',
                              }}>
                              Password associated with your season tickets{' '}
                              <span
                                style={{
                                  color: 'red',
                                }}>
                                *
                              </span>
                            </Form.Label>
                            <div
                              style={
                                {
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  //~sahil ^^ Giving padding to container is better that this.
                                }
                              }>
                              <Form.Control
                                type='password'
                                placeholder='Enter Password'
                                value={values.Password.replaceAll('.', '*')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  height: isMobileOnly ? '48px' : '60px',
                                  width: isMobileOnly ? '100%' : '',
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  backgroundColor: '#151515',
                                  color: '#FAFAFA',
                                  border: '1px solid rgba(255, 255, 255, 0.14)',
                                }}
                              />
                              {errors.Password && touched.Password ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    float: 'left',
                                  }}>
                                  {errors.Password}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>

                      <Row>
                        {isMobileOnly ? '' : ''}
                        <Col style={{paddingLeft: isMobile ? '' : '7rem'}}>
                          <Form.Label
                            style={{
                              color: '#E7E1C5',
                              float: 'left',
                              fontWeight: 400,
                              fontSize: isMobileOnly ? '12px' : isTablet ? '19px' : '16px',
                              // paddingLeft: isMobileOnly ? '2%' : '',
                              fontFamily: 'Atten New',
                              marginLeft: isMobileOnly ? '' : '-22%',
                              marginBottom: isTablet ? '20px' : '10px',
                            }}>
                            Confirm Password{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Group controlId='ConfirmPassword'>
                            <div>
                              <Form.Control
                                type='password'
                                placeholder='Enter Confirm Password'
                                value={values.ConfirmPassword.replaceAll('.', '*')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  height: isMobileOnly ? '48px' : '60px',
                                  width: isMobileOnly ? '100%' : '',
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  backgroundColor: '#151515',
                                  color: '#FAFAFA',
                                  border: '1px solid rgba(255, 255, 255, 0.14)',
                                }}
                              />
                              {errors.ConfirmPassword && touched.ConfirmPassword ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    float: 'left',
                                  }}>
                                  {errors.ConfirmPassword}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col>
                          <Form.Group
                            controlId='Password'
                            style={{
                              marginTop: isMobileOnly && isTablet ? '2%' : '',
                              marginBottom: '1.5rem',
                            }}>
                            <Form.Label
                              style={{
                                color: '#E7E1C5',
                                float: 'left',
                                fontWeight: 400,
                                fontSize: isMobileOnly ? '12.88px' : isTablet ? '19px' : '16px',
                                fontFamily: 'Atten New',
                                // paddingLeft: isMobileOnly ? '2%' : '',
                              }}>
                              Password associated with your season tickets{' '}
                              <span
                                style={{
                                  color: 'red',
                                }}>
                                *
                              </span>
                            </Form.Label>
                            <div
                              style={
                                {
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  //~sahil ^^ Giving padding to container is better that this.
                                }
                              }>
                              <Form.Control
                                type='password'
                                placeholder='Enter Password'
                                value={values.Password.replaceAll('.', '*')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  height: isMobileOnly ? '48px' : '60px',
                                  width: isMobileOnly ? '100%' : '',
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  backgroundColor: '#151515',
                                  color: '#FAFAFA',
                                  border: '1px solid rgba(255, 255, 255, 0.14)',
                                }}
                              />
                              {errors.Password && touched.Password ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    float: 'left',
                                  }}>
                                  {errors.Password}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row> */}

                      {/* <Row>
                        {isMobileOnly ? '' : ''}
                        <Col style={{paddingLeft: isMobile ? '' : '7rem'}}>
                          <Form.Label
                            style={{
                              color: '#E7E1C5',
                              float: 'left',
                              fontWeight: 400,
                              fontSize: isMobileOnly ? '12px' : isTablet ? '19px' : '16px',
                              // paddingLeft: isMobileOnly ? '2%' : '',
                              fontFamily: 'Atten New',
                              marginLeft: isMobileOnly ? '' : '-22%',
                              marginBottom: isTablet ? '20px' : '10px',
                            }}>
                            Confirm Password{' '}
                            <span
                              style={{
                                color: 'red',
                              }}>
                              *
                            </span>
                          </Form.Label>

                          <Form.Group controlId='ConfirmPassword'>
                            <div>
                              <Form.Control
                                type='password'
                                placeholder='Enter Confirm Password'
                                value={values.ConfirmPassword.replaceAll('.', '*')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                style={{
                                  height: isMobileOnly ? '48px' : '60px',
                                  width: isMobileOnly ? '100%' : '',
                                  // marginLeft: isMobileOnly ? '10px' : '',
                                  backgroundColor: '#151515',
                                  color: '#FAFAFA',
                                  border: '1px solid rgba(255, 255, 255, 0.14)',
                                }}
                              />
                              {errors.ConfirmPassword && touched.ConfirmPassword ? (
                                <div
                                  style={{
                                    color: 'red',
                                    fontSize: '12px',
                                    float: 'left',
                                  }}>
                                  {errors.ConfirmPassword}
                                </div>
                              ) : null}
                            </div>
                          </Form.Group>
                        </Col>
                      </Row> */}
                      {isMobileOnly ? (
                        <Col
                          style={{
                            marginTop: '10%',
                            marginBottom: '3%',
                            marginLeft: '-2px',
                            width: '100%',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                          }}>
                          <Col
                            style={{
                              padding: isMobileOnly ? '' : '0px',
                              display: 'flex',
                              flexDirection: 'row-reverse',
                            }}>
                            <Button
                              variant='contained'
                              style={{
                                width: '100%',
                                height: 50,
                                borderRadius: 50,
                                // border: '2px solid blue', //~sahil debug

                                marginBottom: '15px',
                                backgroundColor: '#C57A1E',
                                marginLeft: '10%',
                              }}
                              onClick={() => handleSubmit()}>
                              <div style={{color: '#FCFCFC'}}>Save</div>
                            </Button>
                            <Button
                              variant='contained'
                              style={{
                                width: '100%',
                                height: 50,
                                borderRadius: 50,

                                backgroundColor: 'grey',
                              }}
                              onClick={() => setform(false)}>
                              <div style={{color: '#FCFCFC'}}>Cancel</div>
                            </Button>
                          </Col>
                          {/* <Col style={{ marginTop: "15px" }}>
                            <Button
                              variant="contained"
                              style={{
                                width: "100%",
                                height: 50,
                                // paddingRight: "45px",
                                borderRadius: 50,
                                backgroundColor: "#BC936C",
                              }}
                              disabled={props.activeStep === 0}
                              onClick={(e) => { props.handleBack(e) }}
                            >
                              <div style={{ color: "#FAFAFA", paddingRight: "0px" }}>
                                BACK
                              </div>
                            </Button>
                          </Col> */}
                        </Col>
                      ) : (
                        <Row
                          style={{
                            marginTop: '11%',
                            marginBottom: '11%',
                          }}>
                          <Row
                            style={{
                              marginTop: '11%',
                              marginBottom: '11%',
                              display: 'contents',
                            }}>
                            <Col>
                              <Button
                                variant='contained'
                                style={{
                                  width: isMobile ? 129 : 150,
                                  height: isMobile ? 40 : 50,

                                  borderRadius: 50,
                                  backgroundColor: '#5B5B5B',
                                  float: 'left',
                                }}
                                onClick={() => cancelteam()}>
                                <div style={{color: '#FFFFFF'}}>Cancel</div>
                              </Button>
                            </Col>
                            <Col>
                              <Button
                                variant='contained'
                                type='Submit'
                                style={{
                                  width: isMobile ? 129 : 150,
                                  height: isMobile ? 40 : 50,
                                  borderRadius: 50,
                                  backgroundColor: '#C57A1E',
                                  float: 'right',
                                }}
                                onClick={() => handleSubmit()}>
                                <div style={{color: '#FCFCFC'}}>Save</div>
                              </Button>
                            </Col>
                          </Row>
                        </Row>
                      )}
                    </Form>
                  )}
                </Formik>
              )}
              {isMobileOnly && form === false ? (
                <button
                  className='btn-add-team'
                  // variant='contained'
                  style={{
                    // width: '65%',
                    marginTop: '10px',
                    width: '37%', //~sahil.
                    height: 50,
                    borderRadius: '6px',
                    // position: 'absolute',
                    // marginLeft: '2%',
                    // marginTop: '10%',

                    backgroundColor: 'black',
                    borderColor: '#E7E1C5',

                    border: '1px solid #E7E1C5',
                    // border: '2px solid blue', //~sahil, debug.
                  }}
                  onClick={() => {
                    setform(true)
                  }}>
                  <div style={{color: '#E7E1C5'}}>ADD TEAM</div>
                </button>
              ) : form === false ? (
                <Col>
                  <Button
                    variant='contained'
                    style={{
                      width: '35%',
                      height: 50,
                      borderRadius: '6px',

                      marginTop: '4%',
                      backgroundColor: 'black',
                      borderColor: 'white',
                      marginRight: '45%',
                    }}
                    onClick={() => {
                      setform(true)
                    }}>
                    <div style={{color: 'white'}}>ADD TEAM</div>
                  </Button>
                </Col>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TeamCredentialsBootstrap
