import {
  DashboardData,
  DASHBOARD_DATA,
  TICKET_DATA,
  REFRESH_DATA,
  UpdateDataModel,
  TicketData,
  UpdateData,
  Dashboard,
} from "./types";

export function setDashboardData(data: Dashboard): DashboardData {
  return {
    type: DASHBOARD_DATA,
    payload: data,
  };
}

export function updateTicket(data: any): TicketData {
  return {
    type: TICKET_DATA,
    payload: data,
  };
}

export function refreshData(data: UpdateDataModel): UpdateData {
  return {
    type: REFRESH_DATA,
    payload: data,
  };
}
