import React, { useContext, useEffect, useState, useRef } from "react";
import { Card, Col, ProgressBar, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Vector from "../assets/arrow.svg";
import { isMobile, isTablet, isMobileOnly } from "react-device-detect";
import Search from "./search";
import { Grid } from "@material-ui/core";
import publicIp from "public-ip";
import axios, { AxiosResponse } from "axios";
import { API_END_POINT, CONFIG_DETAILS, CONSTANT } from "../utils/Constant";
import { CircularProgress } from "material-ui";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/index";
import { addSeatSets } from "../store/seatSets/action";
import { Dispatch } from "redux";
import { DataContext, myiconContext } from "../UserContext";

import { makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useHistory } from "react-router";

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
interface Props {
  activeStep: number;
  handleBack(value: React.BaseSyntheticEvent): void;
  handleNext(value: any): void;
}

interface detail {
  id: number;
  player: string;
}

interface Teams {
  City: string;
  League: string;
  State: string;
  franchise: string;
  home_team: string;
  home_team_short: string;
  sky_id: number;
  leagueOrder: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    color: "0085FF",
  },
}));

export default function Teams(props: Props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { trackData, setTrackData } = useContext(DataContext);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    console.log(newValue);

    setValue(newValue);
  };
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets.seatSets;
  });
  let credential = useSelector((state: RootState) => {
    return state.credential;
  });
  const [searchData, setSearchData] = useState<Teams[]>([]);
  const [bool, setbool] = useState<boolean>();
  const [DataArray, setDataArray] = useState<Teams[]>([]);
  const [TeamsData, setTeamsData] = useState<Teams[] | AxiosResponse>([]);
  const [leaguearray, setleaguearray] = useState<string[]>([]);
  const [More, SetMore] = useState(false);
  const [num, setnum] = useState<number>();
  const [region, setregion] = useState<string>("California");
  const [selectedTeams, setselectedTeams] = useState<Teams[]>(
    trackData.teams === undefined ? [] : trackData.teams.selectedTeam
  );
  const [local, setlocal] = useState<boolean>();
  const [isSE, setSE] = useState(window.innerWidth < 321);
  const [array, setarray] = useState<any>(["MLB", "NBA", "NFL", "NHL", "MLS"]);
  const [focused, setFocused] = React.useState(false);
  const [CopyData, setCopyData] = useState<any>([]);
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const searchedText = useRef("");
  const history = useHistory();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const { Icon, setIcon } = useContext(myiconContext);
  const updateMedia = () => {
    setSE(window.innerWidth < 321);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    publicIp
      .v4()
      .then((ip) => {
        axios
          .get(
            `http://api.ipstack.com/${ip}?access_key=${CONFIG_DETAILS.API_KEY}`
          )
          .then((response) => {
            console.log(response);
            setregion(response.data.region_name);
          })
          .catch((err) => {
            console.log(err);
          });

        return;
      })
      .catch(() => {
        return;
      });
  }, [region]);

  React.useEffect(() => {
    async function FetchData() {
      var bodyData = new FormData();
      let requestHeaders: any = { "Content-Type": "multipart/form-data" };
      bodyData.append("name", "123");
      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.TEAM}`,
        headers: requestHeaders,
        data: bodyData,
      });
      console.log(response);
      setTeamsData(response.data.sort());

      if (response.data.length === 0) {
        console.log(response);

        return <CircularProgress />;
      } else {
        let arr = response.data;
        console.log(arr);
        setDataArray(arr);
        setCopyData(arr);
        setSearchData(arr);

        if (DataArray.length === 0) {
          console.log(DataArray);
          return <CircularProgress />;
        } else {
          console.log("data");
          const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder);
          console.log(arr);

          const unique = Array.from(new Set(arr.map((item) => item.League)));
          console.log(unique);
          setleaguearray(["Local Teams", ...unique]);
          setbool(false);
        }
      }
    }
    FetchData();
  }, [CopyData.length == 0]);
  const handleSubmit = async () => {
    setTrackData({
      id: trackData.id !== undefined ? trackData.id : seatsets.length,
      teams: {
        selectedTeam: selectedTeams,
      },
      fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : "",
      gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
      row: trackData.row !== undefined ? trackData.row : "",
      section: trackData.section !== undefined ? trackData.section : "",
      toSeat: trackData.toSeat !== undefined ? trackData.toSeat : "",
      price: trackData.price !== undefined ? trackData.price : "",
    });
    history.push("seats");
    setIcon([
      {
        active: false,
        completed: true,
        error: false,
        icon: 1,
      },
      {
        active: false,
        completed: true,
        error: false,
        icon: 2,
      },
      {
        active: true,
        completed: false,
        error: false,
        icon: 3,
      },
      {
        active: false,
        completed: false,
        error: false,
        icon: 4,
      },
    ]);
    props.handleNext(2);
  };

  const onSearch = async (search: string) => {
    // setbool(false);

    // if (search.length > 0) {
    //   setlocal(true);
    // }
    // if (
    //   DataArray.find((val) =>
    //     val.home_team.toLocaleLowerCase().includes(search)
    //   )
    // ) {
    //   setDataArray(
    //     DataArray.filter((element) =>
    //       element.home_team.toLocaleLowerCase().includes(search)
    //     )
    //   );
    //   const arr = DataArray.sort((a, b) => a.leagueOrder - b.leagueOrder);
    //   const unique = Array.from(new Set(arr.map((item) => item.League)));

    //   setleaguearray(["Local Teams", ...unique]);
    // }

    // if (search.length === 0) {
    //   setbool(true);
    //   setlocal(false);
    //   setDataArray(searchData);
    // } else {
    //   setbool(false);
    // }
    let condition = new RegExp(search.toLocaleLowerCase());
    var result = await Promise.all(
      DataArray.filter(function (el: any) {
        return condition.test(el.home_team.toLocaleLowerCase());
      })
    );
    searchedText.current = search;
    setDataArray(result);
    if (search.length === 0) {
      setDataArray(CopyData);
    }
  };

  return (
    <>
      <div>
        <Search onSearch={onSearch} />
      </div>
      <div className="container" style={{ maxWidth: "100%" }}>
        {leaguearray.slice(0, 1).map((league: string, index) => {
          const arr1 = DataArray.filter((leg) => {
            return leg.State === region;
          });
          return (
            <div
              className={classes.root}
              key={index}
              style={{ backgroundColor: "#010101" }}
            >
              <AppBar
                position="static"
                color="transparent"
                elevation={0}
                style={{
                  borderBottomStyle: "solid",
                  borderBottomColor: "#696969",
                  borderBottomWidth: "1px",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  TabIndicatorProps={{
                    style: {
                      backgroundColor: "#C57A1E",
                      borderBottomStyle: "solid",
                      borderBottomColor: "#c57a1e",
                      borderBottomWidth: "5px",
                    },
                  }}
                  classes={{
                    indicator: classes.indicator,
                  }}
                >
                  {arr1.length > 0
                    ? leaguearray.map((league) => {
                        console.log(leaguearray);

                        return (
                          <Tab
                            label={league}
                            style={{
                              color: "#BC936C",
                              fontSize: isMobileOnly ? "13px" : "18px",
                              fontFamily: "atten New",
                              fontWeight: "bold",
                            }}
                            {...a11yProps}
                          />
                        );
                      })
                    : leaguearray.slice(0).map((league) => {
                        console.log(leaguearray);

                        return (
                          <Tab
                            label={league}
                            style={{
                              color: "#BC936C",
                              fontSize: isMobileOnly ? "13px" : "18px",
                              fontFamily: "atten New",
                              fontWeight: "bold",
                            }}
                            {...a11yProps}
                          />
                        );
                      })}
                </Tabs>
              </AppBar>

              {arr1.length > 0
                ? leaguearray.map((val, i) => {
                    if (val === "Local Teams") {
                      const arr = DataArray.filter(
                        (value) => value.State === region
                      );
                      console.log("{{LOCAL TEAMS}}");
                      return (
                        <TabPanel value={value} index={i} key={i}>
                          <Row style={{ marginTop: "1rem" }}>
                            {arr1.length > 0
                              ? arr
                                  ?.slice(0, arr.length)
                                  .map((leg: any, index: any) => {
                                    console.log(val);

                                    return (
                                      <Grid
                                        container
                                        item
                                        xs={6}
                                        md={2}
                                        sm={3}
                                        key={leg.sky_id}
                                      >
                                        <Col
                                          className="cardMobile"
                                          key={leg.sky_id}
                                          style={{
                                            width : 150,
                                            height : 150,
                                            userSelect: "none",
                                            marginRight: "5px",
                                            marginBottom: "10px",
                                            borderColor:
                                              selectedTeams.findIndex(
                                                (o) => o.sky_id === leg.sky_id
                                              ) !== -1
                                                ? "#BC936B"
                                                : "#2D2A2F",
                                            backgroundColor:
                                              selectedTeams.findIndex(
                                                (o) => o.sky_id === leg.sky_id
                                              ) !== -1
                                                ? "#1D1A1F"
                                                : "#2D2A2F",
                                            paddingTop: "18%",
                                            wordBreak: "break-word",
                                          }}
                                          onClick={() => {
                                            if (
                                              selectedTeams.findIndex(
                                                (o) => o.sky_id === leg.sky_id
                                              ) === -1
                                            ) {
                                              setselectedTeams([leg]);
                                              setTrackData({
                                                id:
                                                  trackData.id !== undefined
                                                    ? trackData.id
                                                    : seatsets.length,
                                                teams: {
                                                  selectedTeam: [leg],
                                                },
                                                fromSeat:
                                                  trackData.fromSeat !==
                                                  undefined
                                                    ? trackData.fromSeat
                                                    : "",
                                                gamesID:
                                                  trackData.gamesID !==
                                                  undefined
                                                    ? trackData.gamesID
                                                    : [],
                                                row:
                                                  trackData.row !== undefined
                                                    ? trackData.row
                                                    : "",
                                                section:
                                                  trackData.section !==
                                                  undefined
                                                    ? trackData.section
                                                    : "",
                                                toSeat:
                                                  trackData.toSeat !== undefined
                                                    ? trackData.toSeat
                                                    : "",
                                                price:
                                                  trackData.price !== undefined
                                                    ? trackData.price
                                                    : "",
                                              });
                                              // props.handleNext(2);
                                              // history.push("seats");

                                              history.push("seats");
                                              setIcon([
                                                {
                                                  active: false,
                                                  completed: true,
                                                  error: false,
                                                  icon: 1,
                                                },
                                                {
                                                  active: false,
                                                  completed: true,
                                                  error: false,
                                                  icon: 2,
                                                },
                                                {
                                                  active: true,
                                                  completed: false,
                                                  error: false,
                                                  icon: 3,
                                                },
                                                {
                                                  active: false,
                                                  completed: false,
                                                  error: false,
                                                  icon: 4,
                                                },
                                              ]);
                                              console.log(Icon);
                                              props.handleNext(2);
                                            } else {
                                              let arr = selectedTeams.filter(
                                                (o) => o.sky_id !== leg.sky_id
                                              );
                                              setselectedTeams(arr);
                                            }
                                          }}
                                        >
                                          <div
                                            className="card_name"
                                            style={{
                                              fontSize: "24px",
                                              color:
                                                selectedTeams.findIndex(
                                                  (o) => o.sky_id === leg.sky_id
                                                ) !== -1
                                                  ? "#BC936C"
                                                  : "#FAFAFA",
                                              fontWeight: 600,
                                              fontFamily: "Atten New !important"
                                            }}
                                          >
                                            {arr.length === 0 ? (
                                              <div>No Local Teams</div>
                                            ) : (
                                              leg.home_team
                                            )}
                                          </div>
                                        </Col>
                                      </Grid>
                                    );
                                  })
                              : null}
                          </Row>
                          {isTablet ? (
                            <Row>
                              {" "}
                              <Col
                                style={{
                                  height: "2rem",
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {More ? "Show Less" : "Show More"} &nbsp;
                                  <img
                                    src={Vector}
                                    onClick={() => {
                                      SetMore(!More);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </TabPanel>
                      );
                    } else {
                      const arr = DataArray.filter(
                        (value) => value.League === val
                      );
                      return (
                        <TabPanel value={value} index={i}>
                          <Row style={{ marginTop: "1rem" }}>
                            {arr
                              ?.slice(0, arr.length)
                              .map((leg: any, index: any) => {
                                console.log(val);

                                return (
                                  <Grid container item xs={6} md={2} sm={3}>
                                    <Col
                                      className="cardMobile"
                                      key={leg.sky_id}
                                      style={{
                                        width : 150,
                                        height : 150,
                                        border: "none",
                                        userSelect: "none",
                                        marginRight: "5px",
                                        marginBottom: "10px",
                                        paddingTop: "18%",
                                        wordBreak: "break-word",
                                        borderColor:
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) !== -1
                                            ? "#BC936B"
                                            : "#2D2A2F",
                                        backgroundColor:
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) !== -1
                                            ? "#1D1A1F"
                                            : "#2D2A2F",
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) === -1
                                        ) {
                                          setselectedTeams([leg]);
                                          setTrackData({
                                            id:
                                              trackData.id !== undefined
                                                ? trackData.id
                                                : seatsets.length,
                                            teams: {
                                              selectedTeam: [leg],
                                            },
                                            fromSeat:
                                              trackData.fromSeat !== undefined
                                                ? trackData.fromSeat
                                                : "",
                                            gamesID:
                                              trackData.gamesID !== undefined
                                                ? trackData.gamesID
                                                : [],
                                            row:
                                              trackData.row !== undefined
                                                ? trackData.row
                                                : "",
                                            section:
                                              trackData.section !== undefined
                                                ? trackData.section
                                                : "",
                                            toSeat:
                                              trackData.toSeat !== undefined
                                                ? trackData.toSeat
                                                : "",
                                            price:
                                              trackData.price !== undefined
                                                ? trackData.price
                                                : "",
                                          });
                                          // props.handleNext(2);
                                          // history.push("seats");

                                          history.push("seats");
                                          setIcon([
                                            {
                                              active: false,
                                              completed: true,
                                              error: false,
                                              icon: 1,
                                            },
                                            {
                                              active: false,
                                              completed: true,
                                              error: false,
                                              icon: 2,
                                            },
                                            {
                                              active: true,
                                              completed: false,
                                              error: false,
                                              icon: 3,
                                            },
                                            {
                                              active: false,
                                              completed: false,
                                              error: false,
                                              icon: 4,
                                            },
                                          ]);
                                          console.log(Icon);
                                          props.handleNext(2);
                                        } else {
                                          let arr = selectedTeams.filter(
                                            (o) => o.sky_id !== leg.sky_id
                                          );
                                          setselectedTeams(arr);
                                        }
                                      }}
                                    >
                                      <div
                                        className="card_name"
                                        style={{
                                          color: "#FAFAFA",
                                          fontSize: "24px",
                                          fontWeight: 600,
                                          fontFamily: "Atten New !important"
                                        }}
                                      >
                                        {leg.home_team}
                                      </div>
                                    </Col>
                                  </Grid>
                                );
                              })}
                          </Row>
                          {isTablet ? (
                            <Row>
                              {" "}
                              <Col
                                style={{
                                  height: "2rem",
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {More ? "Show Less" : "Show More"} &nbsp;
                                  <img
                                    src={Vector}
                                    onClick={() => {
                                      SetMore(!More);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </TabPanel>
                      );
                    }
                  })
                : leaguearray.slice(0).map((val, i) => {
                    if (val === "Local Teams") {
                      const arr = DataArray.filter(
                        (value) => value.State === region
                      );
                      return (
                        <TabPanel value={value} index={i} key={Math.random()}>
                          <Row style={{ marginTop: "1rem" }}>
                            {arr1.length > 0
                              ? arr
                                  ?.slice(0, arr.length)
                                  .map((leg: any, index: any) => {
                                    return (
                                      <Grid
                                        container
                                        item
                                        xs={6}
                                        md={2}
                                        sm={3}
                                        key={index}
                                      >
                                        <Col
                                          className="cardMobile"
                                          style={{
                                            width : 150,
                                            height : 150,
                                            userSelect: "none",
                                            marginRight: "5px",
                                            marginBottom: "10px",
                                            paddingTop: "18%",
                                            wordBreak: "break-word",
                                          }}
                                          onClick={() => {
                                            if (
                                              selectedTeams.findIndex(
                                                (o) => o.sky_id === leg.sky_id
                                              ) === -1
                                            ) {
                                              setselectedTeams([leg]);
                                              setTrackData({
                                                id:
                                                  trackData.id !== undefined
                                                    ? trackData.id
                                                    : seatsets.length,
                                                teams: {
                                                  selectedTeam: [leg],
                                                },
                                                fromSeat:
                                                  trackData.fromSeat !==
                                                  undefined
                                                    ? trackData.fromSeat
                                                    : "",
                                                gamesID:
                                                  trackData.gamesID !==
                                                  undefined
                                                    ? trackData.gamesID
                                                    : [],
                                                row:
                                                  trackData.row !== undefined
                                                    ? trackData.row
                                                    : "",
                                                section:
                                                  trackData.section !==
                                                  undefined
                                                    ? trackData.section
                                                    : "",
                                                toSeat:
                                                  trackData.toSeat !== undefined
                                                    ? trackData.toSeat
                                                    : "",
                                                price:
                                                  trackData.price !== undefined
                                                    ? trackData.price
                                                    : "",
                                              });
                                              // props.handleNext(2);
                                              // history.push("seats");

                                              history.push("seats");
                                              setIcon([
                                                {
                                                  active: false,
                                                  completed: true,
                                                  error: false,
                                                  icon: 1,
                                                },
                                                {
                                                  active: false,
                                                  completed: true,
                                                  error: false,
                                                  icon: 2,
                                                },
                                                {
                                                  active: true,
                                                  completed: false,
                                                  error: false,
                                                  icon: 3,
                                                },
                                                {
                                                  active: false,
                                                  completed: false,
                                                  error: false,
                                                  icon: 4,
                                                },
                                              ]);
                                              console.log(Icon);
                                              props.handleNext(2);
                                            } else {
                                              let arr = selectedTeams.filter(
                                                (o) => o.sky_id !== leg.sky_id
                                              );
                                              setselectedTeams(arr);
                                            }
                                          }}
                                        >
                                          <div
                                            className="card_name"
                                            style={{
                                              fontSize: "24px",
                                              fontFamily: "Atten New !important"
                                            }}
                                          >
                                            {arr.length === 0 ? (
                                              <div>No Local Teams</div>
                                            ) : (
                                              leg.home_team
                                            )}
                                          </div>
                                        </Col>
                                      </Grid>
                                    );
                                  })
                              : null}
                          </Row>
                          {isTablet ? (
                            <Row>
                              {" "}
                              <Col
                                style={{
                                  height: "2rem",
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {More ? "Show Less" : "Show More"} &nbsp;
                                  <img
                                    src={Vector}
                                    onClick={() => {
                                      SetMore(!More);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </TabPanel>
                      );
                    } else {
                      const arr = DataArray.filter(
                        (value) => value.League === val
                      );
                      return (
                        <TabPanel value={value} index={i} key={Math.random()}>
                          <Row style={{ marginTop: "1rem" }}>
                            {arr
                              ?.slice(0, arr.length)
                              .map((leg: any, index: any) => {
                                console.log(val);

                                return (
                                  <Grid
                                    container
                                    item
                                    xs={6}
                                    md={3}
                                    sm={3}
                                    style={{
                                      paddingBottom: "10px",
                                      paddingRight: "10px",
                                    }}
                                  >
                                    <Col
                                      className="cardMobile"
                                      key={leg.sky_id}
                                      style={{
                                        width : 150,
                                        userSelect: "none",
                                        marginRight: "5px",
                                        marginBottom: "10px",
                                        paddingTop: "60px",
                                        height: "166px",
                                        wordBreak: "break-word",
                                        borderColor:
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) !== -1
                                            ? "#E7E1C5"
                                            : "#2D2B2F",
                                        backgroundColor:
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) !== -1
                                            ? "#31302A"
                                            : "#2D2A2F",
                                      }}
                                      onClick={() => {
                                        if (
                                          selectedTeams.findIndex(
                                            (o) => o.sky_id === leg.sky_id
                                          ) === -1
                                        ) {
                                          setselectedTeams([leg]);
                                          setTrackData({
                                            id:
                                              trackData.id !== undefined
                                                ? trackData.id
                                                : seatsets.length,
                                            teams: {
                                              selectedTeam: [leg],
                                            },
                                            fromSeat:
                                              trackData.fromSeat !== undefined
                                                ? trackData.fromSeat
                                                : "",
                                            gamesID:
                                              trackData.gamesID !== undefined
                                                ? trackData.gamesID
                                                : [],
                                            row:
                                              trackData.row !== undefined
                                                ? trackData.row
                                                : "",
                                            section:
                                              trackData.section !== undefined
                                                ? trackData.section
                                                : "",
                                            toSeat:
                                              trackData.toSeat !== undefined
                                                ? trackData.toSeat
                                                : "",
                                            price:
                                              trackData.price !== undefined
                                                ? trackData.price
                                                : "",
                                          });
                                          // props.handleNext(2);

                                          // history.push("seats");
                                          // props.handleNext(2);

                                          history.push("seats");
                                          setIcon([
                                            {
                                              active: false,
                                              completed: true,
                                              error: false,
                                              icon: 1,
                                            },
                                            {
                                              active: false,
                                              completed: true,
                                              error: false,
                                              icon: 2,
                                            },
                                            {
                                              active: true,
                                              completed: false,
                                              error: false,
                                              icon: 3,
                                            },
                                            {
                                              active: false,
                                              completed: false,
                                              error: false,
                                              icon: 4,
                                            },
                                          ]);
                                          console.log(Icon);
                                          props.handleNext(2);
                                        } else {
                                          let arr = selectedTeams.filter(
                                            (o) => o.sky_id !== leg.sky_id
                                          );
                                          setselectedTeams(arr);
                                        }
                                      }}
                                    >
                                      <div
                                        className="card_name"
                                        style={{
                                          fontFamily: "Atten New !important",
                                          border:
                                            selectedTeams.findIndex(
                                              (o) => o.sky_id === leg.sky_id
                                            ) !== -1
                                              ? "#BC936C"
                                              : "#FAFAFA",
                                          color:
                                            selectedTeams.findIndex(
                                              (o) => o.sky_id === leg.sky_id
                                            ) !== -1
                                              ? "#E7E1C5"
                                              : "#FAFAFA",
                                          fontSize: "24px",
                                        }}
                                      >
                                        {leg.home_team}
                                      </div>
                                    </Col>
                                  </Grid>
                                );
                              })}
                          </Row>
                          {isTablet ? (
                            <Row>
                              {" "}
                              <Col
                                style={{
                                  color: "#BC936C",
                                  height: "2rem",
                                  textAlign: "right",
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <div>
                                  {More ? "Show Less" : "Show More"} &nbsp;
                                  <img
                                    src={Vector}
                                    onClick={() => {
                                      SetMore(!More);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                        </TabPanel>
                      );
                    }
                  })}
            </div>
          );
        })}

        <Row
          style={{
            justifyContent: "space-between",
            marginTop: "5%",
            paddingBottom: "5%",
            marginLeft: "121px",
            marginRight: "131px",
          }}
        >
          <Col>
            <Button
              variant="contained"
              style={{
                width: 150,
                height: "50px",
                borderRadius: 50,
                backgroundColor: "#5B5B5B",
                float: "left",
              }}
              onClick={(e) => {
                props.handleBack(e);
              }}
            >
              <div style={{ color: "#FCFCFC" }}>BACK</div>
            </Button>
          </Col>
          <Col style={{ paddingRight: "0px" }}>
            <Button
              variant="contained"
              disabled={selectedTeams.length === 0 ? true : false}
              style={{
                width: 150,
                height: "50px",
                borderRadius: 50,
                backgroundColor:
                  selectedTeams.length === 0 ? "#5B5B5B" : "#C57A1E",
                float: "right",
              }}
              onClick={() => {
                handleSubmit();
              }}
            >
              <div style={{ color: "#FCFCFC" }}>NEXT</div>
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
}

// interface Props {
//   callbackfunction(value: any, name: string): void;
//   parameter: any;
//   activeStep: number;
//   handleBack(value: React.BaseSyntheticEvent): void;
//   handleNext(): void;
//   Team: any;
//   games: any;
//   bil: any;
// }
// interface gameObject {
//   datetime: string;
//   gameId: string;
// }
// interface form {
//   id: number;
//   teams: {
//     selectedTeam: string;
//   };
//   gamesID: gameObject[];
//   section: string;
//   row: string;
//   toSeat: number;
//   fromSeat: number;
//   price: number;
// }

// interface detail {
//   id: number;
//   player: string;
// }

// interface Team {
//   name: string;
//   Details: detail[];
// }

// interface Teams {
//   City: string;
//   League: string;
//   State: string;
//   franchise: string;
//   home_team: string;
//   home_team_short: string;
//   sky_id: number;
// }

// const Teams: React.FC<Props> = (props) => {
//   const [searchData, setSearchData] = useState<Teams[]>([]);
//   const [bool, setbool] = useState<boolean>();
//   const [DataArray, setDataArray] = useState<Teams[]>([]);
//   const [TeamsData, setTeamsData] = useState<Teams[] | AxiosResponse>([]);
//   const [leaguearray, setleaguearray] = useState<string[]>(["Local Teams"]);
//   const [More, SetMore] = useState(false);
//   const { trackData, setTrackData } = useContext(DataContext)
//   const [num, setnum] = useState<number>();
//   const [region, setregion] = useState<string>("California");
//   const [currentIndex, setcurrentIndex] = useState<any>(null);
//   const [selectedTeams, setselectedTeams] = useState<Teams[]>(trackData.teams === undefined ? [] : trackData.teams.selectedTeam);

//   const dispatch: Dispatch<any> = useDispatch();

//   useEffect(() => {
//     publicIp
//       .v4()
//       .then((ip) => {
//         axios
//           .get(
//             `http://api.ipstack.com/${ip}?access_key=${CONFIG_DETAILS.API_KEY}`
//           )
//           .then((response) => {

//             setregion(response.data.region_name);
//           })
//           .catch((err) => {
//             console.log(err);
//           });

//         return;
//       })
//       .catch(() => {
//         return;
//       });
//   }, []);
//   React.useEffect(() => {
//     console.log(trackData)
//     async function FetchData() {
//       var bodyData = new FormData();
//       let requestHeaders: any = { "Content-Type": "multipart/form-data" };
//       bodyData.append("name", "123");
//       const response = await axios({
//         method: "get",
//         url: `${CONFIG_DETAILS.ROOT}${API_END_POINT.TEAM}`,
//         headers: requestHeaders,
//         data: bodyData,
//       });

//       setTeamsData(response.data);

//       if (response.data.length === 0) {
//         return <CircularProgress />;
//       } else {
//         let arr = response.data;

//         setDataArray(arr);
//         setSearchData(arr);

//         if (DataArray.length === 0) {

//           return <CircularProgress />;
//         } else {
//           const unique = Array.from(
//             new Set(DataArray.map((item) => item.League))
//           );

//           setleaguearray(["Local Teams", ...unique]);
//           setbool(false);
//         }
//       }
//     }
//     FetchData();
//   }, [DataArray.length === 0 || bool === true]);

//   let seatsets = useSelector((state: RootState) => {
//     return state.seatsets.seatSets;
//   })

//   const handleSubmit = async () => {

//     setTrackData({
//       id: trackData.id !== undefined ? trackData.id : seatsets.length,
//       teams: {

//         selectedTeam: selectedTeams,

//       },
//       fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : '',
//       gamesID: trackData.gamesID !== undefined ? trackData.gamesID : [],
//       row: trackData.row !== undefined ? trackData.row : '',
//       section: trackData.section !== undefined ? trackData.section : '',
//       toSeat: trackData.toSeat !== undefined ? trackData.toSeat : '',
//       price: trackData.price !== undefined ? trackData.price : '',
//     })
//     props.handleNext();
//   };

//   const onSearch = (search: string) => {
//     setbool(false);
//     if (
//       DataArray.find((val) =>
//         val.home_team.toLocaleLowerCase().includes(search)
//       )
//     ) {
//       setDataArray(
//         DataArray.filter((element) =>
//           element.home_team.toLocaleLowerCase().includes(search)
//         )
//       );
//       const unique = Array.from(new Set(DataArray.map((item) => item.League)));

//       setleaguearray(["Local Teams", ...unique]);
//     }

//     if (search.length === 0) {
//       setbool(true);
//       setDataArray(searchData);
//     }
//   };

//   return (
//     <>
//       <Search onSearch={onSearch} />
//       <div className="container" style={{ padding: "0px" }}>
//         {leaguearray.map((league: string, index) => {
//           if (league !== "Local Teams") {
//             const arr = DataArray.filter((value) => value.League === league);
//             return (
//               <>
//                 <Row style={{ marginTop: "3rem", marginLeft: "0.5rem" }}>
//                   <Col style={{ textAlign: "left" }}>
//                     <div
//                       className="text_Card_Title"
//                       style={{ fontSize: isTablet ? "18px" : "", color: "#BC936C" }}
//                     >
//                       {league}
//                     </div>
//                   </Col>
//                   <Col style={{ height: "2rem", textAlign: "right", color: "#BC936C" }}>
//                     <div>
//                       {index === currentIndex ? "Show Less" : "Show More"}{" "}
//                       &nbsp;
//                       <img
//                         src={Vector}
//                         onClick={() => {
//                           currentIndex === index
//                             ? setcurrentIndex(null)
//                             : setcurrentIndex(index);
//                           SetMore(!More);
//                         }}
//                         style={{ cursor: "pointer" }}
//                       />
//                       <span
//                         onClick={() => {
//                           currentIndex === index
//                             ? setcurrentIndex(null)
//                             : setcurrentIndex(index);
//                           SetMore(!More);
//                         }}
//                         style={{ cursor: "pointer" }}>
//                         {">"}
//                       </span>
//                     </div>
//                   </Col>
//                 </Row>
//                 <Row style={{ marginTop: "1rem" }}>
//                   {arr.slice(0, 6).map((val) => {
//                     return (
//                       <Grid
//                         container
//                         item
//                         xs={12}
//                         sm={6}
//                         md={3}
//                         lg={2}
//                         style={{ marginTop: "1rem" }}
//                       >
//                         <Col
//                           className="card"
//                           style={{
//                             userSelect: "none",
//                             wordBreak: "break-word",
//                             backgroundColor:
//                               selectedTeams.findIndex(
//                                 (o) => o.sky_id === val.sky_id
//                               ) !== -1
//                                 ? "#1D1A1F"
//                                 : "#151515",
//                             marginLeft: "2rem",
//                             borderColor:
//                               selectedTeams.findIndex(
//                                 (o) => o.sky_id === val.sky_id
//                               ) !== -1
//                                 ? "#BC936C"
//                                 : "",
//                           }}
//                           onClick={() => {
//                             if (
//                               selectedTeams.findIndex(
//                                 (o) => o.sky_id === val.sky_id
//                               ) === -1
//                             ) {
//                               // setselectedTeams([...selectedTeams, val]);
//                               setselectedTeams([val]);
//                             } else {
//                               let arr = selectedTeams.filter(
//                                 (o) => o.sky_id !== val.sky_id
//                               );
//                               setselectedTeams(arr);
//                             }
//                           }}
//                         >
//                           <div
//                             className="card_name"
//                             style={{
//                               fontSize: isTablet ? "18px" : "", color:
//                                 selectedTeams.findIndex(
//                                   (o) => o.sky_id === val.sky_id
//                                 ) !== -1
//                                   ? "#BC936C"
//                                   : "white",
//                             }}
//                           >
//                             {val.home_team}
//                           </div>
//                         </Col>
//                       </Grid>
//                     );
//                   })}
//                 </Row>
//                 {currentIndex === index ? (
//                   <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
//                     {(props) => (
//                       <div style={props as React.CSSProperties | undefined}>
//                         <Grid spacing={1}>
//                           <Row>
//                             {arr?.slice(6).map((val) => {
//                               return (
//                                 <Grid
//                                   container
//                                   item
//                                   xs={12}
//                                   sm={6}
//                                   md={3}
//                                   lg={2}
//                                   style={{ marginTop: "1rem" }}
//                                 >
//                                   <Col
//                                     className="card"
//                                     style={{
//                                       userSelect: "none",
//                                       wordBreak: "break-word",
//                                       backgroundColor:
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) !== -1
//                                           ? "#1D1A1F"
//                                           : "#151515",
//                                       marginLeft: "2rem",
//                                       borderColor:
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) !== -1
//                                           ? "#BC936C"
//                                           : "",
//                                     }}
//                                     onClick={() => {
//                                       if (
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) === -1
//                                       ) {
//                                         setselectedTeams([val]);
//                                       } else {
//                                         let arr = selectedTeams.filter(
//                                           (o) => o.sky_id !== val.sky_id
//                                         );
//                                         setselectedTeams(arr);
//                                       }
//                                     }}
//                                   >
//                                     <div className="card_name" style={{
//                                       color: selectedTeams.findIndex(
//                                         (o) => o.sky_id === val.sky_id
//                                       ) !== -1
//                                         ? "#BC936C"
//                                         : "#FAFAFA",
//                                     }}>
//                                       {val.home_team}
//                                     </div>
//                                   </Col>
//                                 </Grid>
//                               );
//                             })}
//                           </Row>
//                         </Grid>
//                       </div>
//                     )}
//                   </Spring>
//                 ) : null}
//               </>
//             );
//           } else {
//             const arr = DataArray.filter((value) => value.State === region);

//             return (
//               <>
//                 {arr.length > 0 ? (
//                   <>
//                     <Row style={{ marginTop: "3rem", marginLeft: "0.5rem" }}>
//                       <Col style={{ textAlign: "left" }}>
//                         <div
//                           className="text_Card_Title"
//                           style={{ fontSize: isTablet ? "18px" : "", color: "#BC936C" }}
//                         >
//                           {league}
//                         </div>
//                       </Col>
//                       <Col style={{ height: "2rem", textAlign: "right", color: "#BC936C" }}>
//                         <div>
//                           {index === currentIndex ? "Show Less" : "Show More"}{" "}
//                           &nbsp;
//                           <span
//                             onClick={() => {
//                               currentIndex === index
//                                 ? setcurrentIndex(null)
//                                 : setcurrentIndex(index);
//                               SetMore(!More);
//                             }}
//                             style={{ cursor: "pointer" }}>
//                             {">"}
//                           </span>
//                         </div>
//                       </Col>
//                     </Row>
//                     <Row style={{ marginTop: "1rem" }}>
//                       {arr.slice(0, 6).map((val) => {
//                         return (
//                           <Grid
//                             container
//                             item
//                             xs={12}
//                             sm={6}
//                             md={3}
//                             lg={2}
//                             style={{ marginTop: "1rem" }}
//                           >
//                             <Col
//                               className="card"
//                               style={{
//                                 userSelect: "none",
//                                 wordBreak: "break-word",

//                                 backgroundColor:
//                                   selectedTeams.findIndex(
//                                     (o) => o.sky_id === val.sky_id
//                                   ) !== -1
//                                     ? "#1D1A1F"
//                                     : "#2D2A2F",
//                                 marginLeft: "2rem",
//                                 borderColor:
//                                   selectedTeams.findIndex(
//                                     (o) => o.sky_id === val.sky_id
//                                   ) !== -1
//                                     ? "#BC936B"
//                                     : "#2D2A2F",
//                               }}
//                               onClick={() => {
//                                 if (
//                                   selectedTeams.findIndex(
//                                     (o) => o.sky_id === val.sky_id
//                                   ) === -1
//                                 ) {
//                                   setselectedTeams([val]);
//                                 } else {
//                                   let arr = selectedTeams.filter(
//                                     (o) => o.sky_id !== val.sky_id
//                                   );
//                                   setselectedTeams(arr);
//                                 }
//                               }}
//                             >
//                               <div
//                                 className="card_name"
//                                 style={{
//                                   fontSize: isTablet ? "18px" : "", color: selectedTeams.findIndex(
//                                     (o) => o.sky_id === val.sky_id
//                                   ) !== -1
//                                     ? "#BC936C"
//                                     : "#FAFAFA",
//                                 }}
//                               >
//                                 {val.home_team}
//                               </div>
//                             </Col>
//                           </Grid>
//                         );
//                       })}
//                     </Row>
//                   </>
//                 ) : null}
//                 {currentIndex === index && arr.length > 0 ? (
//                   <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
//                     {(props) => (
//                       <div style={props as React.CSSProperties | undefined}>
//                         <Grid spacing={1}>
//                           <Row>
//                             {arr?.slice(6).map((val) => {
//                               return (
//                                 <Grid
//                                   container
//                                   item
//                                   xs={12}
//                                   sm={6}
//                                   md={3}
//                                   lg={2}
//                                   style={{ marginTop: "1rem" }}
//                                 >
//                                   <Col
//                                     className="card"
//                                     style={{
//                                       userSelect: "none",
//                                       wordBreak: "break-word",
//                                       backgroundColor:
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) !== -1
//                                           ? "#1D1A1F"
//                                           : "#2D2A2F",
//                                       marginLeft: "2rem",
//                                       borderColor:
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) !== -1
//                                           ? "#BC936B"
//                                           : "#2D2A2F",
//                                     }}
//                                     onClick={() => {
//                                       if (
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) === -1
//                                       ) {
//                                         setselectedTeams([val]);
//                                       } else {
//                                         let arr = selectedTeams.filter(
//                                           (o) => o.sky_id !== val.sky_id
//                                         );
//                                         setselectedTeams(arr);
//                                       }
//                                     }}
//                                   >
//                                     <div className="card_name" style={{
//                                       color:
//                                         selectedTeams.findIndex(
//                                           (o) => o.sky_id === val.sky_id
//                                         ) !== -1
//                                           ? "#BC936B"
//                                           : "#FAFAFA",
//                                     }}>
//                                       {val.home_team}
//                                     </div>
//                                   </Col>
//                                 </Grid>
//                               );
//                             })}
//                           </Row>
//                         </Grid>
//                       </div>
//                     )}
//                   </Spring>
//                 ) : null}
//               </>
//             );
//           }
//         })}

//         <Row
//           style={{
//             justifyContent: "space-between",
//             marginTop: "10%",
//             paddingBottom: "5%",
//             marginLeft: "0px",
//           }}
//         >
//           <Col>
//             <Button
//               variant="contained"
//               style={{
//                 width: 150,
//                 height: "50px",
//                 borderRadius: 50,
//                 backgroundColor: "#5B5B5B",
//                 float: "left",
//               }}
//               onClick={(e) => { props.handleBack(e) }}
//             >
//               <div style={{ color: "#FCFCFC" }}>BACK</div>
//             </Button>
//           </Col>
//           <Col style={{ paddingRight: "0px" }}>
//             <Button
//               variant="contained"
//               style={{
//                 width: 150,
//                 height: "50px",
//                 borderRadius: 50,
//                 backgroundColor: "#C57A1E",
//                 float: "right",
//               }}
//               onClick={() => {
//                 handleSubmit();
//               }}
//             >
//               <div style={{ color: "#FCFCFC" }}>NEXT</div>
//             </Button>
//           </Col>
//         </Row>
//       </div>
//     </>
//   );
// };
// export default Teams;
