import React, { Dispatch, useContext, useEffect, useState } from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  withStyles,
} from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import StepLabel from "@material-ui/core/StepLabel";
import complete from "../assets/Complete.png";
import Incomplete from "../assets/Incomplete.png";
import StepConnector from "@material-ui/core/StepConnector";
import Typography from "@material-ui/core/Typography";
import { StepIconProps } from "@material-ui/core/StepIcon";
import "../assets/signUp.css";
import Start from "../components/Start";
import ChooseBills from "../components/ChooseBills";
import companyLogo from "../assets/tiqAssist.png";
import back from "../images/back.svg";
import Teams from "../components/Teams";
import ConfirmEmail from "../components/ConfirmEmail";
import WhichGame from "../components/WhichGame";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import WhichGameMobile from "../components/WhichGameMobile";
import TeamsMobile from "../components/TeamsMobile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/index";
import { useHistory, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import "../App.css";
import { Row, Col, Container } from "react-bootstrap";
import {
  DataContext,
  focusContext,
  loginContext,
  myiconContext,
  toggleContext,
} from "../UserContext";

interface choosebill {
  Section: string;
  Row: string;
  fromSeat: string;
  toSeat: string;
  price: string;
}
interface login {
  FullName: string;
  email: string;
}
interface arrayTeam {
  LocalTeam: string;
  NBA: string;
  MLB: string;
}
interface whichgame {
  id: number;
  title: string;
  Subtitle: string;
}

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#969696",
    zIndex: 1,
    color: "#E5E5E5",
    width: isMobileOnly ? 20 : isTablet ? 22 : 35,
    height: isMobileOnly ? 20 : isTablet ? 22 : 35,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    borderColor: "#969696",
    borderStyle: "solid",
    borderWidth: 2,
    marginLeft: isMobile ? "13px" : "10px",
  },
  label: {
    fontSize: 35,
    fontWeight: "bold",
  },
  active: {
    backgroundColor: "#eae0c8",
    borderColor: "#BC936C",
    borderStyle: "solid",
    borderWidth: isTablet ? 5 : isMobileOnly ? 9 : 8.5,
  },
  completed: {
    backgroundColor: "#BC936C",
    borderColor: "#BC936C",
  },
});
function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;
  let { par }: any = useParams();
  const { focus, setfocus } = useContext(focusContext);
  const { Icon, setIcon } = useContext(myiconContext);
  const { login, setlogin } = useContext(loginContext);
  console.log("ICON CONTEXT", Icon);
  console.log("login", login);
  const icons: { [index: string]: React.ReactElement } = {
    1:
      active == true ? (
        <img
          style={{
            width: isMobileOnly ? 19 : 0,
            height: isMobileOnly ? 19 : 0,
          }}
          src={complete}
        />
      ) : completed ? (
        <img
          style={{
            width: isMobileOnly ? 0 : 0,
            height: isMobileOnly ? 0 : 0,
          }}
          src={complete}
        />
      ) : (
        <img
          style={{
            width: isMobileOnly ? 19 : 0,
            height: isMobileOnly ? 19 : 0,
          }}
          src={Incomplete}
        />
      ),
    2:
      active == true ? (
        <img
          style={{
            width: isMobileOnly ? 19 : 0,
            height: isMobileOnly ? 19 : 0,
          }}
          src={complete}
        />
      ) : completed ? (
        <img
          style={{
            width: isMobileOnly ? 0 : 0,
            height: isMobileOnly ? 0 : 0,
          }}
          src={complete}
        />
      ) : (
        <img
          style={{
            width: isMobileOnly ? 19 : isTablet ? 0 : 0,
            height: isMobileOnly ? 19 : isTablet ? 0 : 0,
          }}
          src={Incomplete}
        />
      ),
    3:
      active == true ? (
        <img
          style={{
            width: isMobileOnly ? 19 : 0,
            height: isMobileOnly ? 19 : 0,
          }}
          src={complete}
        />
      ) : completed ? (
        <img
          style={{
            width: isMobileOnly ? 0 : 0,
            height: isMobileOnly ? 0 : 0,
          }}
          src={complete}
        />
      ) : (
        <img
          style={{
            width: isMobileOnly ? 19 : isTablet ? 0 : 0,
            height: isMobileOnly ? 19 : isTablet ? 0 : 0,
          }}
          src={Incomplete}
        />
      ),
    4:
      active == true ? (
        <img
          style={{
            width: isMobileOnly ? 19 : 0,
            height: isMobileOnly ? 19 : 0,
          }}
          src={complete}
        />
      ) : completed ? (
        <img
          style={{ width: isMobileOnly ? 0 : 0, height: isMobileOnly ? 0 : 0 }}
          src={complete}
        />
      ) : (
        <img
          style={{
            width: isMobileOnly ? "16px" : 0,
            height: isMobileOnly ? "16px" : 0,
          }}
          src={Incomplete}
        />
      ),
  };
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: isMobileOnly ? 8 : isTablet ? 9 : 17,
    width: isMobileOnly ? "83%" : isTablet ? "98%" : "91%",
  },
  active: {
    "& $line": {
      borderColor: "#E7E1C5",
    },
  },
  completed: {
    "& $line": {
      borderColor: "#969696",
    },
  },
  line: {
    height: isMobileOnly ? "2px" : 3,
    width: isMobile ? "110%" : "108%",
    border: 0,
    backgroundColor: "#E7E1C5",
    borderRadius: 1,
  },
})(StepConnector);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#010101",
      flex: 1,
      width: "100%",
      alignContent: "center",
      justifyContent: "center",
    },
    inner: {
      flex: 1,
    },
    heading: {
      width: "20%",
      paddingTop: "48px",
      paddingBottom: "2rem",
    },
    label: {
      fontSize: 35,
      fontWeight: "bold",
    },
    textHeading: {
      fontSize: 45,
    },
    stepperIndecator: {
      width: isMobile ? "" : "80%",
      marginLeft: isMobile ? "" : "10%",
      alignItems: "center",
      justifyContent: "center",
    },
    button: {
      width: 150,
      height: "100%",
      borderRadius: 50,
      backgroundColor: "#0085FE",
    },
    instructions: {
      marginTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
  })
);
function getSteps() {
  return ["", "", "", ""];
}
export default function CustomizedSteppers() {
  const classes = useStyles();
  const [gameList, setgameList] = useState<any>([]);
  let { par }: any = useParams();
  const [activeStep, setActiveStep] = React.useState(
    par === "start"
      ? 0
      : par === "teams"
      ? 1
      : par === "seats"
      ? 2
      : par === "events"
      ? 3
      : 4
  );
  const [TokenData, setTokenData] = useState<any>();
  const { focus, setfocus } = useContext(focusContext);
  const [isSE, setSE] = useState(window.innerWidth < 321);
  const [id, setid] = useState<any>();
  const [locationKeys, setLocationKeys] = useState<(string | undefined)[]>([]);

  const { trackData, setTrackData } = useContext(DataContext);
  const { heading, setheading } = useContext(toggleContext);
  const [Back, setBack] = useState(false);
  const history = useHistory();
  const { Icon, setIcon } = useContext(myiconContext);
  const [hideData, sethideData] = useState<any>(false);
  console.log("HELLO From CONTEXT", Icon);

  let state2 = useSelector((state: RootState) => {
    return state.credential;
  });
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets;
  });

  console.log("State ", state2, "  ", seatsets);
  const dispatch: Dispatch<any> = useDispatch();
  const updateMedia = () => {
    setSE(window.innerWidth < 321);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const handlerHide = (value: any) => {
    sethideData(value);
  };
  const steps = getSteps();
  const backHandler = (value: any) => {
    setActiveStep(value);
  };
  function GetStepContent(step: number) {
    let { par }: any = useParams();
    console.log(par);
    switch (par) {
      case "start":
        return (
          <div>
            {isMobile ? (
              <div
                className="label"
                style={{
                  textAlign: isMobileOnly ? "start" : "center",
                  marginTop: "2rem",
                  marginBottom: "1rem",
                  marginLeft: "20px",
                  color: "#BC936C",
                  fontSize: "24px",
                  fontFamily: "Atten New",
                  fontWeight: 700,
                }}
              >
                Let's get started
              </div>
            ) : (
              <div
                className="label"
                style={{
                  color: "#BC936C",
                  fontSize: "40px",
                  fontFamily: "Atten New",
                  fontWeight: 700,
                  paddingBottom: 65,
                  paddingTop: 15,
                }}
              >
                Let's get started
              </div>
            )}
            <Start
              handleNext={handleNext}
              handleBack={handleBack}
              activeStep={activeStep}
            />
          </div>
        );
      case "teams":
        return (
          <div>
            {isMobile ? (
              <div
                className="label"
                style={{
                  fontFamily: "Atten New",
                  fontSize: "20px",
                  fontWeight: 700,
                  color: "#FFFFFF",
                }}
              >
                Choose Your Team
              </div>
            ) : (
              <div
                className="label"
                style={{
                  color: "#BC936C",
                  fontSize: "40px",
                  fontFamily: "Atten New",
                  fontWeight: 500,
                  paddingBottom: 30,
                  paddingTop: 15,
                }}
              >
                What's your team?
              </div>
            )}
            {isMobile ? (
              <TeamsMobile
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
                handlerHide={handlerHide}
              />
            ) : (
              <Teams
                activeStep={activeStep}
                handleBack={handleBack}
                handleNext={handleNext}
              />
            )}
          </div>
        );
      case "seats":
        return (
          <div>
            <ChooseBills
              activeStep={activeStep}
              handleBack={handleBack}
              handleNext={handleNext}
              handleReset={handleReset}
              doubleBack={HandleDouble}
            />
          </div>
        );
      case "events":
        return (
          <div>
            {isMobile ? (
              <div
                className="label"
                style={{
                  fontWeight: 700,
                  fontSize: "24px",
                  width: "93%",
                  color: "#BC936C",
                  fontFamily: "Atten New",
                  textAlign: "center",
                  marginLeft: "12px",
                }}
              >
                {/* {heading === false ? (
                     <div>
                     {seatsets.seatSets.length === 1 ? (
                       <div>
                         Which{" "}
                         {seatsets.seatSets[0].teams.selectedTeam[0].home_team}{" "}
                         games do you own for section{" "}
                         {seatsets.seatSets[0].section} Row{" "}
                         {seatsets.seatSets[0].row} Seats{" "}
                         {seatsets.seatSets[0].fromSeat} to
                         {seatsets.seatSets[0].toSeat}
                       </div>
                     ) : seatsets.seatSets.length > 1 ? (
                       <div>
                         Which {trackData.teams.selectedTeam[0].home_team} games
                         do you own ?
                       </div>
                     ) : (
                       <div>
                         Which {trackData.teams.selectedTeam[0].home_team} games
                         do you own ?
                       </div>
                     )}{" "}
                   </div>
                ) : null} */}
              </div>
            ) : (
              <div
                className="label"
                style={{
                  color: "#BC936C",
                  fontSize: "40px",
                  fontFamily: "Atten New",
                  fontWeight: 500,
                }}
              >
                {heading === false ? (
                  <div>
                    {seatsets.seatSets.length === 1 ? (
                      <div>
                        Which{" "}
                        {seatsets.seatSets[0].teams.selectedTeam[0].home_team}{" "}
                        games do you own for section{" "}
                        {seatsets.seatSets[0].section} Row{" "}
                        {seatsets.seatSets[0].row} Seats{" "}
                        {seatsets.seatSets[0].fromSeat} to
                        {seatsets.seatSets[0].toSeat}
                      </div>
                    ) : seatsets.seatSets.length > 1 ? (
                      <div>
                        Which {trackData.teams.selectedTeam[0].home_team} games
                        do you own ?
                      </div>
                    ) : (
                      <div>
                        Which {trackData.teams.selectedTeam[0].home_team} games
                        do you own ?
                      </div>
                    )}{" "}
                  </div>
                ) : null}
              </div>
            )}
            {isMobile ? (
              <WhichGameMobile
                activeStep={activeStep}
                handleNext={handleNext}
                handleBack={handleBack}
                handleReset={handleReset}
                doubleBack={HandleDouble}
                handlerHide={handlerHide}
                backhandler={backHandler}
              />
            ) : (
              <WhichGame
                activeStep={activeStep}
                handleNext={handleNext}
                handleReset={handleReset}
                doubleBack={HandleDouble}
                handleBack={handleBack}
                backhandler={backHandler}
              />
            )}
          </div>
        );

      default:
        return "Unknown step";
    }
  }
  console.log(activeStep);
  const handleNext = (value: any) => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (e?: React.BaseSyntheticEvent) => {
    setBack(true);
    console.log("handling back button press", e);
    if (typeof e !== "undefined") {
      e.preventDefault();
      console.log(e);
    }
    history.goBack();
    return e;
  };

  useEffect(() => {
    window.addEventListener("popstate", function (e) {
      console.log(e.state);
      if (history.action === "POP" && Back !== true) {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      }
    });
  }, []);
  useEffect(() => {
    window.onbeforeunload = function () {
      setActiveStep(0);
      return setLocationKeys([]);
    };

    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleReset = () => {
    setActiveStep(0);
  };

  const HandleDouble = () => {
    setgameList([]);
    setActiveStep(3);
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.heading}
        style={{
          marginLeft: isTablet ? "29px" : isMobile ? "20px" : "",
          paddingTop: isTablet ? "30px" : isMobile ? "30px" : "",
          paddingBottom: isMobileOnly ? "11px" : "",
        }}
      >
        {isMobile && activeStep >= 0 ? (
          <Row>
            <Col className="col-5" style={{ paddingTop: 3 }}>
              {/* <div
                className="circle"
                style={{ width: 32, height: 32, backgroundColor: "#262429" }}
              >
                <img
                  src={back}
                  onClick={() => {
                    handleBack();
                  }}
                  style={{
                    color: "cecece",
                    marginTop: "1px",
                    marginLeft: "0x",
                    width : 20,
                    height : 16
                  }}
                />
              </div> */}
                     <span
          style={{
            height: '24px',
            width: '24px',
            borderRadius: '50%',
            display: 'inline-block',
            backgroundColor: '#1A1A1A',
            // marginTop: 20,
          }}>
          <ArrowBackIcon
            style={{
              marginTop: '-4px' ,
              color:'white',
              width: '18px',
            }}
            onClick={() => {
              handleBack();
            }}
          />
        </span>
              {/* {activeStep == 0 ? null : <div className="circle" style={{ backgroundColor: "#262429" }}>
              <KeyboardBackspaceIcon onClick={() => { handleBack() }} style={{ color: "cecece", marginTop: '8px', marginLeft: "-2px" }} />
            </div>} */}
            </Col>
            <Col className="col-4">
              <img
                src={companyLogo}
                style={{
                  paddingLeft: isMobileOnly ? "" : "12px",
                  width: isTablet ? "190px" : isMobile ? "172px" : "",
                  height: isTablet ? "30px" : isMobile ? "36px" : "",
                  marginTop: isMobileOnly ? "3px" : "",
                }}
              />
            </Col>
          </Row>
        ) : (
          <img
            src={companyLogo}
            style={{
              paddingLeft: isMobileOnly ? "" : "12px",
              width: isTablet ? "190px" : isMobile ? "172px" : "",
              height: isTablet ? "30px" : isMobile ? "36px" : "",
            }}
          />
        )}
      </div>

      <div className={classes.stepperIndecator}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          style={{
            backgroundColor: "#010101",
            justifyContent: isMobile || isTablet ? "center" : "",
            marginLeft: isMobileOnly ? "-26px" : isTablet ? "-60px" : "",
            paddingLeft: isMobileOnly ? "0px" : "",
            paddingRight: isMobileOnly ? "0px" : "",
            // marginRight: isMobileOnly ? "-50px" : isTablet ? "-50px" : "",
          }}
          connector={<ColorlibConnector />}
        >
          {steps.map((label) => (
            <Step key={label} style={{}}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                style={{ marginRight: isMobile ? "-8px" : "" }}
                contentEditable={false}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {par === "confirm-mail" ? (
            <div>
              <Typography
                className={classes.instructions}
                style={{ marginTop: hideData ? "-25px" : "" }}
              >
                <ConfirmEmail tokenData={TokenData} />
                {/* <ThankyouToVerify/> */}
              </Typography>
            </div>
          ) : (
            <div>
              <Typography
                className={classes.instructions}
                style={{ marginTop: hideData ? "-25px" : "" }}
              >
                {GetStepContent(activeStep)}
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
