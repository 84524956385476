import {useState, useEffect, memo, useRef, Dispatch, useContext} from 'react'
import {useSelector} from 'react-redux'
import {withRouter} from 'react-router-dom'
import {RootState} from '../store'
import {refreshData} from '../store/dashboard/action'
import '../components/Home.css'
import Search from './search'
import TotalsView from '../components/TotalsView/TotalsView'
import TicketCard from './TicketCard/TicketCard'
import NBA from '../images/nbas.png'
import MLB from '../images/mlbs.png'
import NFL from '../images/nfls.png'
import $ from 'jquery'
import NHL from '../images/nhls.png'
import MLS from '../images/mlss.png'
import Seat from '../images/Seat.svg'
import fill from '../images/Fill.png'
import fillSmall from '../images/FillSmall.png'
import Header from './Header'
import Dollar from '../images/dollar.svg'
import {isMobile, isMobileOnly} from 'react-device-detect'
import {elementScroll, elementScrollIntoView} from 'seamless-scroll-polyfill'
import {ContextSpinner, focusContext} from '../UserContext'
import {useDispatch} from 'react-redux'
import {useAuth0} from '@auth0/auth0-react'
import {CircularProgress} from 'material-ui'

const getImgSrc = (value: any | null | undefined) => {
  if (value?.league?.includes('MLB')) {
    return MLB
  } else if (value?.league?.includes('NBA')) {
    return NBA
  } else if (value?.league?.includes('NFL')) {
    return NFL
  } else if (value?.league?.includes('NHL')) {
    return NHL
  } else if (value?.league?.includes('MLS')) {
    return MLS
  }
  return MLB
}

const getTotalsData = (data?: number | null, toFix: number = 1, multiplier: number = 1) => {
  if (data === null || typeof data !== 'number') {
    return '-'
  } else {
    let nData = (data * multiplier).toFixed(toFix)
    return nData.toString()
  }
}

let l = console.log

// type props = {cb: (param: any) => void}
// interface HomeProps {
//   cb: (param: any) => void
// }

type HomeProps = any
function Home(props: HomeProps) {
  // const updatedTickets = useRef<any[]>([])

  const dispatch: Dispatch<any> = useDispatch()

  const dashboardData = useSelector((state: RootState) => {
    return state.dashboard
  })

  const [allTickets, setAllTickets] = useState<any[]>([...(dashboardData?.sales || []), ...(dashboardData?.inv || [])])

  const [teamshow, setteamShow] = useState<any[]>([])
  const {focus, setfocus} = useContext(focusContext)
  const [show, setshow] = useState(false)
  const [progress, setprogress] = useState<boolean>(true)
  const [text, settext] = useState('')
  const {spinner, setspinner} = useContext(ContextSpinner)
  const searchedText = useRef('')
  const {isAuthenticated, getAccessTokenSilently, loginWithRedirect} = useAuth0()
  const elSelIndex = useRef<any>(null)
  const [width, setWidth] = useState<number>(window.innerWidth)

  const scrollEventCallback = (e: any) => {
    // document.documentElement.scrollTop = 0 // this will scroll to top.
    l('~sahil scrolleventhandler', document.documentElement.scrollTop) // 304 is search bar makes the document scroll value changes to.
    const scrollPosition = document.documentElement.scrollTop
    if (scrollPosition < 304) {
      // boom
      const el: any = document.querySelector('.mobile-navigation-bar')
      el.style.visibility = 'visible'
    } else if (scrollPosition === 304) {
      const el: any = document.querySelector('.mobile-navigation-bar')
      el.style.visibility = 'hidden'
    }
  }

  // 🔽︎🔽︎🔽︎ added now to avoid setting display block when its desktop.
  const updateMedia = () => {
    // 428
    setWidth(window.innerWidth)
  }
  console.log('~sahil width', {width})
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  useEffect(() => {
    console.log('~sahil home component mounted...')
    const el: any = window.document.querySelector('.mobile-navigation-bar')
    // TODO: Add a mobile check if its mobile then only set display block(so on desktop it won't give sideaffect.)
    if (el && el.style) {
      if (width <= 959) {
        // Starting from width 960, we shown desktop navigation bar, yikes!!
        // I only want to set display block if its mobile, not on desktop.
        el.style.display = 'block'
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', scrollEventCallback)
    return () => window.removeEventListener('scroll', scrollEventCallback)
  })

  useEffect(() => {
    let array: any[] = []
    switch (window.location.pathname) {
      case '/':
      case '/home':
        array = dashboardData.allTickets || []
        break
      case '/listed':
        array = dashboardData.listedTickets || []
        break
      case '/notlisted':
        array = dashboardData.notListedTickets || []
        break
      case '/sold':
        array = dashboardData.soldTickets || []
        break
      default:
        break
    }
    setAllTickets(array)
    setteamShow(array)
    if (searchedText.current.length > 0) {
      onSearch(searchedText.current)
    }
    // if (isMobile) {
    // to stop search field move to downwards while searching
    // let e = Array.from(document.getElementsByClassName('Home') as HTMLCollectionOf<HTMLElement>)[0]
    // For alternate fix search for '#dejavu' in this file as that fixes it without sideeffect.
    // e.style.height = '1000px' // ~sahil, original. TO SEE IF COMMENTING THIS CAN ACTUALLY SOLVE THIS ISSUE.
    // e.style.height = '100vh' // ~sahil, original. TO SEE IF COMMENTING THIS CAN ACTUALLY SOLVE THIS ISSUE.
    // }
  }, [dashboardData, window.location.pathname])

  const onSearch = async (search: string) => {
    let condition = new RegExp(search.toLocaleLowerCase())
    var result = await Promise.all(
      allTickets.filter(function (el: any) {
        return condition.test(el.home_team.toLocaleLowerCase()) || condition.test(el.league.toLocaleLowerCase()) || condition.test(el.away_team.toLocaleLowerCase())
      })
    )
    searchedText.current = search
    setteamShow(result)
    if (search.length === 0) {
      setteamShow(allTickets)
    }
  }
  const handleModalClose = (status: string) => {
    settext(status == 'NOT_LISTED' ? 'DE-LISTED' : status == 'LISTED' ? 'LISTED' : '')
    setTimeout(() => setshow(false), 2000)
    setshow(true)
  }
  useEffect(() => {
    if (isAuthenticated) {
      setprogress(false)
    }

    ;(async () => {
      try {
        const token = await getAccessTokenSilently()
      } catch (e) {
        if (e.error === 'login_required') {
          loginWithRedirect()
        }
        if (e.error === 'consent_required') {
          loginWithRedirect()
        }
        throw e
      }
    })()
  }, [progress])

  return (
    <>
      {/* <Header
        tabChanged={() => {
          if (updatedTickets.current.length > 0) {
            dispatch(refreshData({list: updatedTickets.current}))
            updatedTickets.current = []
          }
        }}
      /> */}
      {spinner ? (
        <CircularProgress />
      ) : (
        <div className='Home position-relative flex-center flex-cloumn align-items-start'>
          <div className='home-content flex-center flex-column'>
            <TotalsView
              seatsSold={getTotalsData(dashboardData.totals?.percent_sold, 1, 100) + '%'}
              commission={'$' + getTotalsData(dashboardData.totals?.total_sales, 2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              payment={'$' + getTotalsData(dashboardData.totals?.total_payments, 2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            />

            <div className={`ticket-search-div flex-center justify-content-${isMobile ? 'center' : 'between'}`} style={{width: isMobile ? '100%' : '95%'}}>
              {isMobile ? null : <div className='title-div'>Tickets</div>}
              <div
                className='search-div'
                style={
                  {
                    // border: '2px solid green',
                    // ~sahil, debug.
                  }
                }>
                <Search
                  onFocus={(e) => {
                    if (isMobile) {
                      let ele = document.getElementsByClassName('search-div')[0]
                      elementScrollIntoView(ele, {
                        behavior: 'smooth',
                        block: 'start',
                        inline: 'center',
                      })

                      // 🏉︎🏉︎🏉︎🏉︎🏉︎
                      const el: any = document.querySelector('.mobile-navigation-bar')
                      el.style.visibility = 'hidden'
                    }
                  }}
                  onBlur={(e) => {
                    const el: any = document.querySelector('.mobile-navigation-bar')
                    el.style.visibility = 'visible'
                  }}
                  onSearch={onSearch}
                />
              </div>
            </div>
            <div
              className='row position-relative w-100 justify-content-start'
              style={{
                minHeight: '100vh',
                // ^^~sahil, #dejavu.
              }}>
              {teamshow.map((value: any, index: number) => (
                <div
                  className='col-lg-3 col-md-4 col-sm-6 offset-xs-2 p-0'
                  id={index.toString()}
                  onClick={(e) => {
                    elSelIndex.current = index.toString()
                  }}>
                  <TicketCard
                    key={value.id}
                    imageSrc={getImgSrc(value) || ''}
                    data={value}
                    seatImg={Seat}
                    dollarImg={Dollar}
                    buttonTapped={(object) => {}}
                    handleModalClose={handleModalClose}
                    ticketUpdated={(obj) => props.cb(obj)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {show ? (
        <div className='home-list-delist-popup position-fixed w-100 h-100 flex-center'>
          <img
            src={isMobile ? fillSmall : fill}
            alt='fill_image'
            style={{
              width: isMobile ? '22px' : '45px',
              height: isMobile ? '16px' : '40px',
            }}
          />
          <div
            style={{
              marginLeft: isMobile ? '10px' : '20px',
              fontSize: isMobile ? '30px' : '60px',
            }}>
            {text.toLowerCase().includes('de') ? 'De-Listed' : 'Listed'}
          </div>
        </div>
      ) : null}
    </>
  )
}

export default withRouter(memo(Home))
