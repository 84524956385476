import React, {useState, useEffect, Dispatch, useContext, useRef} from 'react'
import Button from '@material-ui/core/Button'
import confirmemail from '../assets/confirmemail.png'
import confirmemailMobile from '../assets/ce.png'
import OtpInput from 'react-otp-input'
import {isMobile, isMobileOnly} from 'react-device-detect'
import Arrow from '../assets/arrow.png'
import axios from 'axios'
import '../App.css'

import swal from 'sweetalert'
import ThankyouToVerify from './ThankyouToVerify'
import {AuthContext, DataContext} from '../UserContext'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {useAuth0} from '@auth0/auth0-react'
import {ENV} from '../utils/environmentConfig'
import {API_END_POINT} from '../utils/Constant'

interface Props {
  tokenData: any
}

const ConfirmEmail: React.FC<Props> = (props) => {
  const {trackData, setTrackData} = useContext(DataContext)
  const {isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims} = useAuth0<any>()
  const [UserMetadata, setUserMetadata] = useState<any>()
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets
  })
  let credential = useSelector((state: RootState) => {
    return state.credential
  })
  const [toggle, settoggle] = useState<boolean>(false)

  const {Auth, setAuth} = useContext(AuthContext)
  const [otp, setotp] = useState<any>('')
  const handleChange = (otp: React.FormEvent<HTMLInputElement>) => {
    setotp(otp)
  }
  const [seconds, setSeconds]: any = useState(300)
  const [isIphone8, setIphone8] = useState(window.innerHeight < 668 && window.innerHeight > 666)
  const [isIphoneX, setIphoneX] = useState(window.innerHeight < 813 && window.innerHeight > 811)

  function fancyTimeFormat(duration: any) {
    // Hours, minutes and seconds
    var hrs = ~~(duration / 3600)
    var mins = ~~((duration % 3600) / 60)
    var secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    var ret = ''

    if (hrs > 0) {
      ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
    }

    ret += '' + mins + ':' + (secs < 10 ? '0' : '')
    ret += '' + secs
    return ret
  }

  async function HandleResend() {
    let seatSets = seatsets.seatSets.map((item) => {
      return {
        section: item.section,
        row: item.row,
        lowSeat: parseInt(item.fromSeat),
        quantity: parseInt(item.toSeat),
        faceValue: parseInt(item.price),
        gamesId: item.gamesID,
        teamId: item.teams.selectedTeam[0].sky_id,
      }
    })

    let data = {
      name: credential.name,
      email: credential.email,
      seatSets: seatSets,
    }

    const prodUrl = `${process.env.REACT_APP_AWS_LAMBDA_SEND_VERIFICATION_CODE_URL}${API_END_POINT.SENDCODE}`
    const devUrl = 'https://ogirtyqy4a.execute-api.us-east-1.amazonaws.com/default/sendVerificationCode'

    const url = ENV === 'prod' ? prodUrl : devUrl

    axios({
      url: url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: data,
    })
      .then((res) => {
        console.log('resresres ', res)
        if (res.status === 200) {
          localStorage.setItem('provisionalClientId', res.data.provisionalClientId)
          // swal({
          //   title: "Success!",
          //   text: res.data.message,
          //   icon: "success",

          // });
        }
      })
      .catch((err) => {
        console.log(err)
      })
    // console.log(data);
  }

  // useEffect(() => {
  //   const { isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims } =
  //   useAuth0<any>();
  //   const getUserMetadata = async () => {
  //     const domain = "dev-ncpskpfy.auth0.com";

  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         audience: `https://${domain}/api/v2/`,
  //         scope: "read:current_user",
  //       });

  //       const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

  //       const metadataResponse = await fetch(userDetailsByIdUrl, {
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //         },
  //       });

  //       const { user_metadata } = await metadataResponse.json();

  //       setUserMetadata(user_metadata);
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };

  //   getUserMetadata();
  // }, []);

  const handleSubmit = () => {
    let seatSets = seatsets.seatSets.map((item) => {
      return {
        section: item.section,
        row: item.row,
        lowSeat: item.fromSeat,
        quantity: item.toSeat,
        faceValue: item.price,
        gamesId: item.gamesID.map((v) => {
          return v.gameId
        }),
        teamId: item.teams.selectedTeam[0].sky_id,
      }
    })

    let data = {
      provisionalClientId: localStorage.getItem('provisionalClientId'),
      email: credential.email,
      data: seatSets,
    }
    let verifyData = {
      otp: `${otp}`,
      provisionalClientId: localStorage.getItem('provisionalClientId'),
      email: credential.email,
    }
    console.log(JSON.stringify(data, null, 2))

    const prodUrl = `${process.env.REACT_APP_AWS_LAMBDA_CHECK_VERIFICATION_CODE_URL}${API_END_POINT.CHECKCODE}`
    const devUrl = 'https://yzhx3h3uxa.execute-api.us-east-1.amazonaws.com/default/checkVerificationCode'

    const url = ENV === 'prod' ? prodUrl : devUrl

    axios({
      url: url,
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      data: verifyData,
    })
      .then(function (response) {
        // swal({
        //   title: "Success!",
        //   text: response.data.message,
        //   icon: "success",
        // });
        localStorage.setItem('Token', JSON.stringify(response.data))
        var qs = require('qs')

        var data = qs.stringify({
          grant_type: 'client_credentials',
          client_id: `${process.env.REACT_APP_OAUTH_CLIENT_ID}`,
          client_secret: `${process.env.REACT_APP_OAUTH_CLIENT_SECRET}`,
          audience: `https://${process.env.REACT_APP_OAUTH_DOMAIN}/api/v2/`,
        })

        axios({
          method: 'post',
          url: `https://${process.env.REACT_APP_OAUTH_DOMAIN}/oauth/token`,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            Cookie:
              '__cfduid=dab8f8370d6add58756a372049ff205631617860212; did=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM; did_compat=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM',
          },
          data: data,
        })
          .then(function (response) {
            console.log(response.data.access_token)
            var data = JSON.stringify({
              connection: 'email',
              email: credential.email,
              email_verified: true,
              app_metadata: {
                tiq_user_id: localStorage.getItem('provisionalClientId'),
              },
            })
            axios({
              method: 'post',
              url: `https://${process.env.REACT_APP_OAUTH_DOMAIN}/api/v2/users`,
              headers: {
                Authorization: `Bearer ${response.data.access_token}`,
                'Content-Type': 'application/json',
                Cookie:
                  '__cfduid=dab8f8370d6add58756a372049ff205631617860212; did=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM; did_compat=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM',
              },
              data: data,
            }).then((response) => {
              console.log(response)
              //  debugger
              settoggle(true)
            })
          })
          .catch(function (error) {
            console.log(error)
          })
      })
      .catch(function (error: any) {
        console.log(error)
      })
  }

  //   const CreateUserAuth0 = async () => {
  //     try {
  //       const accessToken = await getAccessTokenSilently({
  //         audience: `https://dev-ncpskpfy.auth0.com/api/v2/`,

  //       });
  //       var data = JSON.stringify({
  //         connection: "email",
  //         email: credential.email,
  //         email_verified: true,
  //         app_metadata: {
  //           tiq_user_id: localStorage.getItem("provisionalClientId"),
  //         },
  //       });
  // console.log(accessToken)
  //       const Response = await axios({
  //         method: "post",
  //         url: "https://dev-ncpskpfy.auth0.com/api/v2/users",
  //         headers: {
  //           Authorization: `Bearer ${accessToken}`,
  //           "Content-Type": "application/json",
  //           Cookie:
  //             "__cfduid=dab8f8370d6add58756a372049ff205631617860212; did=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM; did_compat=s%3Av0%3A6c2243e0-982c-11eb-87a6-8973a038c1a5.XKCor%2Bwbl6aK%2FTZGE3aUM4z9cm243xlCOXZlDKjvGhM",
  //         },
  //         data: data,
  //       });
  //       alert(JSON.stringify(Response))
  //       settoggle(true);
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };

  const [isSE, setSE] = useState(window.innerWidth < 321)

  const updateMedia = () => {
    setSE(window.innerWidth < 321)
    setIphone8(window.innerHeight < 668 && window.innerHeight > 666)
    setIphoneX(window.innerHeight < 813 && window.innerHeight > 811)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })
  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      setSeconds('')
    }
  })
  return (
    <div
      className='container'
      style={{
        paddingTop: isMobileOnly ? '' : '60px',
        padding: isMobileOnly ? 0 : '',
        height: isMobile ? '' : '31.5rem',
      }}>
      {toggle === true ? (
        <ThankyouToVerify token={props.tokenData} />
      ) : (
        <>
          {isMobileOnly ? (
            <div style={{marginBottom: isMobileOnly ? '' : '2rem'}}>
              <img
                style={{
                  width: isSE ? '319px' : '375px',
                  height: '268px',
                  top: '255px',
                  left: '648px',
                }}
                src={confirmemailMobile}
              />
            </div>
          ) : (
            <div
              style={{
                marginBottom: '1rem',
                zIndex: -1,
                marginTop: isMobile ? '' : '-30px',
              }}>
              <img
                style={{
                  width: '750px',
                  height: '400px',
                  top: '255px',
                  left: '648px',
                }}
                src={confirmemailMobile}
              />
            </div>
          )}
          <div
            style={{
              position: 'relative',
              bottom: isMobileOnly ? '22px' : '170px',
            }}>
            {isMobileOnly ? (
              <div
                style={{
                  color: '#E7E1C5',
                  fontFamily: 'Atten New',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontSize: isMobileOnly ? '24px' : '40px',
                  lineHeight: '30px',
                  paddingTop: isMobileOnly ? '' : '15px',
                  paddingBottom: isMobileOnly ? '' : '5px',
                  zIndex: 1,
                }}>
                Confirm your email
              </div>
            ) : (
              <div
                style={{
                  color: '#E7E1C5',
                  fontFamily: 'Atten New',
                  fontWeight: 700,
                  fontStyle: 'normal',
                  fontSize: isMobileOnly ? '24px' : '40px',
                  lineHeight: '30px',
                  paddingTop: isMobileOnly ? '' : '15px',
                  paddingBottom: isMobileOnly ? '' : '',
                  zIndex: 1,
                }}>
                Confirm your email
              </div>
            )}
            <div
              style={{
                color: '#FFFFFF',
                fontFamily: 'Atten New',
                fontWeight: isMobileOnly ? 400 : 500,
                fontStyle: 'normal',
                fontSize: isMobileOnly ? '14px' : '18px',
                lineHeight: '35px',
                alignItems: 'center',
              }}>
              Please enter the code we just sent to your email
            </div>
            <div
              style={{
                marginTop: '1.5rem',
                marginBottom: '0.5rem',
                display: 'inline-block',
              }}>
              <OtpInput
                value={otp}
                onChange={handleChange}
                numInputs={6}
                isInputNum={true}
                inputStyle={{
                  color: '#FAFAFA',
                  width: isMobileOnly ? '41px' : '50px',
                  height: isMobileOnly ? '41px' : '50px',
                  borderColor: '#DFE0E6',
                  borderRadius: '8px',
                  borderLeftWidth: '1px',
                  borderTopWidth: '1px',
                  // borderLeftColor: "#DFE0E6",
                  // borderTopColor: "#DFE0E6",
                  backgroundColor: '#171718',
                  fontSize: '16px',
                  borderStyle: 'inherit',
                }}
                separator={<span>&nbsp;&nbsp;</span>}
              />
            </div>
            <div>
              <Button
                style={{
                  color: '#E7E1C5',
                  borderRadius: 50,
                  backgroundColor: '#1A1A1A',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  borderColor: '#1A1A1A',
                  fontSize: isMobile ? '4vw' : '12px',
                  fontFamily: 'Atten New',
                  marginTop: isMobileOnly ? '3%' : '1%',
                }}>
                {seconds > 0 ? <div>Code Expires in{fancyTimeFormat(seconds)} seconds</div> : <div>Code Expired</div>}
              </Button>
            </div>
            <div
              style={{
                color: isMobileOnly ? '#9E9E9E' : '#FFFFFF',
                marginTop: isMobileOnly ? '3%' : '1%',
                marginBottom: isMobile ? '4%' : '3.5%',
                fontFamily: 'Atten New',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: '16px',
                lineHeight: '20px',
                alignItems: 'center',
                height: '20px',
              }}>
              Didn't get the code yet?{' '}
              {isMobileOnly ? (
                <a
                  style={{
                    fontWeight: 500,
                    fontFamily: 'Atten New',
                    width: isMobileOnly ? '100%' : '',
                    color: '#BC936C',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  href='#'
                  onClick={() => {
                    if (seconds > 0) {
                      return false
                    } else {
                      HandleResend()
                    }
                  }}
                  attributes-list>
                  Resend&nbsp;
                </a>
              ) : (
                <a
                  style={{
                    fontWeight: 500,
                    fontFamily: 'Roboto',
                    width: isMobileOnly ? '100%' : '',
                    color: '#BC936C',
                    fontSize: '16px',
                    lineHeight: '20px',
                  }}
                  href='#'
                  onClick={() => {
                    if (seconds > 0) {
                      console.log('Currently disable')
                    } else {
                      HandleResend()
                    }
                  }}
                  attributes-list>
                  Resend&nbsp;
                </a>
              )}
            </div>
            <div
              style={{
                marginTop: isIphoneX ? '10%' : isIphone8 ? '0%' : '',
                // marginBottom: isMobile ? "10%" : "",
                marginLeft: isMobileOnly ? '25px' : '',
                marginRight: isMobileOnly ? '25px' : '',
              }}>
              {isMobileOnly ? (
                <Button
                  onClick={() => {
                    handleSubmit()
                  }}
                  style={{
                    width: isMobileOnly ? '100%' : '200px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    height: '50px',
                    borderRadius: '50px',
                    fontWeight: 700,
                    backgroundColor: '#c57a1e',
                    color: '#FAFAFA',
                  }}>
                  Continue
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    handleSubmit()
                  }}
                  style={{
                    width: isMobileOnly ? '100%' : '200px',
                    fontFamily: 'Roboto',
                    fontStyle: 'normal',
                    height: '50px',
                    borderRadius: '50px',
                    fontWeight: 700,
                    backgroundColor: '#C57A1E',
                    color: '#FAFAFA',
                  }}>
                  Verify
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
export default ConfirmEmail
