export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const TICKET_DATA = "TICKET_DATA";
export const REFRESH_DATA = "REFRESH_DATA";

interface Client {
  first_name?: string;
  last_name?: string;
}
interface Totals {
  percent_sold?: number;
  total_sales?: number;
  total_payments?: number;
}

export interface Dashboard {
  client?: Client;
  inv?: any[];
  sales?: any[];
  payments?: any[];
  totals?: Totals;

  allTickets?: any[];
  listedTickets?: any[];
  notListedTickets?: any[];
  soldTickets?: any[];
}

export interface UpdateDataModel {
  list?: any[];
}

export interface DashboardData {
  type: typeof DASHBOARD_DATA;
  payload: Dashboard;
}
export interface TicketData {
  type: typeof TICKET_DATA;
  payload: any;
}
export interface UpdateData {
  type: typeof REFRESH_DATA;
  payload: UpdateDataModel;
}
