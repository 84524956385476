/////////////

export const STORE_SEATSETS = "STORE_SEATSETS";
export const REMOVE_SEATSETS = "REMOVE_SEATSETS";
export const UPDATE_SEATSETS = "UPDATE_SEATSETS";

interface gameObject {
  datetime: string;
  gameId: string;
  description: string
}

export interface form {
  id:number;
  teams: {
    selectedTeam: any[];
  };
  gamesID: gameObject[];
  section: string;
  row: string;
  toSeat: string;
  fromSeat: string;
  price: string;
}

export interface SeatSetsState {
  seatSets: form[];
}

interface SeatSetsAction {
  type: typeof REMOVE_SEATSETS;
  meta: number
}

interface RemoveSeatSetsAction {
  type: typeof STORE_SEATSETS;
  payload: form;
}
interface UpdateSeatSetsAction {
  type: typeof UPDATE_SEATSETS;
  payload: form;
  id:number
}

export type SeatSetsActionTypes = SeatSetsAction | RemoveSeatSetsAction | UpdateSeatSetsAction;
///////////////
