import React, { Dispatch, useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
  Redirect,
} from "react-router-dom";
import ChooseBills from "./components/ChooseBills";
import ConfirmEmail from "./components/ConfirmEmail";
import Start from "./components/Start";
import Teams from "./components/Teams";
import ThankyouToVerify from "./components/ThankyouToVerify";
import VerifyResaleTicket from "./components/VerifyResaleTicket";
import WhichGame from "./components/WhichGame";
import CustomizedSteppers from "./screens/Stepper";
import { hideData, UserContext } from "./UserContext";
import SignInSide from "./components/Login";
import Home from "./components/Home";
import Listed from "./components/Listed";
import Notlisted from "./components/Notlisted";
import Sold from "./components/Sold";

import SignIn from "./components/SignIn";
import StepperRoute from "./StepperRoutes";

import ThankyouToVerifyLogin from "./components/thankyoulogin";
import ConfirmEmailLogin from "./components/confirmemaillogin";
import { ProtectRoute } from "./components/ProtectedRoutes";
import RouteDashboard from "./components/RouteDashboard";
import { Dashboard } from "@material-ui/icons";
import PaymentScreen from "./components/PaymentScreen";
import TeamCredentials from "./components/ViewAccount";
import ViewAccount from "./components/ViewAccount";
import { useDispatch } from "react-redux";
import { setDashboardData } from "./store/dashboard/action";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import SwipeableTextMobileStepper from "./screens/OnboardStepper";
interface RouteProps {
  component?:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  render?: (props: RouteComponentProps<any>) => React.ReactNode;
  path?: string | string[];
  exact?: boolean;
  sensitive?: boolean;
  strict?: boolean;
}
//   export class Route<T extends RouteProps = RouteProps> extends React.Component<T, any> { }

const Routes = () => {
  const { toggle, settoggle } = useContext(UserContext);
  const { Hide, setHideData } = useContext(hideData);
  const { user } = useAuth0<any>();
  const { isAuthenticated } = useAuth0<any>();
  // const dispatch: Dispatch<any> = useDispatch();

  // useEffect(() => {
  //   let id = user["https://tiqassist.com/user_id"];
  //   axios
  //     .get(
  //       `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/dashboard/${id}`
  //     )
  //     .then((res) => {
  //       let allTickets: any[] = [];
  //       let listedTickets: any[] = [];
  //       let notListedTickets: any[] = [];
  //       let soldTickets: any[] = [];

  //       allTickets = [...(res.data?.sales || []), ...(res.data?.inv || [])];

  //       allTickets.forEach((el: any) => {
  //         switch (el.status.toLowerCase().trim()) {
  //           case "listed":
  //             listedTickets.push(el);
  //             break;
  //           case "not_listed":
  //             notListedTickets.push(el);
  //             break;
  //           case "sold":
  //             soldTickets.push(el);
  //             break;
  //           default:
  //             break;
  //         }
  //       });
  //       let data = {
  //         ...res.data,
  //         allTickets,
  //         listedTickets,
  //         notListedTickets,
  //         soldTickets,
  //       };
  //       dispatch(setDashboardData(data));
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // });

  return (
    <Router>
      {/* {Hide === false ? <SignInSide/> :
        localStorage.getItem('Token') != null ? <Navigation /> : <CustomizedSteppers />
      }
      <Navigation  */}
      <Switch>
        <Route exact path="/" component={SignInSide} />
        {isAuthenticated ? <RouteDashboard /> : <StepperRoute />}
        {/* <SwipeableTextMobileStepper/> */}
      </Switch>
    </Router>
  );
};

export default Routes;
