import {
  DASHBOARD_DATA,
  TICKET_DATA,
  REFRESH_DATA,
  UpdateData,
  Dashboard,
  DashboardData,
  TicketData,
} from "./types";

const { v4: uuid } = require("uuid");

let initialState: Dashboard = {};

export function dashboardReducer(
  state = initialState,
  action: DashboardData | TicketData | UpdateData
): Dashboard {
  switch (action.type) {
    case DASHBOARD_DATA:
      return { ...setDashboardData(action.payload) };
    case TICKET_DATA:
      let allArr = state.allTickets || [];
      let objIndex = allArr.findIndex((e) => e.id === action.payload.id);
      if (objIndex !== -1) {
        allArr[objIndex] = { ...action.payload };
      }
      return { ...state };
    case REFRESH_DATA:
      let newState = { ...state };
      action.payload.list?.forEach((el) => {
        let invIndex = newState.inv?.findIndex((o) => o.id === el.id) || -1;
        let salesIndex = newState.sales?.findIndex((o) => o.id === el.id) || -1;
        if (invIndex !== -1 && newState.inv) {
          newState.inv[invIndex] = el;
        } else if (salesIndex !== -1 && newState.sales) {
          newState.sales[invIndex] = el;
        }
      });
      return { ...setDashboardData(newState) };
    default:
      return state;
  }
}

const setDashboardData = (response: any) => {
  let allTickets: any[] = [];
  let listedTickets: any[] = [];
  let notListedTickets: any[] = [];
  let soldTickets: any[] = [];
  let payments: any[] = [];
  let totals: any = {};
  let ResInv = response?.inv;
  let ResSales = response?.sales;
  payments = response?.payments;
  totals = response?.totals;
  ResInv = ResInv.sort(
    (a: any, b: any) =>
      new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
  );
  ResInv = ResInv.filter(function (el: any) {
    el["id"] = uuid().toString();
    if (el.status === "SOLD") {
      soldTickets.push(el);
    }
    return new Date(el.event_date).getTime() > new Date().getTime();
  });
  ResSales = ResSales.sort(
    (a: any, b: any) =>
      new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
  );
  ResSales = ResSales.filter(function (el: any) {
    el["id"] = uuid().toString();
    if (el.status === "SOLD") {
      soldTickets.push(el);
    }
    return new Date(el.event_date).getTime() > new Date().getTime();
  });
  soldTickets = soldTickets.sort(
    (a: any, b: any) =>
      new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
  );
  allTickets = [...(ResSales || []), ...(ResInv || [])];
  allTickets = allTickets.sort(
    (a: any, b: any) =>
      new Date(a.event_date).getTime() - new Date(b.event_date).getTime()
  );
  allTickets.forEach((el: any) => {
    switch (el.status.toLowerCase().trim()) {
      case "listed":
        listedTickets.push(el);
        break;
      case "not_listed":
        notListedTickets.push(el);
        break;
      default:
        break;
    }
  });
  return {
    ...response,
    allTickets,
    listedTickets,
    notListedTickets,
    soldTickets,
    payments,
    totals,
  };
};
