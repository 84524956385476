import React, {useContext, useEffect, Dispatch, useRef} from 'react'
import {Switch, Route, BrowserRouter as Router, RouteComponentProps, RouteChildrenProps} from 'react-router-dom'
import Home from './Home'
import Listed from './Listed'
import Notlisted from './Notlisted'
import Sold from './Sold'
import Header from './Header'
import PaymentScreen from './PaymentScreen'
import TeamCredentials from './TeamCredentials'
import ViewAccount from './ViewAccount'
import AccountDetails from './AccountDetails'
import Hidden from '@material-ui/core/Hidden'
import {focusContext, HideHeaderContext, ReloadContext, updatedTicketsRefContext} from '../UserContext'
import {Console} from 'node:console'
import {isMobileOnly} from 'react-device-detect'
import SignInSide from './Login'
import {useAuth0} from '@auth0/auth0-react'
import {CircularProgress} from 'material-ui'
import axios from 'axios'
import {useDispatch, useSelector} from 'react-redux'
import {setDashboardData} from '../store/dashboard/action'
import {RootState} from '../store'
import {refreshData} from '../store/dashboard/action' //~sahil, added on 27th may.
import {ENV} from '../utils/environmentConfig'
import {API_END_POINT} from '../utils/Constant'
export interface RouteProps {
  component?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>
  render?: (props: RouteComponentProps<any>) => React.ReactNode
  children?: ((props: RouteChildrenProps<any>) => React.ReactNode) | React.ReactNode
  path?: string | string[]
  exact?: boolean
  sensitive?: boolean
  strict?: boolean
}

const RouteDashboard = () => {
  const {HideHeader, setHideHeader} = useContext(HideHeaderContext)
  const {focus, setfocus} = useContext(focusContext)
  const {isAuthenticated, user, getAccessTokenSilently, getIdTokenClaims} = useAuth0<any>()
  const Loading = useAuth0().isLoading

  const dispatch: Dispatch<any> = useDispatch()
  const dashboardData = useSelector((state: RootState) => {
    return state.dashboard
  })

  const {Reload, setReload} = useContext(ReloadContext)
  // const updatedTickets = useContext(updatedTicketsRefContext) //~sahil, added here, 27the may.

  const updatedTickets = useRef<any[]>([])

  useEffect(() => {
    ;(async () => {
      let id = user['https://tiqassist.com/user_id']
      // debugger
      try {
        const localAndDevUrl = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/dashboard/${id}`
        const prodUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.DASHBOARD}/${id}`

        const url = ENV === 'prod' ? prodUrl : localAndDevUrl

        const token = await getIdTokenClaims()
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.__raw}`,
          },
        })
        dispatch(setDashboardData(response.data))
        setReload(false)
      } catch (e) {
        console.error(e)
      }
    })()
  }, [])

  // debugger
  const cb = (newObj: any) => {
    // debugger
    let arr: any[] = updatedTickets.current
    let index = arr.findIndex((o: any) => o.id === newObj.id)
    if (index > -1) {
      arr.splice(index, 1)
    } else {
      arr.push(newObj)
    }
    updatedTickets.current = arr
  }

  return (
    <div>
      <Router>
        <Switch>
          {Loading ? (
            <CircularProgress />
          ) : (
            <>
              <Header
                tabChanged={() => {
                  if (updatedTickets.current.length > 0) {
                    dispatch(refreshData({list: updatedTickets.current}))
                    updatedTickets.current = []
                  }
                }}
              />{' '}
              {/* OK???, Passsed!! ~sahil*/}
              <Route exact path='/home' render={() => <Home cb={cb} />} />
              <Route exact path='/listed' render={() => <Home cb={cb} />} />
              <Route exact path='/notlisted' render={() => <Home cb={cb} />} />
              <Route exact path='/sold' render={() => <Home cb={cb} />} />
              <Route exact path='/paymentScreen' component={PaymentScreen} />
              <Route exact path='/teamCredentials' component={TeamCredentials} />
              <Route exact path='/viewAccount' component={ViewAccount} />
              <Route exact path='/accountDetails' component={ENV === 'prod' ? ComingSoon : AccountDetails} />
            </>
          )}
        </Switch>
      </Router>
    </div>
  )
}

// console.log('~sahil proddev', ENV === 'prod')

const ComingSoon = () => (
  <div
    style={{
      color: 'yellow',
      textAlign: 'center',
    }}>
    Coming Soon
  </div>
)

export default RouteDashboard
