import {useState, useEffect} from 'react'

// usage:
// import {useMediaQueryVanilla} from '../hooks/useMediaQueryVanilla'
// const {isSE, is8Plus, isIphone8} = useMediaQueryVanilla()

export const useMediaQueryVanilla = () => {
  const [isSE, setSE] = useState(window.innerWidth < 321)
  const [is8Plus, set8Plus] = useState(window.innerWidth < 415 && window.innerWidth > 410)
  const [isIphone8, setIphone8] = useState(window.innerWidth < 376 && window.innerWidth > 374)

  const updateMedia = () => {
    setSE(window.innerWidth < 321)
    set8Plus(window.innerWidth < 415 && window.innerWidth > 410)
    setIphone8(window.innerWidth < 376 && window.innerWidth > 374)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  return {isSE, is8Plus, isIphone8}
}
