let ENV: string
let domain: string = process.env.REACT_APP_OAUTH_DOMAIN!
let clientId: string = process.env.REACT_APP_SDK_ID!
let redirectUri: string = process.env.REACT_APP_REDIRECT_URL!
let audience: string

if (!domain || !clientId || !redirectUri)
  throw new Error("Hey, you don't have either of REACT_APP_OAUTH_DOMAIN, REACT_APP_SDK_ID or REACT_APP_REDIRECT_URL defined in your .env or environment.")

if (process.env.REACT_APP_DEVELOPMENT_URL) {
  // environment is development
  ENV = 'dev'
} else if (process.env.REACT_APP_ROOT_URL) {
  // environment is production
  ENV = 'prod'
} else {
  // environment is local
  ENV = 'local'
}

switch (ENV) {
  case 'dev':
    audience = 'https://dev-ncpskpfy.auth0.com/api/v2/'
    // TODO:1: ADD REACT_APP_OAUTH_AUDIENCE (it was giving me error though, ~sahil -> ravi) in aws variables for web-app(development) , Need to check if ravi implemented the newly added variable i.e., REACT_APP_OAUTH_AUDIENCE in build process(as in .yml file) as well. // let audience: string = process.env.REACT_APP_OAUTH_AUDIENCE!
    break

  case 'prod':
    audience = ''
    // ^^ We need to update new audience value later when its registration is ready for production.
    //TODO:2: WHEN REGISTRATION TO ADDED TO PRODUCTION, UPDATE ``REACT_APP_OAUTH_AUDIENCE`` in production environment variables.
    break

  case 'local':
    audience = 'https://dev-ncpskpfy.auth0.com/api/v2/'
    break
}

export {ENV, domain, clientId, redirectUri, audience}

console.log('🧸︎🧸︎ Environment is:', ENV)
