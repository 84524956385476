/////////////

export const STORE_CREDENTIAL = "STORE_CREDENTIAL";
export const REMOVE_CREDENTIAL = "REMOVE_CREDENTIAL";

  export interface credentialState {

    name:string
    email:string

  }

  interface CredentialAction {
    type: typeof STORE_CREDENTIAL
    payload: credentialState
  }
  
  interface RemoveCredentialAction {
    type: typeof REMOVE_CREDENTIAL
    meta: {
      timestamp: number
    }
  }
  
  export type CredentialActionTypes = CredentialAction | RemoveCredentialAction
  
//   type CredentialAction = {
//     type: string
//     data: credentialsData
//   }
  
//   type DispatchType = (args: CredentialAction) => CredentialAction

///////////////