import {SeatSetsState, SeatSetsActionTypes, STORE_SEATSETS, REMOVE_SEATSETS, UPDATE_SEATSETS} from './types'

const initialState: SeatSetsState = {
  seatSets: [],
}

export function seatsetsReducer(state = initialState, action: SeatSetsActionTypes): SeatSetsState {
  switch (action.type) {
    case STORE_SEATSETS:
      return {seatSets: [...state.seatSets, action.payload]}

    case UPDATE_SEATSETS:
      const index = state.seatSets.findIndex((x) => x.id === action.id)

      const newArray = [...state.seatSets]
      newArray[index] = action.payload

      return {
        ...state,
        seatSets: newArray,
      }

    case REMOVE_SEATSETS:
      return {
        seatSets: state.seatSets.filter((seatSets) => seatSets.id !== action.meta),
      }
    default:
      return state
  }
}
