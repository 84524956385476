import React, { Dispatch, useContext, useEffect, useState } from "react";
import Cross from "../assets/editnew.png";
import cross from "../assets/Cross.png";
import circle from "../assets/circle.png";
import Forward from "../assets/forward.png";
import Backward from "../assets/backward.png";
import { isMobile, isTablet } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";
import Button from "@material-ui/core/Button";
import moment from "moment";
import { CircularProgress } from "material-ui";
import { API_END_POINT, CONFIG_DETAILS } from "../utils/Constant";
import axios from "axios";
import { ScrollTo } from "react-scroll-to";
import swal from "sweetalert";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import {
  AuthContext,
  DataContext,
  toggleContext,
  UpdateForm,
} from "../UserContext";
// import Select from "react-select";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "@material-ui/core";
import { Spring } from "react-spring";
interface Data {
  id: number;
  title: string;
  Subtitle: string;
}
interface props {
  activeStep: number;
  handleReset(): void;
  handleNext(value: any): void;
  handleBack(value: React.BaseSyntheticEvent): void;
  doubleBack(): void;
  handletoggle(value: any): void;
  backhandler(value: any): void;
}

interface Seats {
  section: string;
  row: string;
  lowSeat: number;
  quantity: number;
  faceValue: number;
  gamesId: string[];
}
const VerifyResaleTicket: React.FC<props> = (props) => {
  const { trackData, setTrackData } = useContext(DataContext);
  const { updateform, setupdateform } = useContext(UpdateForm);
  const { Auth, setAuth } = useContext(AuthContext);
  const [currentIndex, setcurrentIndex] = useState<any>();
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets;
  });
  const [finalState, setfinalState] = useState<any>(seatsets);
  // const { Game, setGame } = useContext(GameContext);
  let credential = useSelector((state: RootState) => {
    return state.credential;
  });

  const history = useHistory();
  // React.useEffect(() => {
  //   const data1: any = {
  //     initialValues: {
  //       name: currentState.initialValues.name,
  //       email: currentState.initialValues.email,
  //     },
  //     teams: [],
  //     whichTeam: [],
  //     chooseBill: {
  //       section: "",
  //       row: "",
  //       toSeat: 0,
  //       fromSeat: 0,
  //       price: 0,
  //     },
  //   };
  //   dispatch(addSignUp(data1));
  // }, []);
  let { par }: any = useParams();
  const [Open, setOpen] = useState<boolean>(false);

  const [activeStep, setActiveStep] = React.useState(
    par === "start"
      ? 0
      : par === "teams"
        ? 1
        : par === "seats"
          ? 2
          : par === "events"
            ? 3
            : 4
  );
  const HandleBoolean = () => {
    props.handletoggle(true);
  };
  const HandleBack = () => {
    setTrackData({});
    props.backhandler(2);
    history.go(-2);
    // setActiveStep((prevActiveStep : number) => prevActiveStep - 2);
  };
  const HandleDelete = (val: any, va: any) => {
    var arr = [...finalState.seatSets];
    console.log(arr);

    let arr1 = arr.splice(
      arr.findIndex((a) => a.id === va.id),
      1
    );
    console.log(arr1);
    let arr2 = arr.filter((a) => {
      return a.id !== va.id;
    });
    console.log(arr2);
    let a = arr1[0].gamesID.filter((a: any) => {
      return a.gameId !== val.gameId;
    });
    arr1[0].gamesID = a;
    console.log(arr1);
    arr2.push(arr1[0]);
    console.log(arr2);
    setfinalState({ seatSets: arr2 });
  };

  async function HandleSubmit() {
    console.log(seatsets);
    let seatSets = finalState.seatSets.map((item: any) => {
      return {
        section: item.section,
        row: item.row,
        lowSeat: parseInt(item.fromSeat),
        quantity: parseInt(item.toSeat),
        faceValue: parseInt(item.price),
        gamesId: item.gamesID.map((v: any) => {
          return v.gameId;
        }),
        teamId: item.teams.selectedTeam[0].sky_id,
      };
    });
    console.log("final Data", seatSets);
    let details: Array<any> = finalState.seatSets.map((det: any) => {
      return {
        gameData: det.gamesID,
      };
    });
    console.log("Details", details);
    console.log("seatsets", seatSets);

    let data = {
      name: credential.name,
      email: credential.email,
      seatSets: seatSets,
    };
    console.log(JSON.stringify(data, null, 2));
    axios({
      method: "post",
      url:
        `${process.env.REACT_APP_AWS_LAMBDA_SEND_VERIFICATION_CODE_URL}${API_END_POINT.SENDCODE}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          setAuth(res.data);
          localStorage.setItem("provisionalClientId", res.data.provisClientId);
          history.push("confirm-mail");
          props.handleNext(4);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // console.log(data);
  }

  return (
    <div
      className="container"
      style={{
        width: isMobile && isTablet ? "" : "77%",
        // marginLeft: isMobile && isTablet ? "" : "114px",
        height: isMobile ? "" : "70vh",
      }}
    >
      {isMobile ? (
        <div className="label" style={{ fontSize: "6vw", color: "#BC936C" }}>
          Confirm your season ticket package
        </div>
      ) : (
        <div
          className="label"
          style={{
            color: "#BC936C",
            fontSize: "40px",
            fontFamily: "Atten New",
            fontWeight: 500,
            paddingBottom: 30,
            paddingTop: 15,
          }}
        >
          Confirm your season ticket package
        </div>
      )}
      {seatsets.seatSets.length > 0 ? (
        seatsets.seatSets.map((val, index) => {
          return (
            <>
              <Row
                className="col-13"
                style={{
                  borderBottomStyle: "solid",
                  paddingTop: "5%",
                  borderBottomColor: "#cdd0dd",
                  paddingBottom: "4%",
                  fontWeight: "bold",
                  borderBottomWidth: "1px",
                }}
              >
                <Col className="col-4">
                  <div
                    style={{
                      color: "#BC936C",
                      textAlign: "initial",
                      fontFamily: "Atten New",
                      fontWeight: 500,
                    }}
                  >
                    {val.gamesID.length}
                    {"   "}
                    {val.gamesID.length > 1 ? (
                      <span> games</span>
                    ) : (
                      <span> game</span>
                    )}
                    {Open === true ? (
                      <img
                        style={{ marginLeft: "5%", marginBottom: "2%" }}
                        src={Forward}
                        onClick={() => {
                          currentIndex === index
                            ? setcurrentIndex(null)
                            : setcurrentIndex(index);

                          setOpen(!Open);
                        }}
                      />
                    ) : (
                      <img
                        style={{ marginLeft: "5%", marginBottom: "2%" }}
                        src={Backward}
                        onClick={() => {
                          currentIndex === index
                            ? setcurrentIndex(null)
                            : setcurrentIndex(index);

                          setOpen(!Open);
                        }}
                      />
                    )}
                  </div>

                  <div
                    style={{
                      color: "#FAFAFA",
                      textAlign: "initial",
                      fontFamily: "Atten New",
                      fontWeight: 500,
                    }}
                  >
                    {val.teams.selectedTeam[0].home_team}
                  </div>
                </Col>
                <Col className="col-8">
                  <Row>
                    <Col className="col-2">
                      <div
                        style={{
                          color: "#E7E1C5",
                          fontFamily: "Atten New",
                          fontWeight: 500,
                        }}
                      >
                        Section
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        {val.section}
                      </div>
                    </Col>
                    <Col className="col-2">
                      <div
                        style={{
                          fontFamily: "Atten New",
                          fontWeight: 500,
                          color: "#E7E1C5",
                        }}
                      >
                        Row
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        {val.row}
                      </div>
                    </Col>
                    <Col className="col-2">
                      <div
                        style={{
                          fontFamily: "Atten New",
                          fontWeight: 500,
                          color: "#E7E1C5",
                        }}
                      >
                        Seats
                      </div>
                      <div
                        style={{
                          fontFamily: "Roboto",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        {val.fromSeat}-{val.toSeat}
                      </div>
                    </Col>
                    <Col className="col-4">
                      <div
                        style={{
                          color: "#E7E1C5",
                          fontFamily: "Atten New",
                          fontWeight: 500,
                        }}
                      >
                        Average Face Value
                      </div>
                      <div
                        style={{
                          fontFamily: "Atten New",
                          fontWeight: 500,
                          color: "#FFFFFF",
                        }}
                      >
                        ${val.price}
                      </div>
                    </Col>
                    <Col className="col-2">
                      <img
                        style={{ float: "right" }}
                        src={Cross}
                        onClick={() => {
                          //  setTrackData(val)
                          setTrackData({
                            id: val.id,
                            teams: {
                              selectedTeam: val.teams.selectedTeam,
                            },
                            fromSeat: val.fromSeat,
                            gamesID: val.gamesID,
                            row: val.row,
                            section: val.section,
                            toSeat: val.toSeat,
                            price: val.price,
                          });
                          setupdateform(true);
                          props.doubleBack();
                          history.go(-1);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {currentIndex === index && (
                <Spring from={{ opacity: 0 }} to={{ opacity: 1 }}>
                  {(props) => (
                    <>
                      {Open === true &&  val.gamesID.length > 10 ? (
                        <ScrollTo>
                          {({ scroll }) => (
                            <button
                              style={{
                                position: "fixed",
                                width: "98px",
                                height: 36,
                                borderRadius: 50,
                                marginTop: "10px",
                                boxShadow: "none",
                                backgroundColor: "#C57A1E",
                                color: "#FFFFFF",
                                fontSize: "12px ",
                              }}
                              onClick={() => scroll({ y: 0 })}
                            >
                              Back to top
                            </button>
                          )}
                        </ScrollTo>
                      ) : null}
                      <div style={props as React.CSSProperties | undefined}>
                        {val.gamesID.map((v) => {
                          return (
                            <Row
                              className="col-13"
                              style={{
                                borderBottomStyle: "solid",
                                paddingTop: "4%",
                                borderBottomColor: "#cdd0dd",
                                paddingBottom: "3%",
                                fontWeight: "bold",
                                borderBottomWidth: "1px",
                              }}
                            >
                              <Col className="col-4">
                                <div
                                  style={{
                                    color: "#BC936C",
                                    textAlign: "initial",
                                    fontFamily: "Atten New",
                                    fontWeight: 500,
                                  }}
                                >
                                  {moment(v.datetime).format("llll")}
                                </div>
                                <div
                                  style={{
                                    color: "#FAFAFA",
                                    textAlign: "initial",
                                    fontFamily: "Atten New",
                                    fontWeight: 500,
                                  }}
                                >
                                  {
                                    /* {v.description.slice(
                                      0,
                                      v.description.length -
                                        val.teams.selectedTeam[0].home_team.length -
                                        3
                                    )} */
                                    v.description
                                  }
                                </div>
                              </Col>
                              <Col className="col-8">
                                <Row>
                                  <Col className="col-2">
                                    <div
                                      style={{
                                        color: "#E7E1C5",
                                        fontFamily: "Atten New",
                                        fontWeight: 500,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        color: "#FFFFFF",
                                      }}
                                    ></div>
                                  </Col>
                                  <Col className="col-2">
                                    <div
                                      style={{
                                        fontFamily: "Atten New",
                                        fontWeight: 500,
                                        color: "#E7E1C5",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        color: "#FFFFFF",
                                      }}
                                    ></div>
                                  </Col>
                                  <Col className="col-2">
                                    <div
                                      style={{
                                        fontFamily: "Atten New",
                                        fontWeight: 500,
                                        color: "#E7E1C5",
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        fontFamily: "Roboto",
                                        fontWeight: 500,
                                        color: "#FFFFFF",
                                      }}
                                    ></div>
                                  </Col>
                                  <Col className="col-4">
                                    <div
                                      style={{
                                        color: "#E7E1C5",
                                        fontFamily: "Atten New",
                                        fontWeight: 500,
                                      }}
                                    ></div>
                                    <div
                                      style={{
                                        fontFamily: "Atten New",
                                        fontWeight: 500,
                                        color: "#FFFFFF",
                                      }}
                                    ></div>
                                  </Col>
                                  <Col className="col-2">
                                    <img
                                      style={{ float: "right" }}
                                      src={cross}
                                      onClick={() => {
                                        HandleDelete(v, val);
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          );
                        })}
                      </div>
                      </>
                  )}
                    </Spring>
                  )}
            </>
              );
        })
      ) : (
              <div style={{ color: "#BC936C" }}>Please Click to Do you have more seats Button for add further Teams</div>
      )}
              <br />
              {/* {Open === true ? (
        <ScrollTo>
          {({ scroll }) => (
            <button
              style={{
                width: "98px",
                height: 36,
                borderRadius: 50,
                boxShadow: "none",
                backgroundColor: "#C57A1E",
                color: "#FFFFFF",
                fontSize: "12px    ",
              }}
              onClick={() => scroll({ y: 0 })}
            >
              Back to top
            </button>
          )}
        </ScrollTo>
      ) : null} */}
              <Row>
                <Col className="col-8" style={{ paddingTop: "4%" }}>
                  <Button
                    style={{
                      color: "#E7E1C5",
                      float: "left",
                      fontWeight: 500,
                      borderRadius: 5,
                      backgroundColor: "#1A1A1A",
                      borderStyle: "solid",
                      borderWidth: "1px",
                      borderColor: "#1A1A1A",
                      fontSize: isMobile ? "3vw" : "15px",
                      fontFamily: "Atten New",
                    }}
                    onClick={() => {
                      HandleBack();
                    }}
                  >
                    DO YOU HAVE MORE SEATS?
          </Button>
                </Col>
                {/* <Col
        className="col-4"
        style={{
          textAlign: "left",
          paddingTop: "4%",
          fontWeight: "bold",
        }}
      >
        <div
          style={{
            color: "#cdd0dd",
            fontFamily: "Roboto",
            fontWeight: 500,
          }}
        >
          Total
        </div>
        <div style={{ fontFamily: "Roboto", fontWeight: 500 }}>
          ${currentState.chooseBill.price.toString()}
        </div>
      </Col> */}
              </Row>
              <Row
                style={{
                  marginTop: "7rem",
                  marginBottom: "10rem",
                }}
              >
                {/* <Col>
          <Button
            variant="contained"
            style={{
              width: 150,
              height: 50,
              borderRadius: 50,
              backgroundColor: "#5B5B5B",
              float: "left",
              boxShadow: "none",
            }}
            disabled={true}
            onClick={() => {
              HandleBoolean();
            }}
          >
            <div style={{ color: "#FAFAFA" }}>Back</div>
          </Button>
        </Col> */}
                <Col className="col-7">
                  <Button
                    variant="contained"
                    style={{
                      width: 150,
                      height: 50,
                      borderRadius: 50,
                      boxShadow: "none",
                      backgroundColor: "#C57A1E",
                      float: "right",
                    }}
                    onClick={() => {
                      HandleSubmit();
                    }}
                  >
                    <div style={{ color: "#FCFCFC" }}>NEXT</div>
                  </Button>
                </Col>
              </Row>
            </div>
          );
        };
export default VerifyResaleTicket;
