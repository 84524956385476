import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import * as yup from "yup";
import { Formik } from "formik";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import { Row, Col, Container } from "react-bootstrap";
import ProfileUpdatedIcon from '../images/profile-updated.svg'
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Header from "../components/Header";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import LOGO from "../images/logo.png";
import clsx from "clsx";
import fill from "../images/Fill.png";
import fillSmall from "../images/FillSmall.png";
import Typography from "@material-ui/core/Typography";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Cross from "../images/PencilEdit.svg";
import { HideHeaderContext } from "../UserContext";
import "../components/ViewAccount.css";
import axios, { AxiosResponse, AxiosRequestConfig } from "axios";
import { useAuth0 } from "@auth0/auth0-react";
import { forceReRenderContext } from "../UserContext";
import { CircularProgress } from "material-ui";
import { API_END_POINT } from "../utils/Constant";
import { ENV } from "../utils/environmentConfig";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    indicator: {
      color: "#BC936B",
    },

    title: {
      marginTop: "20px",
      marginLeft: isMobile ? "20px" : "50px",
    },
    avatar: {
      heigh: "42px",
      width: "42px",
      marginRight: "10px",
      backgroundColor: "#A39C73",
      color: "#FFFFFF",
      marginTop: "20px",
    },
    outer_width: {
      width: isMobile ? "375px" : "1440px",
      padding: isMobile ? "" : "40px 0",
    },

    navbar: {
      width: isMobile ? "345px" : "1383px",
      marginLeft: isMobile ? "10px" : "70px",
    },
    navbarText: {
      // float: "left",
      marginTop: "10px",
      alignItems: "center",
      color: "#C57A1E",
      fontFamily: "Atten New",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "18px",
      lineHeight: "18px",
      borderBottom: "1px solid rgba(255, 255, 255, 0.14)",
      display: "flex",
      flexGrow: isMobile ? 0 : 1,
    },

    avatar1: {
      width: isMobile ? "48px" : "80px",
      // marginRight: "10px",
      backgroundColor: "#C8A178",
      color: "#FFFFFF",
      fontSize: isMobile ? "26px" : "40px",
      marginLeft: isMobile ? "43%" : "46%",
      textAlign: "center",
      justifyContent: "center",
    },
  })
);

const ViewAccountSchema = yup.object().shape({
  City: yup.string().required("Required").nullable(),
  Address: yup.string().required("Required").nullable(),
  Address1: yup.string().required("Required").nullable(),
  Mobile: yup
    .string()
    .required("Phone number is required")
    .nullable()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/g,
      "Invalid phone number"
    ),
  ZipCode: yup
    .string()
    .required("Zip Code is required")
    .nullable()
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9 .,-]*$/, "Invalid Zip Code"),
  Email: yup.string().email().required("Email is required").nullable(),
  State: yup.string().required("Required").nullable(),
});

interface ButtonProps {
  handleBack(value: React.BaseSyntheticEvent): void;
  handleNext(value: any): void;
  Email: any;
  email: string;
  onSubmit(): void;
  callbackfunction(): void;
  value: string;
  name: string;
  emailIndex: number;
}

const TeamCredentials: React.FC<ButtonProps> = (props) => {
  const [toggle, settoggle] = React.useState<boolean | "">(true);
  const [close, setClose] = React.useState<boolean>(false);
  const [emailEditable, setEmailEditable] = useState(false);
  const [mobileEditable, setmobileEditable] = useState(false);
  const [addressone, setaddressone] = useState(false);
  const [addresstwo, setaddresstwo] = useState(false);
  const [city, setcity] = useState(false);
  const [stateEditable, setstateEditable] = useState(false);
  const [zipcode, setzipcode] = useState(false);
  const [submitAccount, setSubmitAccount] = React.useState(false);
  const [submit, setsubmit] = React.useState(false);
  const { forceReRender } = useContext(forceReRenderContext);

  interface ViewAccountType {
    address1: any | string;
    address2: any | string;
    city: any | string;
    email: any | string;
    mobilenumber: any | string;
    state: any | string;
    zip: any | number;
  }

  const [ViewAccount, setViewAccount] = React.useState<Array<ViewAccountType>>([
    {
      address1: null,
      address2: null,
      city: null,
      email: "",
      mobilenumber: null,
      state: null,
      zip: null,
    },
  ]);

  const [showProgressBar, setShowProgressBar] = useState(false);
  const [show, setshow] = useState(false);

  const { HideHeader, setHideHeader } = useContext(HideHeaderContext);
  const { user, getIdTokenClaims } = useAuth0<any>();

  const classes = useStyles();
  let history = useHistory();

  const handleClose = (e: any) => {
    setClose(true);
  };

  const saveteam = () => {
    history.push("/");
  };

  const cancelteam = () => {
    history.push("/home");
  };

  // useEffect(() => {
  //   var axios = require("axios");

  //   var config = {
  //     method: "get",
  //     url: "https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/account/250",
  //     headers: {},
  //   };

  //   axios(config)
  //     .then(function (res: any) {
  //       console.log(res.data);
  //       setViewAccount(res.data);
  //     })
  //     .catch(function (error: any) {
  //       console.log(error);
  //     });
  //   setHideHeader(false);
  // }, [HideHeader]);

  useEffect(() => {
    (async () => {
      let id = user["https://tiqassist.com/user_id"];

      const prodUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.ACCOUNT}/${id}`;
      const devUrl = `https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/account/${id}`;
      const url = ENV === "prod" ? prodUrl : devUrl;

      try {
        const token = await getIdTokenClaims();
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${token.__raw}`,
          },
        });
        setViewAccount(response.data);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  console.log("~sahil viewAccount", { ViewAccount });

  const [isSE, setSE] = useState(window.innerWidth < 321);
  const [is8Plus, set8Plus] = useState(
    window.innerWidth < 415 && window.innerWidth > 410
  );
  const [isIphone12, setIphone12] = useState(
    window.innerWidth < 429 && window.innerWidth > 423
  );
  const updateMedia = () => {
    console.log("isIphone12", isIphone12);
    setSE(window.innerWidth < 321);
    set8Plus(window.innerWidth < 415 && window.innerWidth > 410);
    setIphone12(window.innerWidth < 429 && window.innerWidth > 423);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  return (
    <>
      <div style={{ background: "#010101" }}>
        {console.log("isIphone12", isIphone12)}
        <div className="container">
          <div
            style={{
              height: isMobile ? 171 : 191,
              textAlign: "center",
              background: "rgba(231, 225, 197, 0.15)",
              marginLeft: is8Plus
                ? "-15px"
                : isMobileOnly
                ? "-15px"
                : isTablet
                ? ""
                : "100px",
              marginRight: isMobileOnly ? "6px" : isTablet ? "" : "100px",
              marginBottom: isMobile ? 20 : 50,
              width: isIphone12
                ? "428px"
                : is8Plus
                ? "414px"
                : isMobile
                ? "375px"
                : "",
              paddingTop: "40px",
            }}
          >
            {" "}
            <Avatar
              style={{
                height: isMobile ? "48px" : "80px",
                marginBottom: "10px",
              }}
              className={classes.avatar1}
            >
              {user?.nickname?.charAt(0).toUpperCase()}
            </Avatar>
            <div
              style={{
                fontFamily: "Atten New",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: isMobile ? "16px" : "26px",
                color: "#FFFFFF",
              }}
            >
              {user?.nickname}
            </div>
          </div>

          {showProgressBar ? (
            <CircularProgress
              color="#A39C73"
              style={{
                position: "absolute",
                top: "43vh",
                left: "43vw",
                zIndex: 5000,
              }}
            />
          ) : (
            ""
          )}

          <Formik
            enableReinitialize
            // Using ^^ `enableReinitialize` to update values later when ViewAccount state changes, yo! Src: https://stackoverflow.com/a/63332103
            initialValues={{
              Email: ViewAccount === null ? "" : ViewAccount[0].email,
              Mobile: ViewAccount === null ? "" : ViewAccount[0].mobilenumber,
              City: ViewAccount === null ? "" : ViewAccount[0].city,
              Address: ViewAccount === null ? "" : ViewAccount[0].address1,
              Address1: ViewAccount === null ? "" : ViewAccount[0].address2,
              State: ViewAccount === null ? "" : ViewAccount[0].state,
              ZipCode: ViewAccount === null ? "" : ViewAccount[0].zip,
            }}
            validationSchema={ViewAccountSchema}
            onSubmit={(values) => {
              setShowProgressBar(true);

              // TODO: Debug why onSubmit is called twice when we submit. ~paras vashisht -> Sahil.
              // console.log("values", values);
              // var axios = require('axios')
              let id = user["https://tiqassist.com/user_id"];

              let data = JSON.stringify({
                // clientId: Math.random(),
                clientId: id,
                mobileNumber: values.Mobile,
                address1: values.Address,
                address2: values.Address1,
                city: values.City,
                state: values.State,
                zip: values.ZipCode,
                email: values.Email,
              });

              const localAndDevUrl =
                "https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev/account";
              const prodUrl = `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.ACCOUNT}`;

              const url = ENV === "prod" ? prodUrl : localAndDevUrl;

              let config: AxiosRequestConfig = {
                method: "post",
                url: url,
                headers: {
                  "Content-Type": "application/json",
                },
                data: data,
              };

              axios(config)
                .then(function (res: AxiosResponse) {
                  setShowProgressBar(false);
                  console.log(res.data);
                  setshow(true);
                  setTimeout(() => {
                    setshow(false);
                  }, 1500);

                  if (res.status === 200) {
                    // alert('Successfully Stored')
                  }
                })
                .catch(function (error: any) {
                  console.log(error);
                  setShowProgressBar(false);
                });
            }}
          >
            {({
              values,
              handleChange,
              handleSubmit,
              handleBlur,
              errors,
              touched,
            }) => {
              console.log("~sahil valuesaccountformik", values);
              return (
                <Form
                  onSubmit={handleSubmit}
                  autoComplete={"off"}
                  style={{
                    marginLeft: isMobileOnly ? "" : isTablet ? "" : "100px",
                    marginRight: isMobileOnly ? "6px" : isTablet ? "" : "100px",
                    height: "100%",
                  }}
                >
                  <>
                    <Row>
                      <Col>
                        <Form.Group
                          controlId="Email"
                          style={{
                            marginBottom: isMobileOnly ? "" : "1.5rem",
                          }}
                        >
                          <Form.Label
                            style={{
                              color: "#E7E1C5",
                              float: "left",
                              fontWeight: 700,
                              fontSize: isMobileOnly
                                ? "12.88px"
                                : isTablet
                                ? "19px"
                                : "",
                              fontFamily: "Atten New",
                            }}
                          >
                            Email <span style={{ color: "#FF8B9A" }}>*</span>
                          </Form.Label>
                          <img
                            onClick={() => setEmailEditable(true)}
                            style={{
                              float: "right",
                              marginRight: 10,
                            }}
                            src={Cross}
                          />
                          <Form.Control
                            disabled={submit}
                            name="Email" //~sahil.
                            type="email"
                            placeholder=""
                            value={values.Email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              height: "60px",
                              backgroundColor: "#151515",
                              color: "#E7E1C5",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                            }}
                          />

                          {errors.Email && touched.Email ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                              }}
                            >
                              {errors.Email}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group
                          controlId="Mobile"
                          style={{
                            marginTop: isMobileOnly && isTablet ? "2%" : "",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Form.Label
                            style={{
                              color: "#E7E1C5",
                              float: "left",
                              fontWeight: 700,
                              fontSize: isMobileOnly
                                ? "12.88px"
                                : isTablet
                                ? "19px"
                                : "",
                              fontFamily: "Atten New",
                            }}
                          >
                            Mobile Number{" "}
                            <span style={{ color: "#FF8B9A" }}>*</span>
                          </Form.Label>
                          <img
                            onClick={() => setmobileEditable(true)}
                            style={{
                              float: "right",
                              marginRight: 10,
                            }}
                            src={Cross}
                          />
                          <Form.Control
                            disabled={submit}
                            type='text'
                            name='Mobile' //~sahil.
                            placeholder=''
                            value={values.Mobile || ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              height: "60px",
                              backgroundColor: "#151515",
                              color: "#E7E1C5",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                            }}
                          />

                          {errors.Mobile && touched.Mobile ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                              }}
                            >
                              {errors.Mobile}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Form.Label
                        style={{
                          color: "#E7E1C5",
                          float: "left",
                          fontWeight: 700,
                          fontSize: isMobileOnly
                            ? "12px"
                            : isTablet
                            ? "19px"
                            : "",
                          fontFamily: "Atten New",
                          marginBottom: isTablet ? "20px" : "10px",
                          marginLeft: isMobileOnly
                            ? "17px"
                            : isTablet
                            ? ""
                            : "15px",
                          marginRight: isMobileOnly
                            ? "6px"
                            : isTablet
                            ? ""
                            : "100px",
                        }}
                        className="d-flex"
                      >
                        Address<span style={{ color: "#FF8B9A" }}>*</span>
                      </Form.Label>
                    </Row>
                    <img
                      onClick={() => setaddressone(true)}
                      style={{
                        marginTop: "-6%",
                        marginRight: isMobileOnly ? "3%" : "8%",
                        float: isMobileOnly ? "right" : "none",
                        marginLeft: isMobileOnly ? "" : "97%",
                      }}
                      src={Cross}
                    />
                    <Row style={{ marginLeft: 0 }}>
                      <Form.Group controlId="Address">
                        <Form.Control
                          disabled={submit}
                          placeholder="Mailing Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address || ""}
                          style={{
                            width: isIphone12
                              ? "390px"
                              : is8Plus
                              ? "380px"
                              : isMobileOnly
                              ? "340px"
                              : "428px",
                            height: "60px",
                            backgroundColor: "#151515",
                            border: "1px solid rgba(255, 255, 255, 0.2)",
                            color: "#E7E1C5",
                          }}
                        />

                        {errors.Address && touched.Address ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              float: "left",
                            }}
                          >
                            {errors.Address}
                          </div>
                        ) : null}
                      </Form.Group>
                      <Form.Group controlId="Address1">
                        <Form.Control
                          disabled={submit}
                          placeholder="Mailing Address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.Address1 || ""}
                          style={{
                            width: isIphone12
                              ? "390px"
                              : is8Plus
                              ? "380px"
                              : isMobileOnly
                              ? "340px"
                              : "440px",
                            height: "60px",
                            backgroundColor: "#151515",
                            border: "none",
                            color: "#E7E1C5",
                            marginLeft: isMobile ? "" : 40,
                          }}
                        />

                        {errors.Address1 && touched.Address1 ? (
                          <div
                            style={{
                              color: "red",
                              fontSize: "12px",
                              float: "left",
                            }}
                          >
                            {errors.Address1}
                          </div>
                        ) : null}
                      </Form.Group>
                    </Row>

                    <Row>
                      <Col>
                        <Form.Group
                          controlId="City"
                          style={{
                            marginTop: isMobileOnly && isTablet ? "2%" : "",
                            marginBottom: "1.5rem",
                          }}
                        >
                          <Form.Label
                            style={{
                              color: "#E7E1C5",
                              float: "left",
                              fontWeight: 700,
                              fontSize: isMobileOnly
                                ? "12.88px"
                                : isTablet
                                ? "19px"
                                : "",
                              fontFamily: "Atten New",
                            }}
                          >
                            City <span style={{ color: "#FF8B9A" }}>*</span>
                          </Form.Label>
                          <img
                            onClick={() => setcity(true)}
                            style={{
                              float: "right",
                              marginRight: 10,
                            }}
                            src={Cross}
                          />
                          <Form.Control
                            disabled={submit}
                            type="text"
                            placeholder="City"
                            value={values.City || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              height: "60px",
                              backgroundColor: "#151515",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                              color: "#E7E1C5",
                            }}
                          />

                          {errors.City && touched.City ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                              }}
                            >
                              {errors.City}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Label
                          style={{
                            color: "#E7E1C5",
                            float: "left",
                            fontWeight: 700,
                            fontSize: isMobileOnly
                              ? "12px"
                              : isTablet
                              ? "19px"
                              : "",
                            fontFamily: "Atten New",
                            marginBottom: isTablet ? "20px" : "10px",
                            marginRight: isMobileOnly
                              ? "6px"
                              : isTablet
                              ? ""
                              : "100px",
                          }}
                          className="d-flex"
                        >
                          State/Province{" "}
                          <span style={{ color: "#FF8B9A" }}>*</span>
                        </Form.Label>
                        <Form.Group controlId="State">
                          <Form.Control
                            disabled={submit}
                            placeholder="State/Province "
                            value={values.State || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              width: isIphone12
                                ? "180px"
                                : is8Plus
                                ? "175px"
                                : isMobileOnly
                                ? "159px"
                                : "",
                              height: "60px",
                              backgroundColor: "#151515",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                              color: "#E7E1C5",
                            }}
                          />
                          <img
                            onClick={() => {
                              setstateEditable(true);
                            }}
                            style={{
                              float: "right",
                              marginRight: 10,
                              // marginTop: isMobileOnly ? '-56%' : '-20%', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
                              marginTop: isMobileOnly ? "-53%" : "-20%",
                            }}
                            src={Cross}
                          />
                          {errors.State && touched.State ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                              }}
                            >
                              {errors.State}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Label
                          style={{
                            color: "#E7E1C5",
                            float: "left",
                            fontWeight: 700,
                            fontSize: isMobileOnly
                              ? "12px"
                              : isTablet
                              ? "19px"
                              : "",
                            fontFamily: "Atten New",
                            marginBottom: isTablet ? "20px" : "10px",
                            marginRight: isMobileOnly
                              ? "6px"
                              : isTablet
                              ? ""
                              : "100px",
                            marginLeft: isMobile ? -20 : "",
                          }}
                          className="d-flex"
                        >
                          Zip Code <span style={{ color: "#FF8B9A" }}>*</span>
                        </Form.Label>
                        <Form.Group controlId="ZipCode">
                          <Form.Control
                            disabled={submit}
                            placeholder="Zip Code "
                            value={values.ZipCode || ""}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            style={{
                              width: isIphone12
                                ? "200px"
                                : is8Plus
                                ? "190px"
                                : isMobile
                                ? " 170px"
                                : "440px",
                              marginLeft: isMobile ? -20 : "",
                              height: "60px",
                              backgroundColor: "#151515",
                              border: "1px solid rgba(255, 255, 255, 0.2)",
                              color: "#E7E1C5",
                            }}
                          />
                          <img
                            onClick={() => {
                              setzipcode(true);
                            }}
                            style={{
                              float: "right",
                              marginRight: 10,
                              marginTop: isMobileOnly ? "-56%" : "-20%",
                            }}
                            src={Cross}
                          />
                          {errors.ZipCode && touched.ZipCode ? (
                            <div
                              style={{
                                color: "red",
                                fontSize: "12px",
                                float: "left",
                              }}
                            >
                              {errors.ZipCode}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                  </>

                  <Row
                    style={{
                      marginTop: "11%",
                      marginBottom: "11%",
                    }}
                  >
                    <Col>
                      <Button
                        variant="contained"
                        style={{
                          width: isMobile ? 129 : 150,
                          height: isMobile ? 40 : 50,

                          borderRadius: 50,
                          backgroundColor: "#5B5B5B",
                          float: "left",
                        }}
                        onClick={() => {
                          cancelteam();
                          setTimeout(() => {
                            forceReRender({}); //~sahil, just so that tab indicator on header pages get back coz history.push doesn't cause components to re-render.
                          });
                        }}
                      >
                        <div style={{ color: "#FFFFFF" }}>Cancel</div>
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant="contained"
                        type="Submit"
                        style={{
                          width: isMobile ? 129 : 150,
                          height: isMobile ? 40 : 50,
                          borderRadius: 50,
                          backgroundColor: "#C57A1E",
                          float: "right",
                        }}
                        onClick={() => handleSubmit()}
                      >
                        <div style={{ color: "#FCFCFC" }}>Save</div>
                      </Button>
                    </Col>
                  </Row>
                </Form>
              );
            }}
          </Formik>
        </div>

        {show ? (
          <div className='home-list-delist-popup position-fixed w-100 h-100 flex-center'>
            <div className='small-box'>
              <img src={ProfileUpdatedIcon} alt='fill_image' />
              <div
                className='small-box-message'
                style={{
                  marginLeft: isMobile ? '10px' : '20px',
                  fontSize: isMobile ? '20px' : '60px',
                }}>
                Profile Updated !
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};
export default TeamCredentials;
