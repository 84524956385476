export const API_END_POINT = {
  TEAM: "/teams",
  GAME: "/games",
  REGISTER:"/ta_api/register",
  EMAIL_EXIST:"/email_exist",
  SENDCODE:"/sendVerificationCode",
  CHECKCODE:"/checkVerificationCode",
  LIST:"/list",
  DELIST:"/delist",
  DASHBOARD:"/dashboard",
  TEAMCREDENTIAL:"/team_credentials",
  ACCOUNT:"/account",
  BANKINFO:"/bank_info"
};

export const CONFIG_DETAILS = {
  ROOT: "https://6dvzj3ijy6.execute-api.us-east-1.amazonaws.com/dev",
  ROOTHEROKU:'https://ta_client_dashboard:U6MwVt4AxeDfVL.b-Nk_@tiqassist-stage-api.herokuapp.com',
  API_KEY: "e75dee869dff98ffe0eb5b32e84a057b",
};

export const CONSTANT = [
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "NBA",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "NHL",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "MLB",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "NFL",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "XML",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Washington",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NBA",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NHL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "MLB",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "NFL",
    name: "Trail Blazers",
    state: "Oregon",
  },
  {
    abbrev: "POR",
    city: "Portland",
    fullName: "Portland Trail Blazers",
    id: Math.random().toString(),
    leagueId: Math.random().toString(),
    leagueName: "XML",
    name: "Trail Blazers",
    state: "Oregon",
  },

];
