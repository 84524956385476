import React, {useState, useEffect, useContext} from 'react'
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import {isMobile} from 'react-device-detect'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import LOGO from '../images/logo.png'
import '../components/PaymentScreen.css'
import clsx from 'clsx'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PAYMENTWEB from '../images/PaymentWeb.png'
import PAYMENTMOB from '../images/paymentmob.png'
import UPDOWNARROW from '../images/arrowUPDown.png'
import Moment from 'react-moment'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import {HideHeaderContext, TotalContext, PaymentScreenContext} from '../UserContext'
import {useHistory} from 'react-router-dom'
import Header from '../components/Header'
import {Payment} from '@material-ui/icons'
import {useSelector} from 'react-redux'
import {RootState} from '../store'
import {setFlagsFromString} from 'node:v8'
import {HeaderMinimal} from './HeaderMinimal'

let DEBUG_DESIGN_FLAG = Boolean(0) // Use 1 to enable debug mode in design, use 0 to ship in production.

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    indicator: {
      color: '#BC936B',
    },

    title: {
      marginTop: '20px',
      marginLeft: isMobile ? '20px' : '50px',
    },
    avatar: {
      heigh: '42px',
      width: '42px',
      marginRight: '10px',
      backgroundColor: '#A39C73',
      color: '#FFFFFF',
      marginTop: '20px',
    },
    outer_width: {
      width: isMobile ? '375px' : '1440px',
      padding: isMobile ? '' : '40px 0',
    },

    navbar: {
      // width: isMobile ? '345px' : '1383px',//~sahil, giving static width to anything is poisonous.
      marginLeft: isMobile ? '10px' : '70px',
    },
    navbarText: {
      // float: "left",
      marginTop: '10px',
      alignItems: 'center',
      color: '#C57A1E',
      fontFamily: 'Atten New',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '18px',
      borderBottom: '1px solid rgba(255, 255, 255, 0.14)',
      display: 'flex',
      flexGrow: isMobile ? 0 : 1,
    },
    indicatorColor: {
      // background: "#BC936B",
      // borderBottom: "#BC936B",
    },
  })
)

interface TabPanelProps {
  children?: React.ReactNode
  index: any
  value: any
}

interface Data {
  date: string
  amount: string
  confirnation: string
  handleSortDate(): void
  sortedDate(): void
}

function TabPanel(props: TabPanelProps) {
  const {children, value, index, ...other} = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`scrollable-auto-tabpanel-${index}`} aria-labelledby={`scrollable-auto-tab-${index}`} {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const PaymentScreen: React.FC = (props) => {
  const {Paymnet, setPaymnet} = useContext(PaymentScreenContext)
  const {total, settotal} = useContext(TotalContext)
  const {HideHeader, setHideHeader} = useContext(HideHeaderContext)
  const classes = useStyles()
  let history = useHistory()
  const [value, setValue] = React.useState(0)
  const [revert, setRevert] = React.useState(false)

  const dashboardData = useSelector((state: RootState) => {
    return state.dashboard
  })

  // total data banners.
  const [totals, settotals] = useState<any>(dashboardData.totals)
  console.log(dashboardData)

  // table data
  const [payment, setPayment] = React.useState(dashboardData.payments)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }
  const handleSortDate = () => {
    if (revert) {
      let item = payment?.sort((a: any, b: any) => new Date(a.submitted_date).getTime() - new Date(b.submitted_date).getTime())
      setPayment(item)
      setRevert(false)
    } else {
      let item = payment?.sort((a: any, b: any) => new Date(b.submitted_date).getTime() - new Date(a.submitted_date).getTime())
      setPayment(item)
      setRevert(true)
    }
  }

  useEffect(() => {
    setHideHeader(false)
  }, [HideHeader])
  var commaNumber = require('comma-number')

  const [isSE, setSE] = useState(window.innerWidth < 321)
  const [is8Plus, set8Plus] = useState(window.innerWidth < 415 && window.innerWidth > 410)
  const [isIphone12, setIphone12] = useState(window.innerWidth < 429 && window.innerWidth > 422)
  const updateMedia = () => {
    setSE(window.innerWidth < 321)
    set8Plus(window.innerWidth < 415 && window.innerWidth > 410)
    setIphone12(window.innerWidth < 429 && window.innerWidth > 422)
  }
  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  })

  return (
    <>
      <div className={classes.root}>
        {/* {console.log("payment", Paymnet)} */}

        <HeaderMinimal isMobile={isMobile} />
      </div>

      <div
        style={{
          border: DEBUG_DESIGN_FLAG ? '2px solid yellow' : '', //~sahil, amazing.
          backgroundImage: isMobile ? `url(${PAYMENTMOB})` : `url(${PAYMENTWEB})`,
          // width: is8Plus ? '394px' : isMobile ? '375px' : '', ~sahil, giving static width is poisonous.
          height: 800,
        }}>
        <div
          style={{
            border: DEBUG_DESIGN_FLAG ? '2px solid brown' : '',
          }}
          className={clsx(classes.navbarText, classes.navbar)}>
          <Tabs
            style={{
              fontFamily: 'Atten New',
              fontStyle: 'normal',
              fontWeight: 'normal',
            }}
            value={value}
            onChange={handleChange}
            indicatorColor='primary'
            textColor='inherit'
            variant='scrollable'
            scrollButtons='auto'
            aria-label='scrollable auto tabs example'
            TabIndicatorProps={{
              style: {
                backgroundColor: '#C57A1E',
                borderBottomStyle: 'solid',
                borderBottomColor: '#c57a1e',
                borderBottomWidth: '2px',
                // paddingRight: isMobile ? "40px" : "",
              },
            }}
            classes={{
              indicator: classes.indicator,
            }}>
            <Tab
              style={{
                color: '#C57A1E',
                fontSize: '18px',
                fontFamily: 'atten New',
                fontWeight: 'normal',
                marginRight: '135px',
              }}
              label='Payments'
              {...a11yProps}
            />
          </Tabs>
        </div>

        <div
          style={{
            flex: 1,
            flexDirection: isMobile ? 'column' : 'row',
            border: DEBUG_DESIGN_FLAG ? '4px solid deeppink' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
            // width: '100vw', //~sahil ~debug, you never need width as all webistes are responsive by default, ~kevin powell.
            // ^^
          }}
          className="d-flex flex-wrap justify-content-space-between' align-item-center mt-3">
          <div
            style={{
              border: DEBUG_DESIGN_FLAG ? '2px solid blue' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
              flex: 1,
              flexDirection: 'column',
              // width: isIphone12 || is8Plus ? '380px' : isMobile ? '340px' : '631px',
              // width: '100%', //~sahil
              // ^^^ PLEASE DON"T GIVE WIDTH TO anything if possible, as its the root cause of all evil.
              // height: isMobile ? '68px' : '120px',//~sahil original.
              background: 'rgba(196, 196, 196, 0.21)',
              borderRadius: '8px',
              // marginLeft: isIphone12 ? '25px' : isMobile ? '17px' : '100px', // ~sahil, need to give padding-left in alternate here.
              // marginRight: isMobile ? '' : '10px', // ~sahil, need to give padding-left in alternate here.
              marginBottom: isMobile ? '10px' : '',
            }}
            className='d-flex align-item-center px-2 mx-3'>
            <div
              style={{
                border: DEBUG_DESIGN_FLAG ? '2px solid green' : '',
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: isMobile ? '16px' : '28px',
                lineHeight: '28px',
                alignItems: 'center',
                marginTop: isMobile ? '5px' : '8px',
              }}
              className='d-flex justify-content-between'>
              <div
                style={{
                  color: ' #E7E1C5',
                  // marginLeft: isMobile ? '-40px' : '-30px', //~sahil, original
                  textAlign: 'initial',
                }}>
                Total payments made
              </div>
              {/* <div className='flex-grow-1' /> */}
              <div
                style={{
                  color: '#BC936B',
                  marginRight: '10px',
                  // marginRight: '20px', //~sahil
                }}>
                ${commaNumber((totals.total_payments * (100 / 100)).toFixed(2))}
                {console.log('Paymnet', Paymnet)}
              </div>
            </div>
            <div
              style={{
                width: isMobile ? '200px' : '456px',
                // height: isMobile ? 50 : '', // ~sahil original(giving heights is poisonous, instead give padding top/bottom like below).
                padding: isMobile ? '1.8% 0%' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: isMobile ? '10px' : '20px',
                lineHeight: '20px',
                alignItems: 'center',
                color: '#FFFFFF',
                marginTop: isMobile ? '2px' : '25px',
                // marginLeft: isMobile ? '9px' : '20px', //~sahil, I have px-2 to the container, yo!!
                textAlign: 'initial',
              }}
              className='d-flex flex-wrap justify-content- align-item-center '>
              Total of all the payments shown below. Payments arrive within two business days
            </div>
          </div>

          <div
            style={{
              flex: 1,
              flexDirection: 'column',
              border: DEBUG_DESIGN_FLAG ? '2px solid magenta' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎

              // width: isIphone12 || is8Plus ? '380px' : isMobile ? '340px' : '631px', //~sahil, giving static width in px is poisonous, instead use % to give widths.
              // width: isIphone12 || is8Plus ? '1px' : isMobile ? '340px' : '631px',
              background: 'rgba(196, 196, 196, 0.21)',
              borderRadius: '8px',
              // marginLeft: isIphone12 ? '25px' : isMobile ? '17px' : '30px', // ~sahil, giving width in px is poisonous, instaed use horizontal padding..

              // marginRight: isMobile ? '' : '100px', // ~sahil, original
            }}
            className='d-flex align-item-center px-2 mx-3'>
            <div
              style={{
                border: DEBUG_DESIGN_FLAG ? '2px solid green' : '',
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: isMobile ? '16px' : '28px',
                lineHeight: '28px',
                alignItems: 'center',
                marginTop: isMobile ? '5px' : '8px',
                textAlign: 'initial',
              }}
              className='d-flex justify-content-between'>
              <div
                style={{
                  color: ' #E7E1C5',
                  // marginLeft: isMobile ? '-40px' : '-30px', //~sahil, original
                }}>
                Total payments pending
              </div>
              {/* <div className='flex-grow-1' /> */}
              <div
                style={{
                  color: '#BC936B',
                  // marginRight: '20px', //~sahil
                }}>
                ${commaNumber((totals.total_sales - totals.total_payments * (100 / 100)).toFixed(2))}
              </div>
            </div>
            <div
              style={{
                width: isMobile ? '200px' : '456px', //~sahil, width is poisonous, instead use padding-left or something.
                height: isMobile ? '50px' : '',
                fontFamily: 'Atten New',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: isMobile ? '10px' : '20px',
                lineHeight: '20px',
                alignItems: 'center',
                color: '#FFFFFF',
                marginTop: isMobile ? '2px' : '25px',
                // marginLeft: isMobile ? '9px' : '20px', //~sahil original
                textAlign: 'initial',
              }}
              // className='d-flex flex-wrap justify-content-center align-item-center '>
              className='d-flex flex-wrap align-item-center'>
              Payments are processed within 3-8 business days after the event takes place
            </div>
          </div>

          {/* dates */}

          <div
            className='d-flex justify-content-center align-item-center mt-3  '
            style={{
              border: DEBUG_DESIGN_FLAG ? '2px solid red' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
              background: '#3F4042',
              boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.08)',
              // marginRight: "56px",
              // width: isIphone12 ? '428px' : is8Plus ? '411px' : isMobile ? '375px' : ' 89%',
              width: '100%',
              height: '47px',
              fontSize: isMobile ? '14px' : '20px',
              lineHeight: isMobile ? '31.5px' : '47.79px',
              color: '#DAD1BA',
              fontFamily: 'Atten New',
              fontStyle: 'normal',
              textAlign: 'center',
              margin: '0 auto',
              // marginLeft: isMobile ? "" : "70px",

              // marginTop: isMobile ? "" : "72px",
            }}>
            <div
              style={{
                flexGrow: isMobile ? 0.5 : 0.5,
                marginTop: isMobile ? '8px' : '',
                marginLeft: '5px',
              }}>
              {' '}
              PAY DATE
              <span>
                <UnfoldMoreIcon
                  style={{
                    color: '#9C9FB0',
                    transform: 'matrix(-1, 0, 0, 1, 0, 0)',
                  }}
                  className='ml-2'
                  onClick={handleSortDate}
                />
              </span>
            </div>
            <div
              style={{
                flexGrow: isMobile ? 0.5 : 0.5,
                marginTop: isMobile ? '8px' : '',
                marginRight: isMobile ? '10px' : '18px',
              }}>
              AMOUNT
            </div>
            <div
              style={{
                flexGrow: isMobile ? 0.5 : 0.5,
                marginTop: isMobile ? '8px' : '',
                marginRight: isMobile ? '15px' : '20px',
              }}>
              CONFIRMATION
            </div>
          </div>

          {payment?.map((value: any) => {
            //  console.log('check......',value)
            // let sortedDate = Paymnet.sort((a: any, b: any) =>
            //   a.submitted_date

            //     .reverse()
            //     .join()
            //     .localeCompare(b.submitted_date.reverse().join())
            // );

            return (
              <div
                className='d-flex justify-content-center align-item-center mt-2  '
                style={{
                  border: DEBUG_DESIGN_FLAG ? '2px solid orange' : '', // ~sahil 🛑︎🛑︎🛑︎ debug, 🛑︎🛑︎🛑︎
                  // marginRight: isMobile ? "" : "56px",
                  // width: isIphone12 ? '428px' : is8Plus ? '411px' : isMobile ? '375px' : '89%',
                  width: '100%',

                  height: '47px',
                  fontSize: isMobile ? '14px' : '18px',
                  lineHeight: isMobile ? '31.5px' : '47.79px',
                  color: ' #FFFFFF',
                  fontFamily: 'Atten New',
                  fontStyle: 'normal',
                  textAlign: 'center',
                  margin: '0 auto',
                  // marginLeft: isMobile ? "" : "70px",
                  borderBottom: '1px solid rgba(255, 255, 255, 0.14)',
                }}>
                <div
                  style={{
                    flexGrow: isMobile ? 0.5 : 0.5,
                    marginTop: isMobile ? '8px' : '',
                  }}>
                  <Moment format='MM-DD-YYYY' withTitle>
                    {value.submitted_date}
                  </Moment>
                </div>
                <div
                  style={{
                    flexGrow: isMobile ? 0.5 : 0.5,
                    marginTop: isMobile ? '8px' : '',
                  }}>
                  ${value.amount.toFixed(2)}
                </div>
                <div
                  style={{
                    flexGrow: isMobile ? 0.5 : 0.5,
                    marginTop: isMobile ? '8px' : '',
                  }}>
                  {value.confirmation}
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default PaymentScreen
