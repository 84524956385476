import React, {
  useState,
  useEffect,
  useLayoutEffect,
  Dispatch,
  useContext,
  useRef,
} from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import { isTablet, isMobile, isMobileOnly } from "react-device-detect";
import Button from "@material-ui/core/Button";
import Search from "./search";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/index";
import { Row, Col, Container } from "react-bootstrap";
import line from "../assets/line.svg";
import { API_END_POINT, CONFIG_DETAILS } from "../utils/Constant";
import moment from "moment";
import swal from "sweetalert";
import { DataContext, UpdateForm, verifytoggle } from "../UserContext";
import { CircularProgress } from "material-ui";
import { truncate } from "lodash";
import VerifyResaleTicketMobile from "./VerifyResaleTicketMobile";
import { addSeatSets, updateSeatSets } from "../store/seatSets/action";
import { elementScrollIntoView } from "seamless-scroll-polyfill";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "100%",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    buttonAll: {
      width: "auto",
      height: "50px",
      fontWeight: 400,
      borderRadius: 5,
      backgroundColor: "rgba(0, 133, 255, 0.1)",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#0085FF",
      fontSize: isMobileOnly ? "3vw" : "16px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
    },
    buttonSelected: {
      width: "auto",
      height: "50px",
      fontWeight: 500,
      borderRadius: 5,
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "#DFE0E6",
      fontSize: isMobileOnly ? "3vw" : "16px",
      boxSizing: "border-box",
      fontFamily: "Roboto",
    },
  })
);
interface Data {
  awayTeamId: number;
  datetime: string;
  description: string;
  gameId: string;
  homeTeamId: number;
}

interface props {
  activeStep: number;
  handleBack(value: React.BaseSyntheticEvent): void;
  handleNext(value: any): void;
  handlerHide(value: any): void;
  handleReset(): void;
  backhandler(value: any): void;
  doubleBack(): void;
}
const WhichGameMobile: React.FC<props> = (props) => {
  const { trackData, setTrackData } = useContext(DataContext);
  const classes = useStyles();
  const [selectedID, setselectedID] = useState<Data[]>(
    trackData.gamesID !== undefined ? trackData.gamesID : []
  );
  const [teamShow, setteamShow] = useState<any>([]);
  const [bool, setbool] = useState<any>();
  const [checkall, setcheckall] = useState<boolean>(false);
  const [games, setGames] = useState<any>([]);
  const [toggle, settoggle] = useState<any>(true);
  const { verify, setverify } = useContext(verifytoggle);
  const [CopyData, setCopyData] = useState<any>([]);
  const searchedText = useRef("");
  const { updateform, setupdateform } = useContext(UpdateForm);
  const handleChange = (value: Data) => {
    setselectedID((selectedID) => [...selectedID, value]);
  };
  const messagesEndRef = React.useRef<null | HTMLDivElement>(null);
  const dispatch: Dispatch<any> = useDispatch();
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  const [focused, setFocused] = React.useState(false);
  const onFocus = () => {
    setFocused(true);
  };

  const onBlur = () => {
    setFocused(false);
  };
  const ToggleHandler = (value: any) => {
    settoggle(value);
  };

  React.useEffect(() => {
    async function FetchData() {
      var bodyData = new FormData();
      let requestHeaders: any = { "Content-Type": "multipart/form-data" };

      bodyData.append("name", "123");
      const id = trackData.teams.selectedTeam[0].sky_id;

      const response = await axios({
        method: "get",
        url: `${process.env.REACT_APP_ROOT_URL}${API_END_POINT.GAME}/${id}`,
        headers: requestHeaders,
        data: bodyData,
      });

      if (response.data.length > 0) {
        setGames(response.data);

        setteamShow(response.data);
        setCopyData(response.data);
      } else {
        swal({
          title: "Sorry!",
          text: "Sorry No Games",
          icon: "warning",
        });
      }
    }
    FetchData();
    if (isMobile) {
      // to stop search field move to downwards while searching
      if (verify) {
        let e = Array.from(
          document.getElementsByClassName(
            "which-game"
          ) as HTMLCollectionOf<HTMLElement>
        )[0];
        e.style.height = "1000px";
      } else {
        let e = Array.from(
          document.getElementsByClassName(
            "which-game"
          ) as HTMLCollectionOf<HTMLElement>
        )[0];
        e.style.height = "0px";
      }
    }
  }, [CopyData.length === 0 || verify]);
  let seatsets = useSelector((state: RootState) => {
    return state.seatsets.seatSets;
  });
  const handleRemove = (value: Data) => {
    let removedArry = selectedID.filter((val) => val.gameId !== value.gameId);
    setselectedID((selectedID) => removedArry);
  };
  useEffect(() => {
    selectedID.length == teamShow.length && teamShow.length != 0
      ? setcheckall(true)
      : setcheckall(false);
  }, [selectedID]);

  const selectAll = () => {
    var arr = teamShow.map((val: any) => {
      return val;
    });
    setcheckall(true);
    setselectedID((selectedID) => arr);
  };

  const removeAll = () => {
    setcheckall(false);
    setselectedID((selectedID) => []);
  };

  const handlesubmit = () => {
    setTrackData({
      teams: {
        selectedTeam: trackData.teams.selectedTeam,
      },
      gamesID: selectedID,
    });
    setTrackData({
      id: trackData.id,
      teams: {
        selectedTeam: trackData.teams.selectedTeam,
      },
      fromSeat: trackData.fromSeat !== undefined ? trackData.fromSeat : "",
      gamesID: selectedID,
      row: trackData.row !== undefined ? trackData.row : "",
      section: trackData.section !== undefined ? trackData.section : "",
      toSeat: trackData.toSeat !== undefined ? trackData.toSeat : "",
      price: trackData.price !== undefined ? trackData.price : "",
    });
    if (updateform) {
      console.log("sss ", {
        id: trackData.id,
        teams: {
          selectedTeam: trackData.teams.selectedTeam,
        },
        gamesID: selectedID,
        section: trackData.section,
        row: trackData.row,
        toSeat: trackData.toSeat,
        fromSeat: trackData.fromSeat,
        price: trackData.price,
      });
      dispatch(
        updateSeatSets(
          {
            id: trackData.id,
            teams: {
              selectedTeam: trackData.teams.selectedTeam,
            },
            gamesID: selectedID,
            section: trackData.section,
            row: trackData.row,
            toSeat: trackData.toSeat,
            fromSeat: trackData.fromSeat,
            price: trackData.price,
          },
          trackData.id
        )
      );
      setupdateform(false);
      // setheading(true)
    } else {
      dispatch(
        addSeatSets({
          id: trackData.id,
          teams: {
            selectedTeam: trackData.teams.selectedTeam,
          },
          gamesID: selectedID,
          section: trackData.section,
          row: trackData.row,
          toSeat: trackData.toSeat,
          fromSeat: trackData.fromSeat,
          price: trackData.price,
        })
      );
    }
    setverify(false);
  };

  const onSearch = async (search: string) => {
    // setbool(true);
    // if (search.length === 0) {
    //   setteamShow(games);
    //   setbool(false);
    // } else {
    //   let arr = [...games];
    //   setteamShow(
    //     arr.filter((val: any) => {
    //       var ser = val.description.toLocaleLowerCase();
    //       return ser.includes(search.toLocaleLowerCase());
    //     })
    //   );
    // }

    let condition = new RegExp(search.toLocaleLowerCase());
    var result = await Promise.all(
      CopyData.filter(function (el: any) {
        return condition.test(el.description.toLocaleLowerCase());
      })
    );
    searchedText.current = search;
    setteamShow(result);
    if (search.length === 0) {
      setteamShow(teamShow);
    }
  };
  return (
    <div>
      <div className="which-game">
        {verify === true ? (
          <>
            <div
              className="label"
              style={{
                fontSize: "20px",
                color: "#BC936C",
                fontWeight: 700,
                lineHeight: "24px",
                fontFamily: "Atten New",
              }}
            >
              Which {trackData.teams.selectedTeam[0].home_team} games do you own
              ? <br />
              <p style={{ fontSize: "15px", fontWeight: 300 }}>
                {" "}
                [ for Section {trackData.section} , Row {trackData.row} , Seats{" "}
                {trackData.fromSeat} to {trackData.toSeat} ]
              </p>
            </div>
            <div className="search-div" style={{ display: "inline-block" }}>
              <Search
                onSearch={onSearch}
                onFocus={(e) => {
                  if (isMobile) {
                    let ele = document.getElementsByClassName("search-div")[0];
                    elementScrollIntoView(ele, {
                      behavior: "smooth",
                      block: "start",
                      inline: "center",
                    });
                  }
                }}
              />
            </div>

            {teamShow.length == 0 ? (
              ''
            ) : (
              <div
                className="container"
                style={{
                  paddingLeft: isTablet ? "1%" : "",
                  paddingRight: isTablet || isMobile ? "0%" : "",
                  marginTop: focused ? "-40px" : "5%",
                }}
              >
                {games.length > 0 && teamShow.length > 0 ? (
                  <Container style={{ paddingLeft: "0%" }}>
                    <Row style={{ paddingBottom: "11px" }}>
                      <Col style={{}}>
                        <div className="form-check">
                          <Row>
                            <Checkbox
                              checked={checkall}
                              onChange={checkall ? removeAll : selectAll}
                              style={{
                                color: "#BC936C",
                                width: "24px",
                                height: "24px",
                                marginRight: isMobileOnly ? "12px" : "40px",
                                // marginLeft:!checkall?"":"10px"
                              }}
                            />

                            <div
                              className="col-sm-4"
                              style={{
                                color: "#FAFAFA",
                                display: "contents",
                                alignSelf: "center",
                                fontFamily: "Atten New",
                                fontSize: "14.4px",
                                textAlign: "left",
                                // paddingLeft: "4%",
                              }}
                            >
                              Select all
                            </div>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    {checkall
                      ? selectedID.map((value) => {
                          const selectedIds = selectedID.map(
                            (employee) => employee.gameId
                          );
                          return (
                            <>
                              <Row
                                style={{
                                  paddingTop: "4%",
                                  paddingBottom: "4%",
                                  color: "#FAFAFA",
                                }}
                              >
                                <Col style={{}}>
                                  <div className="form-check">
                                    <Row style={{}}>
                                      <Checkbox
                                        checked={
                                          selectedIds.includes(value.gameId)
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          selectedIds.includes(value.gameId)
                                            ? () => handleRemove(value)
                                            : () => handleChange(value)
                                        }
                                        name={value.description}
                                        style={{
                                          color: "#BC936C",
                                          width: "24px",
                                          height: "24px",
                                          //  marginRight: isMobileOnly ? "12px" : "40px",
                                        }}
                                      />
                                      <div
                                        className="col-10"
                                        style={{ paddingLeft: "3%" }}
                                      >
                                        <div
                                          style={{
                                            color: "#FAFAFA",
                                            textAlign: "left",
                                            alignSelf: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                          }}
                                        >
                                          {value.description}
                                        </div>
                                        <div
                                          style={{
                                            opacity: "60%",
                                            textAlign: "initial",
                                            fontSize: "12px",
                                            color: "#FAFAFA",
                                          }}
                                        >
                                          {moment(value.datetime).format(
                                            "llll"
                                          )}
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <img src={line} />
                            </>
                          );
                        })
                      : teamShow.length > 0 &&
                        teamShow?.map((value: any, i: any) => {
                          const selectedIds = selectedID.map(
                            (employee) => employee.gameId
                          );
                          return (
                            <>
                              <Row
                                style={{
                                  paddingTop: "4%",
                                  paddingBottom: "4%",
                                  color: "#FAFAFA",
                                }}
                              >
                                <Col style={{}}>
                                  <div className="form-check">
                                    <Row style={{}}>
                                      <Checkbox
                                        checked={
                                          selectedIds.includes(value.gameId)
                                            ? true
                                            : false
                                        }
                                        onChange={
                                          selectedIds.includes(value.gameId)
                                            ? () => handleRemove(value)
                                            : () => handleChange(value)
                                        }
                                        name={value.title}
                                        style={{
                                          color: "#BC936C",
                                          width: "24px",
                                          height: "24px",
                                        }}
                                      />
                                      <div
                                        className="col-10"
                                        style={{ paddingLeft: "3%" }}
                                      >
                                        <div
                                          style={{
                                            textAlign: "left",
                                            alignSelf: "center",
                                            fontSize: "14px",
                                            fontWeight: 400,
                                            fontFamily: "Atten New",
                                          }}
                                        >
                                          {value.description}
                                        </div>
                                        <div
                                          style={{
                                            opacity: "60%",
                                            textAlign: "initial",
                                            fontSize: "12px",
                                            fontFamily: "Atten New",
                                            color: "#FAFAFA",
                                          }}
                                        >
                                          {moment(value.datetime).format(
                                            "llll"
                                          )}
                                        </div>
                                      </div>
                                    </Row>
                                  </div>
                                </Col>
                              </Row>
                              <>
                                <img src={line} />
                              </>
                            </>
                          );
                        })}
                  </Container>
                ) : (
                  <VerifyResaleTicketMobile
                    activeStep={props.activeStep}
                    handleReset={props.handleReset}
                    handleNext={props.handleNext}
                    handleBack={props.handleBack}
                    handletoggle={ToggleHandler}
                    doubleBack={props.doubleBack}
                    backhandler={props.backhandler}
                  />
                )}
              </div>
            )}

            {isMobileOnly ? (
              <div
                className={props.activeStep === 0 ? "" : "col"}
                style={{
                  justifyContent: "space-between",
                  marginTop: "10%",
                  paddingLeft: "25px",
                  paddingRight: "25px",
                }}
              >
                <div style={{ paddingBottom: isMobileOnly ? "15px" : "" }}>
                  <Button
                    variant="contained"
                    style={{
                      width: "100%",
                      height: 50,
                      borderRadius: 50,
                      boxShadow:
                        "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                      backgroundColor: "#C57A1E",
                    }}
                    onClick={() => handlesubmit()}
                  >
                    <div style={{ color: "#FCFCFC" }}>
                      {props.activeStep === 0 ? "CONTINUE" : "Continue"}
                    </div>
                  </Button>
                </div>
                {/* <div>
            {props.activeStep !== 0 && (
              <Button
                variant="contained"
                style={{
                  width: "100%",
                  height: 50,
                  boxShadow:
                    "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                  borderRadius: 50,
                  backgroundColor: "#BC936C",
                }}
                disabled={props.activeStep === 0}
                onClick={(e) => {
                  props.handleBack(e);
                }}
              >
                <div className="container" style={{ color: "#FAFAFA" }}>
                  BACK
                </div>
              </Button>
            )}
          </div> */}
              </div>
            ) : (
              <div
                className={props.activeStep === 0 ? "" : "row"}
                style={{
                  justifyContent: "space-between",
                  marginTop: "10%",
                  marginBottom: "5%",
                  paddingTop: isMobileOnly ? "15%" : "0",
                }}
              >
                <div style={{ marginLeft: isTablet ? "75px" : "45px" }}>
                  {props.activeStep !== 0 && (
                    <Button
                      variant="contained"
                      style={{
                        width: 150,
                        height: 50,
                        boxShadow:
                          "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                        borderRadius: 50,
                        backgroundColor: "#BC936C",
                      }}
                      onClick={(e) => {
                        props.handleBack(e);
                      }}
                    >
                      <div className="container" style={{ color: "#FAFAFA" }}>
                        BACK
                      </div>
                    </Button>
                  )}
                </div>
                <div>
                  <Button
                    variant="contained"
                    style={{
                      width: 150,
                      height: 50,
                      borderRadius: 50,
                      boxShadow:
                        "0px 0px 0px -0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0), 0px 0px 0px 0px rgba(0,0,0,0)",
                      backgroundColor: "#BC936C",
                      marginRight: 75,
                    }}
                    onClick={() => handlesubmit()}
                  >
                    <div style={{ color: "#FCFCFC" }}>
                      {props.activeStep === 0 ? "CONTINUE" : "NEXT"}
                    </div>
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <VerifyResaleTicketMobile
            activeStep={props.activeStep}
            handleReset={props.handleReset}
            handleNext={props.handleNext}
            handleBack={props.handleBack}
            handletoggle={ToggleHandler}
            doubleBack={props.doubleBack}
            backhandler={props.backhandler}
          />
        )}
      </div>
      )
    </div>
  );
};
export default WhichGameMobile;
